import { CoreButton } from '@packages';
import './resource-group.scss';
import { useNavigate } from 'react-router-dom';
import ResourceGroupTable from '@admin-features/resource-group/components/resource-group-table/resource-group-table';

export default function ResourceGroupList() {
    const navigate = useNavigate();

    return (
        <>
            <div className='resource-filter-header margin-30'>
                <div className='filter-button-group'>
                    <div>
                        <CoreButton
                            type='primary'
                            text='Create New'
                            isFullWidth={true}
                            onClick={() => navigate(`/admin/resource-group/create`)}
                        />
                    </div>
                </div>
            </div>
            <div>
                <ResourceGroupTable />
            </div>
        </>
    );
}
