import { Space, Row, Col, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './PaymentDetails.scss';
import { useState } from 'react';
import { selectPaymentMethods, selectPaymentMethodsIsLoading } from '@store/slices/paymentSlice';
import { useAppSelector } from '@store/hooks';
import { CoreButton } from '@packages';
import AddPayment from './../add-payment/AddPayment';
import PaymentActionModal from './payment-action-modal/PaymentActionModal';
import { IPaymentMethod } from '@models/payment-method';
import PaymentCard from './payment-card/PaymentCard';
import { subscribe } from '@store/slices/subscribeSlice';
import { BillingActionType } from '../../constant';

export default function PaymentDetails() {
    const [isAddPaymentModalOpen, setAddPaymentModalOpen] = useState<boolean>(false);

    const paymentMethods = useAppSelector(selectPaymentMethods);
    const isLoading = useAppSelector(selectPaymentMethodsIsLoading);
    const [selectedPaymentInfo, setSelectedPaymentInfo] = useState<IPaymentMethod>();

    const [isDeleteModalVisible, setDeleteIsModalVisible] = useState<boolean>(false);
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState<boolean>(false);

    const { subscription } = useAppSelector(subscribe);

    return (
        <div className='payment-details-section-wrapper'>
            <Row gutter={[24, 12]} className='margin-top-16'>
                <Col xs={24} lg={10} xl={8}>
                    <div className='card-details-header'>
                        <p className='card-details-header-title'>Card details</p>
                        <p className='card-details-header-sub-title'>
                            Select default payment method.
                        </p>
                    </div>
                </Col>
                <Col xs={24} lg={12} xl={10}>
                    <Spin spinning={isLoading}>
                        <Space
                            direction='vertical'
                            style={{ width: '100%', maxWidth: '500px', gap: 16 }}
                        >
                            {paymentMethods.map((card, index) => (
                                <PaymentCard
                                    key={index}
                                    card={card}
                                    isDefault={
                                        subscription?.DefaultPaymentMethodId === card?.ID
                                            ? true
                                            : false
                                    }
                                    onSetDefault={(card) => {
                                        setSelectedPaymentInfo(card);
                                        setIsUpdateModalVisible(true);
                                    }}
                                    onDelete={(card) => {
                                        setSelectedPaymentInfo(card);
                                        setDeleteIsModalVisible(true);
                                    }}
                                />
                            ))}
                        </Space>
                    </Spin>
                    <div className='margin-top-16'>
                        <CoreButton
                            size='small'
                            antType='link'
                            text='Add New Card'
                            icon={<PlusOutlined />}
                            onClick={() => setAddPaymentModalOpen(true)}
                        />
                    </div>
                </Col>
                <AddPayment
                    isOpenModal={isAddPaymentModalOpen}
                    onCancelModal={() => setAddPaymentModalOpen(false)}
                />
                <PaymentActionModal
                    title='Are you sure you want to delete this card?'
                    subTitle='This credit card will be deleted.'
                    isVisible={isDeleteModalVisible}
                    onClose={() => setDeleteIsModalVisible(false)}
                    actionType={BillingActionType.Delete}
                    paymentInfo={selectedPaymentInfo}
                />

                <PaymentActionModal
                    title='Update default card'
                    subTitle='This credit card will be used by default for billing.'
                    isVisible={isUpdateModalVisible}
                    onClose={() => setIsUpdateModalVisible(false)}
                    actionType={BillingActionType.Update}
                    paymentInfo={selectedPaymentInfo}
                />
            </Row>
        </div>
    );
}
