import CustomTable from '@components/table/CustomTable';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getResourceGroups, selectResourceGroup } from '@store/slices/admin/resourceGroupSlice';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect } from 'react';
const columns: ColumnsType<any> = [
    {
        key: '1',
        title: 'Name',
        dataIndex: 'UserName',
    },
    {
        key: '2',
        title: 'Email',
        dataIndex: 'Email',
    },

    {
        key: '3',
        title: 'Created At',
        dataIndex: 'CratedAt',
    },
];
const pageLimit = 10;
export default function ResourceGroupTable() {
    const dispatch = useAppDispatch();
    const { data, next, nonce, status } = useAppSelector(selectResourceGroup);

    const nextPage = () => {
        dispatch(getResourceGroups({ pageLimit: pageLimit, next, nonce, isConcat: true }));
    };

    useEffect(() => {
        dispatch(getResourceGroups({ pageLimit: pageLimit }));
    }, [dispatch]);
    return (
        <div className='ksa-table-wrapper table-auto-scroll-without-header'>
            <CustomTable
                className='table-auto-scroll-with-header'
                columns={columns}
                hasNext={!!next}
                data={data}
                isLoading={status === 'loading' ? true : false}
                uniqueId='ItemId'
                currentPage={1}
                goNextPage={nextPage}
            />
        </div>
    );
}
