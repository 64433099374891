import { Tooltip } from 'antd';
import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons'; // Assuming Ant Design for icons

export default function EmptyCard() {
    return (
        <div className='info-message'>
            <Tooltip title='' className='generic-tooltip'>
                <ExclamationCircleOutlined
                    style={{
                        fontSize: '16px',
                        position: 'absolute',
                        marginRight: '12px',
                        color: 'blue',
                    }}
                />
            </Tooltip>
            <p>Details of items added to your estimate are displayed in this panel</p>
        </div>
    );
}
