import { Col, Row } from 'antd';
import './OverviewWrapper.scss';
import AvailableCreditsTable from '../../available-credits-table/AvailableCreditsTable';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
    getSubscription,
    getSubscriptionConfiguration,
    subscribe,
} from '@store/slices/subscribeSlice';
import OverviewSummary from '../overview-summary/OverviewSummary';
import EnterpriseCard from '../../enterprise-card/EnterpriseCard';
import BillingProfile from '../../billing-profile/BillingProfile';
import TrackConsumption from '../../track-consumption/TrackConsumption';
import BillingPayment from '../../billing-payment/BillingPayment';
import StackedBarChart from '../stacked-bar-chart/StackedBarChart';
import SubscriptionCancellation from '../../subscription-cancellation/SubscriptionCancellation';

const OverviewWrapper = () => {
    const dispatch = useAppDispatch();
    const { subscription } = useAppSelector(subscribe);

    useEffect(() => {
        dispatch(getSubscription());
        dispatch(getSubscriptionConfiguration());
    }, []);

    return (
        <div className='overview-wrapper'>
            <Row gutter={[24, 24]}>
                <Col xs={24} lg={17}>
                    <div className='left-panel'>
                        {subscription.IsSetToCancel && <SubscriptionCancellation />}

                        <OverviewSummary />
                        <StackedBarChart />
                        <AvailableCreditsTable />
                    </div>
                </Col>
                <Col xs={24} lg={7}>
                    <div className='right-panel'>
                        <BillingPayment />
                        <EnterpriseCard />
                        <BillingProfile />
                        <TrackConsumption />
                    </div>
                </Col>
            </Row>
            <div className='margin-top-24'></div>
        </div>
    );
};
export default OverviewWrapper;
