import { CoreTitle } from '@packages';
import './InvoiceAttachments.scss';
import { SingleAttachment } from '../single-attachment/SingleAttachment';
import { Divider } from 'antd';

const InvoiceAttachments = () => {
    return (
        <div>
            <CoreTitle tag='h5' text='Invoice Attachment(s)' />
            <div className='attachment-wrapper'>
                <SingleAttachment name='Invoice_10022004-001.pdf' date='Sat 09 Feb, 2024' />
                <Divider style={{ background: '#EBEBEB', margin: 12 }} />
                <SingleAttachment name='Invoice_10022004-001.pdf' date='Sat 09 Feb, 2024' />
            </div>
        </div>
    );
};

export default InvoiceAttachments;
