import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getAdminSubscriptions } from '@store/slices/admin/subscriptionSlice';
import { RootState } from '@store/store';
import { UtcToLocalDate } from '@utils/date';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Subscription.scss';

const Subscription = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { subscriptions } = useAppSelector((state: RootState) => state.adminSubscription);

    useEffect(() => {
        dispatch(getAdminSubscriptions({ PageLimit: 10 }));
    }, [dispatch]);

    const columns: ColumnsType<any> = [
        {
            key: '1',
            title: 'Subscription ID',
            dataIndex: 'SubscriptionId',
            render: (SubscriptionId: string) => <span>{SubscriptionId.slice(0, 5)}</span>,
        },
        {
            key: '2',
            title: 'Email',
            dataIndex: 'Email',
        },

        {
            key: '3',
            title: 'Tier Name',
            dataIndex: 'TierName',
        },
        {
            key: '4',
            title: 'Is Active',
            dataIndex: 'IsActive',
            render: (isActive: boolean) => <span>{isActive ? 'Yes' : 'No'}</span>,
        },
        {
            key: '4',
            title: 'Recurring',
            dataIndex: 'Recurring',
            render: (Recurring: string) => <span>{Recurring || DEFAULT_EMPTY_VALUE}</span>,
        },
        {
            key: '5',
            title: 'Usage Hours',
            dataIndex: 'UsageHours',
        },
        {
            key: '6',
            title: 'Expiration',
            dataIndex: 'Expiration',
            render: (text: string) => <span>{UtcToLocalDate(text)}</span>,
        },
    ];

    const handleRowClick = (record: any) => {
        navigate(`/admin/subscription/${record?.SubscriptionId}`);
    };

    return (
        <div className='subscription-view-wrapper'>
            <Table
                columns={columns}
                dataSource={subscriptions.data}
                loading={false}
                rowClassName='cursor-pointer'
                onRow={(record) => {
                    return {
                        onClick: () => handleRowClick(record),
                    };
                }}
                pagination={false}
            />
        </div>
    );
};

export default Subscription;
