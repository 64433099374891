import { Pagination, PaginationProps } from 'antd';
import './CustomPagination.scss';
import { FC, useRef } from 'react';

interface ICustomPagination {
    total: number;
    pageSize?: number;
    hasNext: boolean;
    goLocalPage: (pageNumber: number) => void;
    goNextPage?: () => void;
    currentPage: number;
}

const CustomPagination: FC<ICustomPagination> = ({
    total,
    pageSize = 10,
    hasNext = false,
    currentPage,
    goLocalPage,
    goNextPage,
}) => {
    const nextButtonRef = useRef<HTMLDivElement | null>(null);
    const onClickNext = () => {
        if (!nextButtonRef) return;
        const isDisabledNext = nextButtonRef?.current?.hasAttribute('disabled');
        if (!isDisabledNext || !hasNext) return;
        goNextPage && goNextPage();
    };
    const totalCustomData = pageSize * currentPage;
    const isDisableNextButton = !hasNext && totalCustomData >= total ? true : false;

    const itemRender: PaginationProps['itemRender'] = (currentPage, type, originalElement: any) => {
        if (type === 'prev') {
            return <a>Pre</a>;
        }
        if (type === 'next') {
            return (
                <div
                    onClick={onClickNext}
                    aria-hidden='true'
                    ref={nextButtonRef}
                    className={isDisableNextButton ? 'disabled' : ''}
                >
                    <a>Next</a>
                </div>
            );
        }
        return originalElement;
    };
    const handlePageChange = (page: number, pageSize: number) => {
        goLocalPage && goLocalPage(page);
    };
    return (
        <div className='custom-pagination-container'>
            <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={total} // Set total to null, as we don't have the total count
                showSizeChanger={false}
                showQuickJumper={false}
                onChange={handlePageChange}
                itemRender={itemRender}
            />
        </div>
    );
};

export default CustomPagination;
