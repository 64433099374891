import OverviewWrapper from '@client-features/subscribe/components/overview/overview-wrapper/OverviewWrapper';
import { useAppDispatch } from '@store/hooks';
import { getAdminSubscription } from '@store/slices/admin/subscriptionSlice';
import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './SubscriptionDetails.scss';
import Invoice from '@admin-features/subscription/components/invoice/Invoice';
import Organizations from '@admin-features/subscription/components/organizations/Organizations';

const SubscriptionDetails = () => {
    const dispatch = useAppDispatch();
    const { subscriptionId } = useParams();
    // const { subscription } = useAppSelector((state: RootState) => state.adminSubscription);

    const [activeTabKey, setActiveTabKey] = useState<string>('overview');

    useEffect(() => {
        if (subscriptionId) {
            dispatch(getAdminSubscription({ SubscriptionId: subscriptionId }));
        }
    }, [subscriptionId, dispatch]);

    const tabItems = [
        {
            label: 'Overview',
            key: 'overview',
            children: <OverviewWrapper />,
        },
        {
            label: 'Invoice',
            key: 'invoice',
            children: <Invoice />,
        },
        {
            label: 'Organizations',
            key: 'organizations',
            children: <Organizations />,
        },
    ];

    const changeActiveTabKeys = (key: string) => {
        setActiveTabKey(key as string);
    };

    return (
        <div className='subscription-details-wrapper'>
            <div className='subscription-details-inner-wrapper '>
                <Tabs activeKey={activeTabKey} onChange={changeActiveTabKeys} items={tabItems} />
            </div>
        </div>
    );
};

export default SubscriptionDetails;
