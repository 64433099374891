import { IProcessingSummary } from '@models/dashboard.model';
import _ from 'lodash';

export function propagateCounts(summary?: IProcessingSummary[]): IProcessingSummary[] {
    if (!summary || !Array.isArray(summary)) {
        return undefined as any;
    }

    const newSummary = _.cloneDeep(summary);

    function computeCounts(node: IProcessingSummary): number {
        if (!node.leaf || node.leaf.length === 0) {
            node.leaf = [
                {
                    title: node.title,
                    id: node.id,
                    name: node.name,
                    count: node.count,
                    leaf: undefined,
                },
            ];
            return node.count || 0;
        }

        let total = 0;
        node.leaf.forEach((child) => {
            total += computeCounts(child);
        });

        node.count = total;
        return total;
    }

    newSummary.forEach((node: IProcessingSummary) => computeCounts(node));

    return newSummary;
}

export const propagateTotalCounts = (nodes: IProcessingSummary[]) => {
    if (!nodes) {
        return 0;
    }
    return nodes.reduce((acc, node) => acc + (node.count ?? 0), 0);
};

export const isLeafFoundInNodes = (nodes: IProcessingSummary[]) => {
    return nodes.some((node) => (node?.leaf && node.leaf.length > 0 ? true : false));
};
