import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { IResourceGroups, IResourceGroupsPayload } from '@models/admin/resource-group.model';
import { RequestStatus } from '@models/async-status.enum';
import { ResourceGroupAPI } from '@service/admin/resource-group.service';

const namespace = 'resource-group';
export interface ILanguageState {
    resourceGroups: {
        data: IResourceGroups;
        status: RequestStatus;
        next?: string;
        nonce?: string;
    };
}

const initialState: ILanguageState = {
    resourceGroups: {
        data: [],
        status: 'idle',
    },
};

export const getResourceGroups = createAsyncThunk(
    'resourceGroups',
    async (payload: IResourceGroupsPayload) => {
        try {
            const response = await ResourceGroupAPI.getResourceGroups(payload);
            return { ...response, isConcat: payload.isConcat };
        } catch (error: any) {
            console.log(error);
            throw error?.response?.data?.Message || error?.message;
        }
    },
);

const appResourceGroupSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getResourceGroups.pending, (state) => {
                state.resourceGroups.status = 'loading';
            })
            .addCase(getResourceGroups.rejected, (state) => {
                state.resourceGroups.status = 'failed';
                state.resourceGroups.data = [];
            })
            .addCase(getResourceGroups.fulfilled, (state, action) => {
                state.resourceGroups.status = 'idle';
                const { resourceGroups = [], Next, Nonce, isConcat } = action.payload;
                const hasNext = !!Next;
                state.resourceGroups.data = isConcat
                    ? state.resourceGroups.data.concat(resourceGroups)
                    : resourceGroups;
                state.resourceGroups.status = 'idle';
                state.resourceGroups.next = Next;
                state.resourceGroups.nonce = hasNext ? Nonce : undefined;
            });
    },
});
export const selectResourceGroup = (state: RootState) => state.resourceGroup.resourceGroups;
export default appResourceGroupSlice.reducer;
