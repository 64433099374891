import { CoreButton, CoreTitle } from '@packages';
import './UserDetailsStep.scss';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { RegexValidators } from '@config/regex-validator-constant';
import { useAppDispatch } from '@store/hooks';
import { setEmail } from '@store/slices/admin/userSlice';

interface IUserDetailsStepProps {
    goToNextStep: () => void;
}

const UserDetailsStep = ({ goToNextStep }: IUserDetailsStepProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
        defaultValues: {},
    });
    const onSubmit = async (data: any) => {
        console.log(data.Email, 'data');
        dispatch(setEmail(data.Email));
        goToNextStep();
    };

    return (
        <div className='user-details-stepper-wrapper'>
            <div className='form-header'>
                <CoreTitle text='Create User' tag='h1' />
                <p>Please enter email address.</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <InlineGenericInput
                    control={control}
                    errors={errors}
                    levelCol={24}
                    inputCol={24}
                    dataKey='Email'
                    level={t('EMAIL')}
                    marginBottom={15}
                    rules={{
                        required: true,
                        pattern: {
                            value: RegexValidators.EMAIL,
                            message: t('INVALID_ENTRY_EG'),
                        },
                    }}
                />
                <CoreButton
                    type='primary'
                    text='Let’s get started'
                    isFullWidth={true}
                    htmlType='submit'
                />
            </form>
        </div>
    );
};

export default UserDetailsStep;
