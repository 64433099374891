import { useAppSelector } from '@store/hooks';
import PricingCalculatorEstimationCard from './PricingCalculatorEstimationCard';
import { selectPaymentCalculatorList } from '@store/slices/paymentCalculator';
export default function PricingCalculatorContainer() {
    const paymentCalculator = useAppSelector(selectPaymentCalculatorList);
    return (
        <div style={{ width: '100%' }}>
            {paymentCalculator.map((calculator) => (
                <PricingCalculatorEstimationCard key={calculator.key} pricingAmount={calculator} />
            ))}
        </div>
    );
}
