import { CoreButton, CoreTitle } from '@packages';
import { SubscribeAPI } from '@service/subscription.service';
import { message, Modal, Radio } from 'antd';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch } from '@store/hooks';
import { getSubscription } from '@store/slices/subscribeSlice';
import './CancelSubscriptionModal.scss';
import InlineGenericInput from '@components/common/InlineGenericInput';

interface ICancelSubscriptionModalProps {
    isModalOpen: boolean;
    onConfirm: () => void;
    onClose: () => void;
}

interface ICancelSubscriptionForm {
    Reason: string;
    OtherReason: string;
}

const CancelSubscriptionModal = ({
    isModalOpen,
    onConfirm,
    onClose,
}: ICancelSubscriptionModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm<ICancelSubscriptionForm>({
        mode: 'onTouched',
    });

    const onSubmit = async (data: ICancelSubscriptionForm) => {
        const reason = data.Reason === 'other' ? data.OtherReason : data.Reason;

        setIsLoading(true);
        SubscribeAPI.CancelSubscription({ Reason: reason })
            .then(() => {
                dispatch(getSubscription());
                message.success('subscription cancellation successful');
            })
            .catch((error) => {
                message.error(error?.data?.Message);
            })
            .finally(() => {
                setIsLoading(false);
                onCloseClicked();
            });
    };

    const onCloseClicked = () => {
        reset();
        onClose();
    };

    const reasons = [
        { label: 'The product has technical issues', value: 'The product has technical issues' },
        { label: 'I don’t use it enough', value: 'I don’t use it enough' },
        { label: 'It’s too expensive', value: 'It’s too expensive' },
        { label: 'I accomplished my goal', value: 'I accomplished my goal' },
        { label: 'I want to renew manually', value: ' want to renew manually' },
        { label: 'I’m switching to another provider', value: 'I’m switching to another provider' },
        {
            label: 'I’m just testing it out and don’t intend on staying',
            value: 'I’m just testing it out and don’t intend on staying',
        },
        { label: 'Other', value: 'other' },
    ];

    return (
        <Modal
            width={500}
            open={isModalOpen}
            onOk={onConfirm}
            onCancel={onClose}
            closable={false}
            footer={[]}
            maskClosable={false}
            className='cancel-subscription-modal-wrapper'
        >
            <div className='cancel-subscription-modal-inner-wrapper'>
                <CoreTitle text='Are you sure you want to cancel your subscription?' tag='h6' />
                <form onSubmit={handleSubmit(onSubmit)} className='margin-top-16'>
                    <Controller
                        name='Reason'
                        control={control}
                        rules={{ required: 'Please select a reason' }}
                        render={({ field }) => (
                            <Radio.Group
                                {...field}
                                style={{ width: '100%' }}
                                onChange={(e) => field.onChange(e.target.value)}
                            >
                                {reasons.map((reason) => (
                                    <Radio key={reason.value} value={reason.value}>
                                        <p className='radio-label'>{reason.label}</p>
                                    </Radio>
                                ))}
                            </Radio.Group>
                        )}
                    />
                    {errors.Reason && <p className='error-message'>{errors.Reason.message}</p>}

                    {watch('Reason') === 'other' && (
                        <InlineGenericInput
                            inputCol={24}
                            levelCol={24}
                            control={control}
                            errors={errors}
                            dataKey='OtherReason'
                            level={'Reason'}
                            errorMessageSize='small'
                            marginBottom={10}
                            rules={{
                                required: true,
                            }}
                        />
                    )}

                    <div className='cancel-subscription-action'>
                        <CoreButton
                            key={1}
                            text='Cancel'
                            type='basic'
                            onClick={onCloseClicked}
                            isFullWidth={true}
                        />
                        <CoreButton
                            key={2}
                            text='Submit and continue'
                            type='primary'
                            htmlType='submit'
                            isFullWidth={true}
                            loading={isLoading}
                        />
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default CancelSubscriptionModal;
