import { CoreButton, CoreTitle } from '@packages';
import './BillingPayment.scss';
import { UTCToShortDate } from '@utils/date';
import { subscribe } from '@store/slices/subscribeSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { tabType } from '@models/subscription.model';
import { setActiveTabkey } from '@store/slices/subscriptionOverviewSlice';

const BillingPayment = () => {
    const { subscription } = useAppSelector(subscribe);
    const dispatch = useAppDispatch();

    const goToPaymentDetails = () => {
        dispatch(setActiveTabkey('payment' as tabType));
    };

    return (
        <div className='billing-payment-wrapper round-box-shadow'>
            <div className='billing-payment-block'>
                <CoreTitle tag='h6' text='Billing Period' />
                <p>
                    {UTCToShortDate(subscription?.LastPaymentDate)} -{' '}
                    {UTCToShortDate(subscription?.DueDate)}
                </p>
            </div>

            <div className='billing-payment-block'>
                <CoreTitle tag='h6' text='Last Billed on' />
                <p>{UTCToShortDate(subscription?.LastPaymentDate)}</p>
            </div>

            <div className='billing-payment-block'>
                <CoreTitle tag='h6' text='Previous Invoice' />
                <p>SAR 0.40</p>
            </div>

            <div className='billing-payment-block'>
                <CoreTitle tag='h6' text='Next Payment' />
                <p>{UTCToShortDate(subscription?.Expiration)}</p>
            </div>

            <div>
                <CoreButton
                    type='primary'
                    text='Manage Payments'
                    size='small'
                    onClick={goToPaymentDetails}
                />
            </div>
        </div>
    );
};

export default BillingPayment;
