import { useAppSelector } from '@store/hooks';
import { userSelectRole } from '@store/slices/userSlice';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';

const useGetMenuSelectedKey = (): string[] => {
    const [getKeys, setKeys] = useState<string[]>([]);
    const { isAdmin } = useAppSelector(userSelectRole);
    const pathName = window.location.pathname;
    const generateKey = (): void => {
        const clonePath = cloneDeep(pathName);
        const newPath =
            (isAdmin && clonePath.startsWith('/admin') && clonePath.replace('/admin', '')) ||
            clonePath;
        const keyNames = newPath.split('/');
        const keys = keyNames[1];
        setKeys([keys]);
    };
    useEffect(() => {
        generateKey();
    }, [pathName, isAdmin]);
    return getKeys;
};

export default useGetMenuSelectedKey;
