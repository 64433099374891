import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { CoreButton, CoreStatus } from '@packages';
import { message, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import './AlertsTab.scss';
import CreateAlertModal from '../create-alert-modal/CreateAlertModal';
import UpdateAlertModal from '../update-alert-modal/UpdateAlertModal';
import { useAppDispatch } from '@store/hooks';
import { getAlerts } from '@store/slices/subscribeSlice';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { UTCToShortDate } from '@utils/date';
import { IAlert } from '@models/subscription.model';
import { ConfirmationModal } from '@components';
import { SubscribeAPI } from '@service/subscription.service';

const AlertsTab = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false);
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [selectedAlert, setSelectedAlert] = useState<IAlert | null>(null);

    const { alerts } = useSelector((state: RootState) => state.subscribe);
    useEffect(() => {
        dispatch(getAlerts());
    }, []);

    const alertMessages: Record<string, string> = {
        'on-organization-numbers-exceeds': 'If total organization numbers is above',
        'on-entities-numbers-exceeds': 'If total entities numbers is above',
        'on-device-numbers-exceeds': 'If total device numbers is above',
        'on-invoice-numbers-exceeds': 'If total invoice numbers is above',
        'on-users-numbers-exceeds': 'If total users numbers is above',
        'on-total-billing-exceeds': 'If total billing is above SAR',
    };

    const columns: ColumnsType<any> = [
        {
            key: 1,
            title: 'Alert Condition',
            dataIndex: 'AlertType',
            render: (alertType: string, record) => {
                const message = alertMessages[alertType] || '';
                return (
                    <span>
                        {message} {alertType === 'on-total-billing-exceeds' ? 'SAR' : ''} {record.Value}
                    </span>
                );
            },
        },
        {
            key: 2,
            title: 'Is Active',
            dataIndex: 'IsActive',
            render: (IsActive: string) => (
                <CoreStatus
                    type={IsActive ? 'success' : 'inactive'}
                    status={IsActive ? 'Active' : 'Inactive'}
                />
            ),
        },
        {
            key: 3,
            title: 'Created At',
            dataIndex: 'CreatedAt',
            render: (text: string) => <span>{UTCToShortDate(text)}</span>,
        },
        {
            key: 4,
            title: 'Actions',
            render: (_, record) => (
                <div className='action-buttons'>
                    <div
                        className='edit-table-icon'
                        aria-hidden='true'
                        onClick={() => {
                            setSelectedAlert(record);
                            setIsUpdateModalVisible(true);
                        }}
                    >
                        <EditOutlined />
                    </div>
                    <div className='edit-table-icon' aria-hidden='true'>
                        <DeleteOutlined
                            onClick={() => {
                                setSelectedAlert(record);
                                setIsDeleteModalOpen(true);
                            }}
                            style={{ color: 'red' }}
                        />
                    </div>
                </div>
            ),
        },
    ];

    const onAlertDelete = () => {
        if (selectedAlert) {
            const deleteAlertPayload = { AlertType: selectedAlert.AlertType };
            SubscribeAPI.DeleteAlert(deleteAlertPayload)
                .then(() => {
                    dispatch(getAlerts());
                    setIsDeleteModalOpen(false);
                    message.success('Delete alert successful');
                })
                .catch((error) => {
                    message.error(error?.data?.Message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    return (
        <div className='alerts-tab-wrapper'>
            <div className='create-alert-btn'>
                <CoreButton
                    text='Create Alert'
                    type='primary'
                    size='small'
                    onClick={() => setIsCreateModalVisible(true)}
                />
            </div>

            <Table rowKey={'CreatedAt'} columns={columns} dataSource={alerts} />

            <CreateAlertModal
                isVisible={isCreateModalVisible}
                onClose={() => setIsCreateModalVisible(false)}
            />

            <UpdateAlertModal
                isVisible={isUpdateModalVisible}
                onClose={() => setIsUpdateModalVisible(false)}
                selectedAlert={selectedAlert}
            />
            <ConfirmationModal
                title='Delete Alert'
                description='Would you like to delete this Alert?'
                buttonText='Delete'
                isDeleteModalOpen={isDeleteModalOpen}
                handleDeleteOk={() => onAlertDelete()}
                handleDeleteCancel={() => {
                    setIsDeleteModalOpen(false);
                    setSelectedAlert(null);
                }}
                isLoading={isLoading}
            />
        </div>
    );
};

export default AlertsTab;
