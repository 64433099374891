import { getItem, MenuItem } from './menu-items';
import { Link } from 'react-router-dom';
import { CustomerIcon, DashboardIcon } from '@components/icons';
import { FileTextOutlined, GroupOutlined, SaveOutlined, BookOutlined } from '@ant-design/icons';
import { TFunction } from 'i18next';

export const ClientsMenuItems = (t: TFunction<'translation', undefined>): MenuItem[] =>
    [
        getItem(<Link to='/dashboard'>{t('DASHBOARD')}</Link>, 'dashboard', <DashboardIcon />),
        getItem(
            <Link to='/organizations'>{t('ORGANIZATIONS')}</Link>,
            'organizations',
            <GroupOutlined />,
        ),
        getItem(<Link to='/customers'>{t('CUSTOMERS')}</Link>, 'customers', <CustomerIcon />),
        getItem(
            <Link to='/e-invoices' className='full-width-height'>
                {t('E_INVOICES')}
            </Link>,
            'e-invoices',
            <FileTextOutlined />,
        ),
        getItem(
            <Link to='/api-services'>{t('API & Services')}</Link>,
            'api-services',
            <SaveOutlined />,
        ),
        getItem(<Link to='/whats-new'>{t('WHATS_NEW')}</Link>, 'whats-new', <BookOutlined />),
    ].filter(Boolean);
