import { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { CoreButton, CoreTitle } from '@packages';
import { Col, Input, message, Row, Transfer } from 'antd';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useSelector } from 'react-redux';
import { getRoles, selectRolesInfo } from '@store/slices/roleSlice';
import './RolePermissonStep.scss';
import { getRandomPassword } from '../../../../constant/getRandomPassword';
import { RootState } from '@store/store';
import { AdminUserAPI } from '@service/admin/user.service';
import InlineGenericRadio from '@components/common/InlineGenericRadio';

const RolePermissonStep = forwardRef((props, ref) => {
    const dispatch = useAppDispatch();
    const { data: roles } = useSelector(selectRolesInfo);
    const email = useAppSelector((state: RootState) => state.adminUser.email);

    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        formState: { errors },
        clearErrors,
    } = useForm<any>({
        mode: 'onChange',
        defaultValues: {
            Roles: [],
            Password: '',
        },
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    useEffect(() => {
        dispatch(getRoles({ PageLimit: 200 }));
    }, [dispatch]);

    useImperativeHandle(ref, () => ({
        triggerSubmit: () => {
            handleSubmit(onSubmit)();
        },
    }));

    const transferData = roles.map((role) => ({
        key: `${role.OrganizationId}-${role.RoleName}`,
        RoleName: role.RoleName,
        OrganizationId: role.OrganizationId,
    }));

    const handleChange = (newTargetKeys: string[]) => {
        setSelectedKeys(newTargetKeys);

        if (!transferData || transferData.length === 0) return;

        const updatedRoles = newTargetKeys
            .map((key) => {
                const { OrganizationId, RoleName } =
                    transferData.find((item) => item.key === key) || {};
                return { OrganizationId, RoleName };
            })
            .filter((role) => role.OrganizationId && role.RoleName);

        setFormValue('Roles', updatedRoles);
    };

    const generateRandomPassword = () => {
        const newPassword = getRandomPassword();
        setPassword(newPassword);
        setFormValue('Password', newPassword);
    };

    const onSubmit = async (data: any) => {
        setLoading(true);

        const payload = {
            AutoActivate: data.AutoActivate === 'true' ? true : false,
            Email: email,
            Password: data.Password,
            Roles: data.Roles,
        };

        AdminUserAPI.createUser(payload)
            .then((response) => {
                message.success(response?.message || 'User Created Successfully');
                console.log('Response:', response);
                console.log('Submitted payload:', payload);
            })
            .catch((error) => {
                console.error('Error:', error);
                message.error(error?.message || 'User Creation Failed');
            })
            .finally(() => {
                setLoading(false);
            });

        console.log('Submitted payload:', payload);
        setLoading(false);
    };

    return (
        <div className='role-wrapper'>
            <CoreTitle text='Permission Options' tag='h4' />
            <form
                onSubmit={handleSubmit(onSubmit)}
                className='margin-top-30'
                id='role-permission-form'
            >
                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <div className='general-input-wrapper'>
                            <label className='general-label'>Geterated Password</label>
                            <Controller
                                name='Password'
                                control={control}
                                render={({ field }) => (
                                    <Input.Password
                                        {...field}
                                        className='general-input'
                                        value={password}
                                        placeholder='Generated Password'
                                        disabled
                                    />
                                )}
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors.Password && (
                                <span className='error-message'>
                                    Generated Password is required
                                </span>
                            )}
                        </div>

                        <CoreButton
                            text='Generate Password'
                            type='primary'
                            size='small'
                            onClick={generateRandomPassword}
                        />
                    </Col>
                    <Col span={12}>
                        <InlineGenericRadio
                            levelCol={24}
                            inputCol={24}
                            setFormValue={setFormValue}
                            control={control}
                            errors={errors}
                            clearErrors={clearErrors}
                            options={[
                                {
                                    label: `True`,
                                    value: 'true',
                                },
                                {
                                    label: `False`,
                                    value: 'false',
                                },
                            ]}
                            direction='horizontal'
                            dataKey='AutoActivate'
                            level='Auto Activate'
                            showLevel={true}
                            marginBottom={20}
                            errorMessageSize='small'
                            rules={{
                                required: true,
                            }}
                        />
                    </Col>

                    <Col span={24}>
                        <div className='general-input-wrapper'>
                            <label className='general-label'>Seletc Roles</label>
                            <Controller
                                name='Roles'
                                control={control}
                                render={({ field }) => (
                                    <Transfer
                                        {...field}
                                        className='custom-transfer'
                                        dataSource={transferData}
                                        titles={['Available Roles', 'Selected Roles']}
                                        targetKeys={selectedKeys}
                                        onChange={handleChange}
                                        render={(item) => item.RoleName}
                                    />
                                )}
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors.Password && (
                                <span className='error-message'>Roles are required</span>
                            )}
                        </div>
                    </Col>
                </Row>
            </form>
        </div>
    );
});

RolePermissonStep.displayName = 'RolePermissonStep';

export default RolePermissonStep;
