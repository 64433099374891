import './PdfTemplateCreate.scss';
import { CoreBackButton, CoreButton } from '@packages';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { useForm } from 'react-hook-form';
import { ICreateAdminTemplate } from '@models/admin/template.model';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import InlineGenericTextArea from '@components/common/InlineGenericTextArea';
import { unwrapResult } from '@reduxjs/toolkit';
import { message } from 'antd';
import { AdminTemplateAPI } from '@service/admin/template.service';
import { useNavigate } from 'react-router-dom';
import { PDF_FORMATS } from '../constant';

const PdfTemplateCreate = () => {
    console.log('PdfTemplateCreate');
    const navigate = useNavigate();
    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        formState: { errors },
        reset,
    } = useForm<ICreateAdminTemplate>({
        mode: 'onChange',
    });

    const onSubmit = async (data: ICreateAdminTemplate) => {
        await AdminTemplateAPI.createPdfTemplate(data)
            .then(unwrapResult)
            .then((result) => {
                message.success('Template Successfully Added');
                reset();
                navigate(`/admin/template/pdf`);
            })
            .catch((err) => {
                message.error('Template Addition Failed');
            });
    };

    return (
        <div className='admin-template-create-wrapper'>
            <CoreBackButton text='' />
            <div className='template-create-form-wrapper'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InlineGenericInput
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='TemplateName'
                        level='Template Name'
                        marginBottom={20}
                        rules={{
                            required: true,
                        }}
                    />

                    <InlineGenericSelect
                        inputCol={24}
                        levelCol={24}
                        control={control}
                        errors={errors}
                        dataKey='Layout'
                        level='Layout'
                        options={PDF_FORMATS}
                        setFormValue={setFormValue}
                        errorMessageSize='small'
                        allowClear={true}
                        showSearch={false}
                        marginBottom={10}
                        rules={{
                            required: true,
                        }}
                    />

                    <InlineGenericTextArea
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='TemplateHtml'
                        level='Template HTML'
                        marginBottom={20}
                        minRows={4}
                        rules={{
                            required: true,
                        }}
                    />

                    <InlineGenericTextArea
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='PreviewHtml'
                        level='Preview Template HTML'
                        marginBottom={20}
                        rules={{
                            required: true,
                        }}
                    />

                    <InlineGenericSelect
                        inputCol={24}
                        levelCol={24}
                        control={control}
                        errors={errors}
                        dataKey='TemplateType'
                        level='Template Type'
                        options={[
                            { label: 'Standard', value: 'Standard' },
                            { label: 'Simplified', value: 'Simplified' },
                        ]}
                        setFormValue={setFormValue}
                        errorMessageSize='small'
                        allowClear={true}
                        showSearch={false}
                        marginBottom={-5}
                    />

                    <div className='template-create-action'>
                        <CoreButton htmlType='submit' type='primary' text='ADD' />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PdfTemplateCreate;
