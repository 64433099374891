import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { estimationItemsData } from '@utils/estimationItemData';
import { getPayAsGoAmountByItemType } from '@utils/getPayAsGoByItemType';
import _ from 'lodash';
import { selectPayAsYouGoInfo } from './subscribeSlice';

interface IPaymentCalculatorState {
    invoices?: number;
    organizations?: number;
    users?: number;
    entities?: number;
    devices?: number;
}

export type PaymentCalculatorType = keyof IPaymentCalculatorState;

const initialState: IPaymentCalculatorState = {};

export const PaymentCalculator = createSlice({
    name: 'paymentCalculator',
    initialState,
    reducers: {
        removeCalculatorItem: (state, action: { type: string; payload: PaymentCalculatorType }) => {
            const keyName = action.payload;
            if (keyName in state) {
                delete state[keyName];
            }
        },
        addNewCalculatorItem: (state, action: { type: string; payload: PaymentCalculatorType }) => {
            const keyName = action.payload;
            state[keyName] = 1;
        },
        changeCalculatorItem: (
            state,
            action: { type: string; payload: { name: PaymentCalculatorType; count: number } },
        ) => {
            const { name, count } = action.payload;
            state[name] = count;
        },
    },
});
export const { removeCalculatorItem, addNewCalculatorItem, changeCalculatorItem } =
    PaymentCalculator.actions;

export const selectPaymentCalculator = (state: RootState) => state.paymentCalculator;

export const selectPaymentCalculatorList = createSelector(
    [selectPaymentCalculator, selectPayAsYouGoInfo],
    (calculator, payAsYouGo) => {
        // remove empty calculator stateValue
        const filteredState = _.pickBy(
            calculator,
            (value) => value !== undefined && value !== null,
        );

        // if found empty object then return empty array
        if (_.isEmpty(filteredState)) {
            return [];
        }
        return _.map(filteredState, (value, key) => {
            const amount = getPayAsGoAmountByItemType(key as PaymentCalculatorType, payAsYouGo);
            return {
                key,
                value,
                amount,
                total: amount * (value || 0),
            } as IPricingCalculatorAmount;
        });
    },
);

export const selectIsPaymentCalculatorFound = createSelector(
    [selectPaymentCalculator],
    (calculator): boolean => {
        return _.some(calculator, (value) => value !== undefined && value !== null);
    },
);

export const selectEstimationData = createSelector([selectPaymentCalculator], (calculator) => {
    const calculatorKeys = Object.keys(calculator);
    return estimationItemsData.filter((item) => !calculatorKeys.includes(item.type));
});

export default PaymentCalculator.reducer;

export interface IPricingCalculatorAmount {
    key: PaymentCalculatorType;
    value: number;
    amount: number;
    total: number;
}

export type IPricingCalculatorAmountList = IPricingCalculatorAmount[];
