import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'antd';
import { CoreButton, CoreTitle } from '@packages';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { useTranslation } from 'react-i18next';
import { IUserFilterPayload } from '@models/user.model';

export type ICustomUserFilterData = Omit<IUserFilterPayload, 'Next' | 'Nonce' | 'PageLimit'>;

interface ICustomerFiltersProps {
    handleFilterSubmit: (data: IUserFilterPayload) => void;
    defaultValues?: any;
}

const CustomUserFIlter = ({ handleFilterSubmit, defaultValues }: ICustomerFiltersProps) => {
    const { t } = useTranslation();
    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        formState: { errors },
        reset,
    } = useForm<ICustomUserFilterData>({ mode: 'onTouched' });

    const onSubmit = (data: ICustomUserFilterData) => {
        handleFilterSubmit(data);
    };

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    return (
        <div className='customer-filter-wrapper'>
            <div className='margin-bottom-20'>
                <CoreTitle tag='h6' text={t('FILTERS')} />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row gutter={10}>
                    <Col span={12}>
                        <InlineGenericInput
                            inputCol={24}
                            levelCol={24}
                            control={control}
                            errors={errors}
                            dataKey='Name'
                            level='Name'
                            errorMessageSize='small'
                            marginBottom={10}
                        />
                    </Col>
                    <Col span={12}>
                        <InlineGenericSelect
                            inputCol={24}
                            levelCol={24}
                            control={control}
                            errors={errors}
                            dataKey='IsActive'
                            level='Active'
                            placeHolder='Active'
                            errorMessageSize='small'
                            marginBottom={0}
                            setFormValue={setFormValue}
                            options={[
                                { value: true, label: 'True' },
                                { value: false, label: 'False' },
                            ]}
                            allowClear
                        />
                    </Col>
                    <Col span={12}>
                        <InlineGenericSelect
                            inputCol={24}
                            levelCol={24}
                            control={control}
                            errors={errors}
                            dataKey='IsVerified'
                            level='Verified'
                            placeHolder='Verified'
                            errorMessageSize='small'
                            marginBottom={0}
                            setFormValue={setFormValue}
                            options={[
                                { value: true, label: 'True' },
                                { value: false, label: 'False' },
                            ]}
                            allowClear
                        />
                    </Col>
                    <Col span={12}>
                        <InlineGenericInput
                            inputCol={24}
                            levelCol={24}
                            control={control}
                            errors={errors}
                            dataKey='PhoneNumber'
                            level={t('PHONE_NUMBER')}
                            type='phoneNumber'
                            hideError={true}
                            marginBottom={20}
                            errorMessageSize='small'
                        />
                    </Col>
                    <Col span={12}>
                        <InlineGenericInput
                            inputCol={24}
                            levelCol={24}
                            control={control}
                            errors={errors}
                            dataKey='Email'
                            level={t('EMAIL')}
                            errorMessageSize='small'
                            marginBottom={10}
                        />
                    </Col>
                </Row>

                <Row justify='end'>
                    <CoreButton
                        type='primary'
                        htmlType='submit'
                        isFullWidth={false}
                        text={t('APPLY_NOW')}
                    />
                </Row>
            </form>
        </div>
    );
};

export default CustomUserFIlter;
