import { Col, message, Radio, Row } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { CoreButton } from '@packages';
import './UserInfoForm.scss';
import { IUpdateUser } from '@models/user.model';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
    onChangeUserProfile,
    selectLoggedUserInfo,
    UpdateLoggedUserRequest,
} from '@store/slices/userSlice';
import { FC, useEffect, useMemo, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import countryList from 'react-select-country-list';
import InlineGenericDate from '@components/common/InlineGenericDate';
import { RegexValidators } from '@config/regex-validator-constant';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import InlineGenericInput from '@components/common/InlineGenericInput';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import GenericStreetName from '@components/common/GenericStreetName';
import GenericInputMaxLimit from '@components/common/GenericInputMaxLimit';
import GenericPostalCode from '@components/common/GenericPostalCode';

interface IUserInfoParams {
    fileID?: string;
    fileUrl?: string;
}

const UserInfoForm: FC<IUserInfoParams> = ({ fileID, fileUrl }) => {
    const countryOptions = useMemo(() => countryList().getData(), []);
    const { userDetails } = useAppSelector(selectLoggedUserInfo);
    const [isLoading, setLoading] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        formState: { errors, isValid },
        reset,
    } = useForm<IUpdateUser>({
        mode: 'onChange',
        defaultValues: {},
    });

    useEffect(() => {
        if (userDetails) {
            reset(userDetails);
        }
    }, [userDetails]);

    const onSubmit = (data: IUpdateUser) => {
        setLoading(true);
        dispatch(
            UpdateLoggedUserRequest({
                ...data,
                Address: {
                    ...data?.Address,
                    BuildingNumber: parseInt(data?.Address?.BuildingNumber as string),
                    AdditionalNumber: parseInt(data?.Address?.AdditionalNumber as string),
                    PostalCode: parseInt(data?.Address?.PostalCode as string),
                },
                DateOfBirth: data?.DateOfBirth || '',
                DisplayPictureId: fileID || userDetails?.DisplayPictureId,
            }),
        )
            .then(unwrapResult)
            .then((result) => {
                fileUrl && dispatch(onChangeUserProfile(fileUrl));
                setLoading(false);
                message.success('Successfully updated profile');
            })
            .catch((err) => {
                setLoading(false);
                message.error('There was an error while updating profile');
            });
    };
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row gutter={20}>
                    <Col span={12}>
                        <InlineGenericInput
                            control={control}
                            errors={errors}
                            levelCol={24}
                            inputCol={24}
                            dataKey='FirstName'
                            level={t('FIRST_NAME')}
                            marginBottom={20}
                            rules={{
                                required: true,
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <InlineGenericInput
                            control={control}
                            errors={errors}
                            levelCol={24}
                            inputCol={24}
                            dataKey='LastName'
                            level={t('LAST_NAME')}
                            marginBottom={20}
                            rules={{
                                required: true,
                            }}
                        />
                    </Col>
                </Row>

                {/* phone number */}
                <Row gutter={20}>
                    <Col span={12}>
                        <InlineGenericInput
                            control={control}
                            errors={errors}
                            levelCol={24}
                            inputCol={24}
                            dataKey='PhoneNumber'
                            level={t('PHONE_NUMBER')}
                            type='phoneNumber'
                            marginBottom={20}
                            rules={{
                                maxLength: 15,
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <div className='general-input-wrapper'>
                            <label className='general-label'> {t('SALUTATION')}</label>
                            <Controller
                                render={({ field }) => (
                                    <div className='margin-top-16'>
                                        <Radio.Group
                                            {...field}
                                            name='radiogroup'
                                            defaultValue={'MR'}
                                        >
                                            <Radio value={'MR'}>{t('MR')}</Radio>
                                            <Radio value={'MRS'}>{t('MRS')}</Radio>
                                        </Radio.Group>
                                    </div>
                                )}
                                control={control}
                                defaultValue='MR'
                                name='Salutation'
                            />
                        </div>
                    </Col>
                </Row>

                {/* dob gender */}

                <Row gutter={20}>
                    <Col span={12}>
                        <InlineGenericDate
                            levelCol={24}
                            inputCol={24}
                            control={control}
                            errors={errors}
                            dataKey='DateOfBirth'
                            level={t('DATE_OF_BIRTH')}
                            setFormValue={setFormValue}
                            rules={{
                                required: true,
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <div className='general-input-wrapper'>
                            <label className='general-label '>{t('GENDER')}</label>
                            <Controller
                                render={({ field }) => (
                                    <div className='margin-top-16'>
                                        <Radio.Group {...field} name='radiogroup'>
                                            <Radio value={'MALE'}>{t('MALE')}</Radio>
                                            <Radio value={'FEMALE'}>{t('FEMALE')}</Radio>
                                            <Radio value={'OTHERS'}>{t('OTHERS')}</Radio>
                                        </Radio.Group>
                                    </div>
                                )}
                                control={control}
                                name='Gender'
                            />
                        </div>
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col span={12}>
                        <InlineGenericInput
                            control={control}
                            errors={errors}
                            levelCol={24}
                            inputCol={24}
                            dataKey='SecondaryPhoneNumber'
                            level={t('SECONDARY_PHONE_NUMBER')}
                            type='phoneNumber'
                            marginBottom={20}
                            rules={{
                                maxLength: 15,
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <InlineGenericInput
                            control={control}
                            errors={errors}
                            levelCol={24}
                            inputCol={24}
                            dataKey='SecondaryEmail'
                            level={t('SECONDARY_EMAIL')}
                            marginBottom={20}
                            rules={{
                                pattern: {
                                    value: RegexValidators.EMAIL,
                                    message: t('INVALID_ENTRY_EMAIL.COM'),
                                },
                            }}
                        />
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col span={12}>
                        <InlineGenericInput
                            levelCol={24}
                            inputCol={24}
                            control={control}
                            errors={errors}
                            dataKey='Address.BuildingNumber'
                            level={t('BUILDING_NUMBER')}
                            type='buildingNumber'
                            rules={{
                                pattern: {
                                    value: RegexValidators.ONLY_NUMERIC,
                                    message: t('BUILDING_NUMBER_SHOULD_BE_NUMERIC'),
                                },
                                maxLength: 50,
                            }}
                            marginBottom={20}
                        />
                    </Col>
                    <Col span={12}>
                        <InlineGenericInput
                            levelCol={24}
                            inputCol={24}
                            control={control}
                            errors={errors}
                            dataKey='Address.AdditionalNumber'
                            level={t('ADDITIONAL_NUMBER')}
                            type='number'
                            marginBottom={20}
                        />
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col span={12}>
                        <GenericStreetName
                            levelCol={24}
                            inputCol={24}
                            control={control}
                            errors={errors}
                            dataKey='Address.Street'
                            type='arabic'
                            level={t('STREET_ARABIC')}
                            rules={{
                                pattern: {
                                    value: RegexValidators.ARABIC_CHARACTER,
                                    message: `${t('STREET_ONLY_ALLOW_ARABIC')}`,
                                },
                                maxLength: 1000,
                            }}
                            marginBottom={20}
                        />
                    </Col>
                    <Col span={12}>
                        <GenericStreetName
                            levelCol={24}
                            inputCol={24}
                            control={control}
                            errors={errors}
                            dataKey='Address.StreetEn'
                            level={t('STREET_ENGLISH')}
                            type='english'
                            rules={{
                                pattern: {
                                    message: `${t('STREET_ONLY_ALLOW_ENGLISH')}`,
                                    value: RegexValidators.ENGLISH_CHARACTER,
                                },
                                maxLength: 1000,
                            }}
                            marginBottom={20}
                        />
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col span={12}>
                        <GenericInputMaxLimit
                            levelCol={24}
                            inputCol={24}
                            control={control}
                            errors={errors}
                            dataKey='Address.AdditionalStreet'
                            level={t('ADDITIONAL_STREET')}
                            rules={{
                                maxLength: 127,
                            }}
                            maxLimit={127}
                            marginBottom={20}
                        />
                    </Col>
                    <Col span={12}>
                        <GenericInputMaxLimit
                            levelCol={24}
                            inputCol={24}
                            control={control}
                            errors={errors}
                            dataKey='Address.City'
                            level={t('CITY')}
                            rules={{
                                maxLength: 127,
                            }}
                            maxLimit={127}
                            type='mixed'
                            marginBottom={20}
                        />
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col span={12}>
                        <GenericInputMaxLimit
                            levelCol={24}
                            inputCol={24}
                            control={control}
                            errors={errors}
                            dataKey='Address.District'
                            level={t('DISTRICT')}
                            maxLimit={127}
                            type='mixed'
                            marginBottom={20}
                        />
                    </Col>
                    <Col span={12}>
                        <GenericPostalCode
                            levelCol={24}
                            inputCol={24}
                            control={control}
                            errors={errors}
                            dataKey='Address.PostalCode'
                            level={t('POSTAL_CODE')}
                            hideError={false}
                            marginBottom={20}
                        />
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col span={12}>
                        <GenericInputMaxLimit
                            levelCol={24}
                            inputCol={24}
                            control={control}
                            errors={errors}
                            dataKey='Address.ProvinceOrState'
                            level={t('PROVINCE')}
                            rules={{
                                maxLength: 127,
                            }}
                            maxLimit={127}
                            type='mixed'
                            marginBottom={20}
                        />
                    </Col>
                    <Col span={12}>
                        <InlineGenericSelect
                            levelCol={24}
                            inputCol={24}
                            control={control}
                            errors={errors}
                            dataKey='Address.CountryCode'
                            level={t('COUNTRY')}
                            placeHolder={t('COUNTRY')}
                            options={countryOptions}
                            setFormValue={setFormValue}
                            marginBottom={5}
                        />
                    </Col>
                </Row>

                <Row className='margin-top-10 margin-bottom-34' justify='end'>
                    <Col className='margin-right-10'>
                        <CoreButton
                            type='basic'
                            antType='text'
                            isFullWidth={false}
                            text={t('CANCEL')}
                            onClick={() => navigate('/profile')}
                        />
                    </Col>
                    <Col>
                        <CoreButton
                            type='primary'
                            htmlType='submit'
                            isFullWidth={false}
                            text={t('SAVE')}
                            loading={isLoading}
                            disabled={!isValid}
                        />
                    </Col>
                </Row>
            </form>
        </div>
    );
};
export default UserInfoForm;
