import { PaymentAPI } from '@service/payment.service';
import { useAppDispatch } from '@store/hooks';
import { getPaymentMethods } from '@store/slices/paymentSlice';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import { Col, notification, Row } from 'antd';
import { useState } from 'react';
import './AddPayment.scss';
import { useForm } from 'react-hook-form';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { CoreButton } from '@packages';
import { useTranslation } from 'react-i18next';

interface IAddPaymentForm {
    nameOnCard: string;
}
export interface AddPaymentFormProps {
    onCancelModal: () => void;
}

const stripeElementStyle = {
    style: {
        base: {
            fontSize: '16px',
        },
    },
};

const stripeCardNumberElementStyle = {
    base: {
        fontSize: '16px',
    },
    complete: {
        color: '#4CAF50',
    },
    invalid: {
        color: '#FF5252',
        iconColor: '#FF5252',
    },
};

function AddPaymentForm({ onCancelModal }: AddPaymentFormProps) {
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const elements = useElements();
    const stripe = useStripe();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IAddPaymentForm>({
        defaultValues: {
            nameOnCard: '',
        },
    });

    const onSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }

        const cardNumberElement = elements.getElement(CardNumberElement);
        setLoading(true);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElement as any,
        });

        if (error) {
            api.error({
                message: 'Payment Creation Failed',
                description: error.message,
            });
            setLoading(false);
        } else {
            await PaymentAPI.addPayment(paymentMethod.id)
                .then((data) => {
                    console.log(data);
                    api.success({
                        message: 'Successfully Payment Creation',
                        description: 'Payment method added successfully!',
                    });
                    setLoading(false);
                    onCancelModal();
                    getPayments();
                })
                .catch((error) => {
                    console.log(error);
                    api.error({
                        message: 'Payment ID Creation Failed',
                        description:
                            'Payment ID creation failed in HASH but payment creation success in stripe',
                    });
                    setLoading(false);
                });
        }
    };

    const getPayments = () => {
        dispatch(getPaymentMethods());
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {contextHolder}
            <Row gutter={16}>
                <Col span={18}>
                    <InlineGenericInput
                        inputCol={24}
                        levelCol={24}
                        control={control}
                        errors={errors}
                        dataKey='NameOnCard'
                        level='Name on card'
                        errorMessageSize='small'
                        marginBottom={10}
                        rules={{
                            required: true,
                        }}
                    />
                </Col>
                <Col span={6}>
                    <div className='general-input-wrapper'>
                        <label className='general-label'>Expiry</label>
                        <div className='stripe-input'>
                            <CardExpiryElement options={stripeElementStyle} />
                        </div>
                    </div>
                </Col>
                <Col span={18}>
                    <div className='general-input-wrapper'>
                        <label className='general-label'>Card Number</label>
                        <div className='stripe-input'>
                            <CardNumberElement
                                options={{ showIcon: true, style: stripeCardNumberElementStyle }}
                            />
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className='general-input-wrapper'>
                        <label className='general-label'>CVC</label>
                        <div className='stripe-input'>
                            <CardCvcElement options={stripeElementStyle} />
                        </div>
                    </div>
                </Col>
            </Row>

            <div className='add-payment-actions'>
                <CoreButton
                    key={1}
                    isFullWidth={true}
                    text={t('CANCEL')}
                    type={'basic'}
                    onClick={onCancelModal}
                />
                <CoreButton
                    htmlType='submit'
                    isFullWidth={true}
                    key={2}
                    text='Add Card'
                    type={'primary'}
                    loading={isLoading}
                    className='submit-modal-button'
                />
            </div>
        </form>
    );
}

export default AddPaymentForm;
