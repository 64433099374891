import { Col, message, Row, Spin, Transfer } from 'antd';
import React, { useState } from 'react';
import './resource-group-create.scss';
import { Controller, useForm } from 'react-hook-form';
import { IResourceGroup } from '@models/admin/resource-group.model';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InlineGenericRadio from '@components/common/InlineGenericRadio';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { CoreButton } from '@packages';

export default function ResourceGroupCreate() {
    const { id: resourceGroupId } = useParams();
    const [isPageLoading, setPageLoading] = useState<boolean>(false);
    const [isButtonLoading, setButtonLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const {
        control,
        handleSubmit,
        clearErrors,
        setValue: setFormValue,
        formState: { errors },
        reset,
        watch,
    } = useForm<IResourceGroup>({
        mode: 'onChange',
        defaultValues: {},
    });

    const onResourceCreate = async (data: IResourceGroup) => {
        try {
            setButtonLoading(true);
            // const response = await CustomerAPI.createCustomer(modifyCustomerData(data));
            setButtonLoading(false);
            message.success(t('Resource Group Successfully Created'));
            onCancel();
        } catch {
            setButtonLoading(false);
            message.error(t('Resource Group Create Failed'));
        }
    };
    const onResourceUpdate = async (data: IResourceGroup) => {
        try {
            setButtonLoading(true);
            // const response = await CustomerAPI.updateCustomer(
            //     customerId ?? '',
            //     modifyCustomerData(data),
            // );
            setButtonLoading(false);
            message.success(t('Resource Group Successfully Update'));
            onCancel();
        } catch {
            setButtonLoading(false);
            message.error(t('Resource Group Update Failed'));
        }
    };
    const onCancel = () => {
        navigate('/admin/resource-group', {
            state: {
                delay: true,
            },
        });
    };
    return (
        <Spin spinning={isPageLoading}>
            <div className='resource-create-wrapper'>
                <form
                    onSubmit={handleSubmit(resourceGroupId ? onResourceUpdate : onResourceCreate)}
                >
                    <Row gutter={[24, 24]}>
                        <Col span={12}>
                            <InlineGenericInput
                                levelCol={24}
                                inputCol={24}
                                control={control}
                                errors={errors}
                                dataKey='name'
                                level='Resource Group Name'
                                showLevel={true}
                                marginBottom={20}
                                errorMessageSize='small'
                                rules={{
                                    required: true,
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <InlineGenericRadio
                                levelCol={24}
                                inputCol={24}
                                setFormValue={setFormValue}
                                control={control}
                                errors={errors}
                                clearErrors={clearErrors}
                                options={[
                                    {
                                        label: `Yes`,
                                        value: 'true',
                                    },
                                    {
                                        label: `No`,
                                        value: 'false',
                                    },
                                ]}
                                direction='horizontal'
                                dataKey='isAllowedForAssign'
                                level='Allowed For Assign'
                                showLevel={true}
                                marginBottom={20}
                                errorMessageSize='small'
                                rules={{
                                    required: true,
                                }}
                            />
                        </Col>

                        <Col span={24}>
                            <div className='general-input-wrapper'>
                                <label className='general-label'>Select Resources</label>
                                <Controller
                                    name='resources'
                                    control={control}
                                    render={({ field }) => (
                                        <Transfer
                                            {...field}
                                            className='custom-transfer'
                                            dataSource={[]}
                                            titles={['Available Resources', 'Selected Resources']}
                                            targetKeys={[]}
                                            onChange={() => {
                                                console.log('onchange');
                                            }}
                                            render={(item) => item}
                                        />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors.resources && (
                                    <span className='error-message'>Roles are required</span>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <div className='submit-action-buttons'>
                        <CoreButton
                            text={t('CANCEL')}
                            type='basic'
                            htmlType='button'
                            onClick={onCancel}
                        />
                        <CoreButton
                            loading={isButtonLoading}
                            text={t('SAVE')}
                            type='primary'
                            htmlType='submit'
                        />
                    </div>
                </form>
            </div>
        </Spin>
    );
}
