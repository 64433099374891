import { Navigate, Route, Routes } from 'react-router-dom';
import {
    RecoverAccount,
    Login,
    SignUp,
    SignUpConfirmation,
    TwoStepVerification,
    VerifyAccount,
    ResetPassword,
} from '../client-features/auth';
import Maintenance from '../client-features/maintenance/Maintenance';
import { RequireAnonymous, RequireAuth } from '@components';
import ActivateAccount from '../client-features/auth/activate-account/ActivateAccount';
import CoreContactUs from '../packages/CoreContactUs/CoreContactUs';
import ResetPasswordConfirmation from '../client-features/auth/reset-password/ResetPasswordConfirmation';
import Authorization from '../client-features/authorization/Authorization';
import Home from './../home/Home';
import AdminRouter from './Admin-Router';
import ClientRouter from './Client-Router';
import React from 'react';
import { UserRoles } from '@config/user-roles';

const AppRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='authorization' element={<Authorization />}></Route>
            <Route path='contact-us' element={<CoreContactUs />}></Route>

            <React.Fragment>
                <Route element={<RequireAuth allowedRoles={[UserRoles.organizationAdmin]} />}>
                    <Route path='*' element={<ClientRouter />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={[UserRoles.admin]} />}>
                    <Route path='admin/*' element={<AdminRouter />} />
                </Route>
            </React.Fragment>

            <Route path='*' element={<Navigate replace to='/' />}></Route>

            <Route element={<RequireAnonymous />}>
                <Route path='sign-up' element={<SignUp />}></Route>
                <Route path='sign-up-confirmation' element={<SignUpConfirmation />}></Route>
                <Route path='verify-account' element={<VerifyAccount />}></Route>
                <Route path='login' element={<Login />}></Route>
                <Route path='recover-account' element={<RecoverAccount />}></Route>
                <Route path='activate-account' element={<ActivateAccount />}></Route>
                <Route path='reset-password' element={<ResetPassword />}></Route>
                <Route
                    path='reset-password-confirmation'
                    element={<ResetPasswordConfirmation />}
                ></Route>
                <Route path='maintenance' element={<Maintenance />}></Route>
                <Route path='two-step-verification' element={<TwoStepVerification />}></Route>
            </Route>
        </Routes>
    );
};

export default AppRouter;
