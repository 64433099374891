import { RequestStatus } from '@models/async-status.enum';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import {
    ISubscribePlanDetails,
    ISubscribePlan,
    ISubscriptionConfiguration,
    IAlert,
} from '@models/subscription.model';
import { SubscribeAPI } from '@service/subscription.service';
import { calculateTimeDifference } from '@utils/calculateDayDifference';
import { ISingleTierPayload, ITier } from '@models/billing.model';
import { payAsGoTiredData } from '@utils/payAsGoTireData';

interface ISubscribeState {
    status: RequestStatus;
    data: ISubscribePlan[];
    subscription: ISubscribePlanDetails;
    configuration: ISubscriptionConfiguration;
    tiers: any;
    alerts: IAlert[];
    tier: {
        isLoading: boolean;
        data: ITier | null;
    };
}

const initialState: ISubscribeState = {
    status: 'idle',
    data: [],
    subscription: {} as ISubscribePlanDetails,
    configuration: {} as ISubscriptionConfiguration,
    tiers: [],
    alerts: [],
    tier: {
        isLoading: false,
        data: null,
    },
};

export const getSubscription = createAsyncThunk('getSubscription', async () => {
    try {
        const response = await SubscribeAPI.GetSubscription();
        return response;
    } catch (error: any) {
        throw error as any;
    }
});
export const getSubscriptionConfiguration = createAsyncThunk(
    'getSubscriptionConfiguration',
    async () => {
        try {
            const response = await SubscribeAPI.GetSubscriptionConfiguration();
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const getTiers = createAsyncThunk('getTiers', async () => {
    try {
        const response = await SubscribeAPI.GetTiers();
        return response;
    } catch (error: any) {
        throw error as any;
    }
});

export const getTier = createAsyncThunk('getTier', async (payload: ISingleTierPayload) => {
    try {
        const response = await SubscribeAPI.GetTier(payload);
        return response;
    } catch (error: any) {
        throw error as any;
    }
});
export const getAlerts = createAsyncThunk('getAlerts', async () => {
    try {
        const response = await SubscribeAPI.GetAlerts();
        return response;
    } catch (error: any) {
        throw error as any;
    }
});

export const Subscribe = createSlice({
    name: 'subscribe',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSubscription.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getSubscription.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(getSubscription.fulfilled, (state, action) => {
                state.status = 'idle';
                state.subscription = action.payload;
            })
            .addCase(getSubscriptionConfiguration.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getSubscriptionConfiguration.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(getSubscriptionConfiguration.fulfilled, (state, action) => {
                state.status = 'idle';
                state.configuration = action.payload;
            })
            .addCase(getTiers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getTiers.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(getTiers.fulfilled, (state, action) => {
                state.status = 'idle';
                state.data = action.payload.Tiers;
            })
            .addCase(getTier.pending, (state) => {
                state.tier.isLoading = true;
            })
            .addCase(getTier.fulfilled, (state, action) => {
                state.tier.isLoading = false;
                state.tier.data = action.payload;
            })
            .addCase(getTier.rejected, (state) => {
                state.tier.isLoading = false;
            })
            .addCase(getAlerts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAlerts.fulfilled, (state, action) => {
                state.status = 'failed';
                state.alerts = action.payload;
            })
            .addCase(getAlerts.rejected, (state) => {
                state.status = 'idle';
            });
    },
});

export const subscribe = (state: RootState) => state.subscribe;
export const tierData = (state: RootState) => state.subscribe.data;
export const singleTierData = (state: RootState) => state.subscribe.tier;
export const configuration = (state: RootState) => state.subscribe.configuration;

export const tierSelector = createSelector([tierData], (data) => {
    return data?.map((item) => ({
        value: item.ItemId,
        label: item.Name,
    }));
});
export const selectUpdatedTiredData =
    (type: 'month' | 'year') =>
    (state: RootState): ISubscribePlan[] => {
        const tireData = state.subscribe.data;
        return type === 'month' ? [...tireData, payAsGoTiredData] : tireData;
    };

export const selectCurrentSubscriptions = (state: RootState) => state.subscribe?.subscription;

export const selectIsTrialSubscriptions = createSelector(
    [selectCurrentSubscriptions],
    (subscription) => {
        return subscription?.IsTrial;
    },
);
export const selectPayAsYouGoInfo = (state: RootState) =>
    state.subscribe?.configuration?.PayAsYouGo;

export const selectSubscriptionsExpireTime = createSelector(
    [selectCurrentSubscriptions],
    (subscription) => {
        const expireDate = subscription.Expiration;
        return calculateTimeDifference(expireDate);
    },
);

export default Subscribe.reducer;
