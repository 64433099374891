import { Tag, Button } from 'antd';
import { LOGO } from '../../card/PaymentCardListView';
import { IPaymentMethod } from '@models/payment-method';
import './PaymentCard.scss';

interface IPaymentCardProps {
    card: IPaymentMethod;
    isDefault: boolean;
    onSetDefault: (card: IPaymentMethod) => void;
    onDelete: (card: IPaymentMethod) => void;
}

const PaymentCard = ({ card, isDefault, onSetDefault, onDelete }: IPaymentCardProps) => {
    return (
        <div className='payment-card-wrapper round-box-shadow'>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'start',
                    width: '100%',
                }}
            >
                <div className='payment-card-image-wrapper'>
                    <img
                        src={card.Brand === 'visa' ? LOGO.visa : LOGO.master}
                        alt={card.Brand}
                        style={{ width: 32 }}
                    />
                </div>
                <div className='payment-card-details'>
                    <p className='card-brand'>{`${card.Brand} ending in ${card.Last4}`}</p>
                    <p className='card-exp-date'>Expiry {card.Expire}</p>
                    <div>
                        {isDefault ? (
                            <Button size='small' type='text' className='default-btn'>
                                Default
                            </Button>
                        ) : card.Status === 'Inactive' ? (
                            <Tag color='red'>Expired</Tag>
                        ) : (
                            <Button
                                size='small'
                                type='text'
                                onClick={() => onSetDefault(card)}
                                className='default-btn'
                            >
                                Set as Default
                            </Button>
                        )}
                    </div>
                </div>
            </div>

            <div className='remove-btn'>
                <Button type='text' danger onClick={() => onDelete(card)}>
                    Remove
                </Button>
            </div>
        </div>
    );
};

export default PaymentCard;
