import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Checkbox, Input, message, notification, Popover } from "antd";
import { CoreButton, CoreLoginWrapper, CoreTitle } from "../../packages";
import { RegexValidators } from "@config/regex-validator-constant";
import { BorderOutlined, CheckSquareOutlined } from "@ant-design/icons";
import "./CoreSignUpPage.scss";
import { useNavigate } from "react-router-dom";
import { authAPI } from "@service/auth.service";
import { useTranslation } from "react-i18next";
import termsConditions from '@assets/pdf/terms-and-conditions.pdf';
import privacyPolicy from '@assets/pdf/privacy-and-policy.pdf';
import { plainTextToLocalizationText } from "@utils/getLocalizationKey";


interface ISignUp {
    email: string;
    password: string;
    ConfirmPassword?: string;
}

const CoreSignUpPage = () => {
    const { t } = useTranslation();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isConfirmPasswordTouch, setConfirmPasswordTouch] = useState<boolean>(false);
    const [matchPassword, setMatchPassword] = useState(false);
    const [isTenChar, setIsTenChar] = useState(false);
    const [containsUpperAndLower, setContainsUpperAndLower] = useState(false);
    const [containsNumber, setContainsNumber] = useState(false);
    const [containsSpecial, setContainsSpecial] = useState(false);
    const [isTrueTermsAndCondition, setTermsAndCondition] = useState(false);

    const [messageApi, contextHolder] = notification.useNotification();
    const [isLoading, setLoading] = useState(false);

    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<ISignUp>({ mode: "onTouched" });

    const navigate = useNavigate();

    const onSubmit = async (data: ISignUp) => {
        let payload: ISignUp = {
            email: data.email,
            password: '',
        }

        if (matchPassword) {
            payload.password = password
        }


        if (isValid && matchPassword) {
            setLoading(true)
            const response = await authAPI.createAccount(payload);
            setLoading(false)
            if (response.Success) {
                navigate('/sign-up-confirmation');
            } else {
                message.error(t(plainTextToLocalizationText(response?.Message)));
            }

        }
    };
    const onChangePassword = (e: any) => {
        const pass = e.target.value;
        pass.length >= 10 ? setIsTenChar(true) : setIsTenChar(false);
        pass.match(RegexValidators.UPPERCASE_LOWERCASE)
            ? setContainsUpperAndLower(true)
            : setContainsUpperAndLower(false);
        pass.match(RegexValidators.ONLY_ONE_NUMBER)
            ? setContainsNumber(true)
            : setContainsNumber(false);
        pass.match(RegexValidators.SPECIAL_CHARACTER)
            ? setContainsSpecial(true)
            : setContainsSpecial(false);
        setPassword(pass);
        pass === confirmPassword ? setMatchPassword(true) : setMatchPassword(false)

    };

    const onChangeConfirmPassword = (e: any) => {
        const confirmPassword = e.target.value
        password === confirmPassword ? setMatchPassword(true) : setMatchPassword(false)
        setConfirmPassword(confirmPassword)
        setConfirmPasswordTouch(true)
    }

    const isValidationMatch = matchPassword && isTenChar && containsUpperAndLower && containsNumber && containsSpecial && isTrueTermsAndCondition;

    const [isInputFocused, setIsInputFocused] = useState(false);

    const handleInputFocus = () => {
        setIsInputFocused(true);
    };

    const handleInputBlur = () => {
        setIsInputFocused(false);
    };

    const content = (
        <div style={{ width: "100%" }} className="mb-10">
            <p className="validation-header">
                {t('YOUR_PASSWORD_NEEDS_TO_BE_AT_LEAST')}
            </p>
            <div className="error-validation">
                {!isTenChar ? (
                    <BorderOutlined className="success-box" />
                ) : (
                    <CheckSquareOutlined className="success-checkbox" />
                )}
                <p className="validation-message">{t('10_CHARACTERS_LONG')}</p>
            </div>
            <div className="error-validation">
                {!containsUpperAndLower ? (
                    <BorderOutlined className="success-box" />
                ) : (
                    <CheckSquareOutlined className="success-checkbox" />
                )}
                <p className="validation-message">{t('INCLUDING_1_UPPERCASE_AND_1_LOWERCASE')}</p>
            </div>

            <div className="error-validation">
                {!containsSpecial ? (
                    <BorderOutlined className="success-box" />
                ) : (
                    <CheckSquareOutlined className="success-checkbox" />
                )}
                <p className="validation-message">{t('INCLUDING_1_SPECIAL_CHARACTER')}</p>
            </div>

            <div className="error-validation">
                {!containsNumber ? (
                    <BorderOutlined className="success-box" />
                ) : (
                    <CheckSquareOutlined className="success-checkbox" />
                )}
                <p className="validation-message">{t('ADD_1_NUMBER')}</p>
            </div>

        </div>
    )

    const handleKeyPress = (event: any) => {
        if (event.key === ' ') {
            event.preventDefault();
        }
    };

    return (
        <CoreLoginWrapper>
            <div className="core-sign-up-wrapper">
                {contextHolder}
                <div className="core-sign-up-wrapper-content">
                    <div>
                        <CoreTitle tag="h1" text={t('WELCOME_TO')} />
                        <CoreTitle tag="h1" text={t('E_INVOICE_SOLUTIONS')} />
                        <p className="core-sign-up-sub-title">{t('CREATE_YOUR_ACCOUNT')}</p>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='general-input-wrapper'>
                            <label className='general-label'>{t('EMAIL_ADDRESS')}</label>
                            <Controller
                                name="email"
                                render={({ field }) => <Input {...field} placeholder={t('EMAIL_ADDRESS')}
                                                              className="general-input"
                                                              onKeyPress={handleKeyPress} name="email"
                                                              autoComplete="off" />}
                                control={control}
                                rules={{
                                    required: true,
                                    pattern: RegexValidators.EMAIL,
                                }}
                            />
                            {errors?.email?.type === "required" && (
                                <span className='error-message'>{t('EMAIL_FIELD_IS_REQUIRED')}</span>)}
                            {errors?.email?.type === "pattern" && (
                                <span className='error-message'>{t("INVALID_EMAIL_ADDRESS")}</span>)}
                        </div>
                        <div className="general-input-wrapper">
                            <label className="general-label">{t("NEW_PASSWORD")}</label>
                            <Popover open={isInputFocused} content={content} placement='right'>
                                <Input.Password placeholder={t("PASSWORD")} onFocus={handleInputFocus}
                                                onBlur={handleInputBlur}
                                                className="general-input" onChange={onChangePassword}
                                                onKeyPress={handleKeyPress} name="password" autoComplete="off" />
                            </Popover>
                            {errors?.password?.type === "required" && (
                                <span className="error-message">{t('PASSWORD_IS_REQUIRED')}</span>
                            )}

                            <div className="general-input-wrapper margin-top-20">
                                <label className="general-label">{t('CONFIRM_PASSWORD')}</label>
                                <Input.Password placeholder={t("PASSWORD")} className="general-input"
                                                onClick={() => {
                                                    setConfirmPasswordTouch(true)
                                                }}
                                                onChange={onChangeConfirmPassword} onKeyPress={handleKeyPress}
                                                name="confirmPassword" autoComplete="off" />
                                {!matchPassword && isConfirmPasswordTouch && (
                                    <span className="error-message">{t("PASSWORD_DOSNT_MATCH")}</span>
                                )}
                            </div>
                        </div>
                        <div className="terms-conditions mb-30">
                            <Checkbox checked={isTrueTermsAndCondition} className="general-checkbox" onChange={(e) => {
                                const isChecked = e.target.checked;
                                setTermsAndCondition(isChecked)
                            }}>
                                {t('I_HAVE_READ_AND_AGREED_TO_THE_FOLLOWING')}<a
                                className="sign-in-link" href={privacyPolicy} target="_blank"> {t('PRIVACY_POLICY')}</a>,
                                <a className="sign-in-link" href={termsConditions}
                                   target="_blank"> {t('TERMS_AND_CONDITIONS')}</a> {t('BEFORE_PROCEEDING')}
                            </Checkbox>
                        </div>

                        <CoreButton
                            type="primary"
                            htmlType="submit"
                            isFullWidth={true}
                            text={t('CREATE_ACCOUNT')}
                            disabled={!isValidationMatch}
                            loading={isLoading}
                        />
                    </form>
                    <div className='core-sign-up-buttons'>
                        <p>{t('ALREADY_HAVE_AN_ACCOUNT')}
                            <span className="sign-in-link" onClick={() => navigate('/log-in')}>{t('SIGN_IN')}</span>
                        </p>
                    </div>
                </div>
            </div>
        </CoreLoginWrapper>
    );
};

export default CoreSignUpPage;
