import { useEffect, useState } from 'react';
import { message, Modal } from 'antd';
import { useForm } from 'react-hook-form';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { CoreButton } from '@packages';
import InlineGenericRadio from '@components/common/InlineGenericRadio';
import { IAlert, IUpdateAlertPayload } from '@models/subscription.model';
import { SubscribeAPI } from '@service/subscription.service';
import { getAlerts } from '@store/slices/subscribeSlice';
import { useAppDispatch } from '@store/hooks';
import { alertType } from '../const/alertTypeEnum';

interface IUpdateAlertModalProps {
    isVisible: boolean;
    onClose: () => void;
    selectedAlert: IAlert | null;
}

const getAlertTypeName = (alertValue: string | undefined): string => {
    const entries = Object.entries(alertType);
    const matchedAlertType = entries.find(([key, value]) => value === alertValue);
    return matchedAlertType ? matchedAlertType[0] : '';
};

const UpdateAlertModal = ({ isVisible, onClose, selectedAlert }: IUpdateAlertModalProps) => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {
        control,
        handleSubmit,
        reset,
        clearErrors,
        setValue: setFormValue,
        formState: { errors },
    } = useForm<any>();

    useEffect(() => {
        if (selectedAlert) {
            reset({
                Value: Number(selectedAlert.Value),
                IsActive: selectedAlert.IsActive ? 'true' : 'false',
            });
        }
    }, [selectedAlert, reset]);

    const onSubmit = (data: any) => {
        const alertPayload: IUpdateAlertPayload = {
            Value: Number(data.Value),
            IsActive: data.IsActive === 'true' ? true : false,
            AlertType: selectedAlert?.AlertType,
        };
        setIsLoading(true);
        SubscribeAPI.UpdateAlert(alertPayload)
            .then(() => {
                dispatch(getAlerts());
                message.success('Update alert successful');
                reset();
                onClose();
            })
            .catch((error) => {
                message.error(error?.data?.Message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onCancel = () => {
        reset();
        onClose();
    };

    const alertTypeName = selectedAlert ? getAlertTypeName(selectedAlert.AlertType) : '';

    return (
        <Modal
            title='Update Alert'
            open={isVisible}
            onCancel={onCancel}
            maskClosable={false}
            footer={null}
        >
            <form onSubmit={handleSubmit(onSubmit)} className='margin-top-16'>

                <p>Alert Type: {alertTypeName}</p>

                <InlineGenericInput
                    inputCol={24}
                    levelCol={24}
                    control={control}
                    errors={errors}
                    type='number'
                    dataKey='Value'
                    level='Value'
                    errorMessageSize='small'
                    marginBottom={10}
                    rules={{
                        required: true,
                    }}
                />

                <InlineGenericRadio
                    levelCol={24}
                    inputCol={24}
                    setFormValue={setFormValue}
                    control={control}
                    errors={errors}
                    clearErrors={clearErrors}
                    options={[
                        { label: 'Active', value: 'true' },
                        { label: 'Inactive', value: 'false' },
                    ]}
                    dataKey='IsActive'
                    level='Is Active'
                    rules={{
                        required: true,
                    }}
                />

                <div style={{ textAlign: 'end' }}>
                    <CoreButton
                        text='Update'
                        type='primary'
                        htmlType='submit'
                        size='small'
                        loading={isLoading}
                    />
                </div>
            </form>
        </Modal>
    );
};

export default UpdateAlertModal;
