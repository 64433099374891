import React, { useEffect, useState } from 'react';
import './PricingCalculator.scss';
import HeaderLayout from '@components/main-layout/header-layout/HeaderLayout';
import PricingCalculatorEmptyContainer from '../components/PricingCalculatorContainer/PricingCalculatorEmptyContainer';
import PricingCalculatorEmptyCard from '../components/PricingCalculatorCard/PricingCalculatorCard';
import EstimateModal from './../components/EstimationModal/EstimationModal';
import PricingCalculatorContainer from './../components/PricingCalculatorContainer/PricingCalculatorContainer';

import { useAppDispatch, useAppSelector } from '@store/hooks';
import { selectIsPaymentCalculatorFound } from '@store/slices/paymentCalculator';
import { getSubscriptionConfiguration } from '@store/slices/subscribeSlice';

export default function PricingCalculator() {
    const [visibleModal, setVisibleModal] = useState<boolean>(false);
    const isCalculatorFound = useAppSelector(selectIsPaymentCalculatorFound);

    const dispatch = useAppDispatch();

    const onOpenModal = () => {
        setVisibleModal(true);
    };

    useEffect(() => {
        dispatch(getSubscriptionConfiguration());
    }, []);

    return (
        <HeaderLayout>
            <div className='pricing-main-container'>
                {isCalculatorFound ? (
                    <PricingCalculatorContainer />
                ) : (
                    <PricingCalculatorEmptyContainer onPressEstimation={onOpenModal} />
                )}

                <PricingCalculatorEmptyCard onPressEstimation={onOpenModal} />

                <EstimateModal
                    isVisibleModal={visibleModal}
                    onHideModal={() => setVisibleModal(false)}
                />
            </div>
        </HeaderLayout>
    );
}
