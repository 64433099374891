import ServiceTable from './../../components/Service-table/ServiceTable';
import { CoreButton } from '@packages';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './api-services-list.scss';

export default function ApiServicesList() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className='service-list-container'>
            <div className='margin-30'>
                <div className='filter-header'>
                    <a className='link' target='_blank' rel='noopener noreferrer' href={"https://docs.einvoicesolutions.com"}>{t('API_INTEGRATION')}</a>
                    <CoreButton
                        className='submit-button'
                        type='primary'
                        text={t('CREATE_NEW')}
                        isFullWidth={true}
                        onClick={() => navigate('/api-services/create')}
                    />
                </div>
            </div>
            <div className='margin-top-20'>
                <ServiceTable />
            </div>
        </div>
    );
}
