import { CoreBackButton, CoreButton } from '@packages';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { useForm } from 'react-hook-form';
import { ICreateAdminEmailTemplate } from '@models/admin/template.model';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import InlineGenericTextArea from '@components/common/InlineGenericTextArea';
import { unwrapResult } from '@reduxjs/toolkit';
import { message } from 'antd';
import { AdminTemplateAPI } from '@service/admin/template.service';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
    getEmailConfigurations,
    MailConfigurationOptions,
} from '@store/slices/admin/adminTemplateSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';

const EmailTemplateCreate = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const EMailConfigurationOptions = useAppSelector(MailConfigurationOptions);

    const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getEmailConfigurations());
    }, [dispatch]);

    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        formState: { errors },
        reset,
    } = useForm<ICreateAdminEmailTemplate>({
        mode: 'onChange',
    });

    const onSubmit = async (data: ICreateAdminEmailTemplate) => {
        setIsButtonLoading(true);
        await AdminTemplateAPI.createEmailTemplate(data)
            .then(unwrapResult)
            .then(() => {
                message.success('Template Successfully Added');
                setIsButtonLoading(false);
                reset();
                navigate(`/admin/template/email`);
            })
            .catch((err) => {
                message.error(err.data.Message || 'Template Addition Failed');
                setIsButtonLoading(false);
            });
    };

    return (
        <div className='admin-template-create-wrapper'>
            <CoreBackButton text='' />
            <div className='template-create-form-wrapper'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InlineGenericSelect
                        inputCol={24}
                        levelCol={24}
                        control={control}
                        errors={errors}
                        dataKey='MailConfigurationId'
                        level='Mail SubscriptionConfiguration Id'
                        options={EMailConfigurationOptions || []}
                        setFormValue={setFormValue}
                        errorMessageSize='small'
                        allowClear={true}
                        showSearch={false}
                        marginBottom={10}
                        rules={{
                            required: true,
                        }}
                    />

                    <InlineGenericInput
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='EscapedTemplateBody'
                        level='Escaped Template Body'
                        marginBottom={20}
                        rules={{
                            required: true,
                        }}
                    />

                    <InlineGenericInput
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='Language'
                        level='Language'
                        marginBottom={20}
                        rules={{
                            required: true,
                        }}
                    />

                    <InlineGenericTextArea
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='Subject'
                        level='Subject'
                        marginBottom={20}
                        rules={{
                            required: true,
                        }}
                    />

                    <InlineGenericTextArea
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='MailPurpose'
                        level='Mail Purpose'
                        marginBottom={20}
                        minRows={4}
                        rules={{
                            required: true,
                        }}
                    />

                    <div className='template-create-action'>
                        <CoreButton
                            loading={isButtonLoading}
                            htmlType='submit'
                            type='primary'
                            text='ADD'
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EmailTemplateCreate;
