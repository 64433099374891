import { Col, DatePicker, Row } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue } from 'react-hook-form';
import GenericErrorMessage, { ErrorMessageSize } from './GenericErrorMessage';
import GenericTooltip from './GenericTooltip';
import { useTranslation } from 'react-i18next';
import { DEFAULT_DATE_VALUE } from '@config/constants';
import { CustomRules } from '@models/rules.model';
import { isFiledFoundError, isRequiredValue } from '@utils/errorMessageOption';
import _ from 'lodash';

interface IInlineGenericDateParams {
    control: Control<any, any>;
    errors: FieldErrors<any>;
    level: string;
    dataKey: string;
    disabled?: boolean;
    rules?: CustomRules;
    levelCol?: number;
    inputCol?: number;
    showLevel?: boolean;
    showTime?: boolean;
    allowClear?: boolean;
    setFormValue: UseFormSetValue<any>;
    placeHolder?: string;
    children?: React.ReactNode;
    hideError?: boolean;
    gutter?: number;
    tooltip?: string;
    marginBottom?: number;
    errorMessageSize?: ErrorMessageSize;
    disableTodayBeforeDate?: boolean;
    disableTodayAfterDate?: boolean;
    disableDateAfter?: string | Date | dayjs.Dayjs;
    disableDateBefore?: string | Date | dayjs.Dayjs;
    onChange?: (date?: string) => void;
}

const InlineGenericDate: FC<IInlineGenericDateParams> = ({
    control,
    errors,
    level,
    dataKey,
    rules,
    disabled,
    levelCol = 8,
    inputCol = 16,
    showLevel = true,
    showTime = false,
    setFormValue,
    placeHolder,
    allowClear = false,
    hideError,
    children,
    gutter = 130,
    tooltip,
    marginBottom = 30,
    errorMessageSize,
    disableTodayBeforeDate,
    disableDateAfter,
    disableDateBefore,
    disableTodayAfterDate,
    onChange,
}) => {
    const { t } = useTranslation();

    const disabledDate = (current: dayjs.Dayjs) => {
        if (disableTodayBeforeDate && current.isBefore(dayjs().subtract(1, 'day'))) {
            return true;
        }

        if (disableTodayAfterDate && current.isAfter(dayjs())) {
            return true;
        }
        if (disableDateAfter && current && current < dayjs(disableDateAfter)) {
            return true;
        }
        if (disableDateBefore && current && current > dayjs(disableDateBefore)) {
            return true;
        }
        return false;
    };

    return (
        <Row gutter={gutter} align='middle' style={{ marginBottom: marginBottom }}>
            {showLevel && (
                <Col span={levelCol}>
                    <label className='general-label'>
                        {t(level)}
                        {rules?.required && <span className='required-field'>*</span>}
                        {tooltip && <GenericTooltip text={tooltip} />}
                    </label>
                </Col>
            )}
            <Col span={inputCol}>
                <Controller
                    name={dataKey as any}
                    render={({ field }) => {
                        const dateValue =
                            field.value && field.value === DEFAULT_DATE_VALUE
                                ? undefined
                                : field?.value;

                        return (
                            <DatePicker
                                {...field}
                                status={isFiledFoundError(errors, dataKey) ? 'error' : undefined}
                                format={'YYYY/MM/DD'}
                                value={field?.value ? dayjs(dateValue) : undefined}
                                allowClear={allowClear}
                                onChange={(value, dateString) => {
                                    if (value) {
                                        const date = dayjs(value)?.toISOString();
                                        setFormValue(dataKey, date, {
                                            shouldValidate: true,
                                        });
                                        onChange && onChange(date);
                                    } else {
                                        setFormValue(dataKey, undefined, {
                                            shouldValidate: true,
                                        });
                                        onChange && onChange(undefined);
                                    }
                                }}
                                placeholder={placeHolder ? placeHolder : t(level)}
                                className='general-date-picker'
                                disabled={disabled ?? false}
                                showTime={showTime}
                                disabledDate={disabledDate}
                            />
                        );
                    }}
                    control={control}
                    rules={{ ...rules, required: isRequiredValue(rules) }}
                />
                {!hideError && (
                    <GenericErrorMessage
                        errors={errors}
                        dataKey={dataKey}
                        rules={rules}
                        level={level}
                        errorMessageSize={errorMessageSize}
                    />
                )}
                {children}
            </Col>
        </Row>
    );
};

export default InlineGenericDate;
