import { CoreButton, CoreTitle } from '@packages';
import './BillingProfile.scss';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useEffect, useState } from 'react';
import { getSubscription, subscribe } from '@store/slices/subscribeSlice';
import { getBillingAddress, singleBillingAddress } from '@store/slices/billingAddressSlice';
import BillingFormModal from '../billing/billing-form-modal/BillingFormModal';
import { getCountryNameFromCode } from '@utils/getCountryNameFromCode';
import { DEFAULT_EMPTY_VALUE } from '@config/constants';

const BillingProfile = () => {
    const dispatch = useAppDispatch();
    const { subscription } = useAppSelector(subscribe);
    const billingData = useAppSelector(singleBillingAddress);
    const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);

    useEffect(() => {
        if (subscription?.DefaultBillingAddressId) {
            dispatch(
                getBillingAddress({ BillingAddressId: subscription?.DefaultBillingAddressId }),
            );
        }
    }, [subscription?.DefaultBillingAddressId]);

    const callDefaultBillingAddress = () => {
        dispatch(
            getBillingAddress({
                BillingAddressId: subscription?.DefaultBillingAddressId,
            }),
        );
    };

    const handleAddConfirm = () => {
        dispatch(getSubscription());
        setIsAddModalVisible(false);
        callDefaultBillingAddress();
    };

    return (
        <div className='billing-profile-wrapper round-box-shadow'>
            <div className='billing-profile-header'>
                <CoreTitle tag='h6' text='Billing Profile' />

                {subscription?.DefaultBillingAddressId && (
                    <CoreButton
                        text='Edit'
                        size='small'
                        onClick={() => {
                            setIsEditModalVisible(true);
                        }}
                    />
                )}
            </div>

            <div className='billing-profile-block-wrapper'>
                {subscription?.DefaultBillingAddressId ? (
                    <div className='billing-profile-block'>
                        <CoreTitle tag='h6' text={billingData?.AddressTitle} />
                        <p className='block-user-name'>
                            {billingData?.Name} {billingData?.Surname}
                        </p>
                        <p className='block-sub-title'>
                            {billingData?.Address}, {billingData?.City},{' '}
                            {getCountryNameFromCode(billingData?.Country || DEFAULT_EMPTY_VALUE)}
                        </p>
                    </div>
                ) : (
                    <div className='billing-profile-block'>
                        <div className='no-default-address'>
                            <p>You don't have any default billing address.</p>

                            <CoreButton
                                className='add-address-btn'
                                type='primary'
                                text='Add Address'
                                size='small'
                                onClick={() => {
                                    setIsAddModalVisible(true);
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>

            <BillingFormModal
                isVisible={isAddModalVisible}
                onClose={() => {
                    setIsAddModalVisible(false);
                }}
                onConfirm={handleAddConfirm}
            />

            {isEditModalVisible && (
                <BillingFormModal
                    isVisible={isEditModalVisible}
                    onClose={() => setIsEditModalVisible(false)}
                    onConfirm={() => {
                        setIsEditModalVisible(false);
                        callDefaultBillingAddress();
                    }}
                    addressId={subscription?.DefaultBillingAddressId}
                />
            )}
        </div>
    );
};

export default BillingProfile;
