import { AxiosError } from 'axios';
import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import {
    ICreateAdminEmailTemplate,
    ICreateAdminTemplate,
    IDeleteAdminEmailTemplate,
    IDeleteAdminTemplate,
    IGetAdminTemplatesPayload,
    ISingleAdminEmailTemplatePayload,
    ISingleAdminTemplatePayload,
    IUpdateAdminTemplate,
} from '@models/admin/template.model';
import { processQuery } from '@utils/processQuery';

export const AdminTemplateAPI = {
    getPdfTemplates: async (payload: IGetAdminTemplatesPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TemplateCommand/Templates`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getPdfTemplate: async (payload: ISingleAdminTemplatePayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TemplateQuery/Template${processQuery(payload)}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    createPdfTemplate: async (payload: ICreateAdminTemplate): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TemplateCommand/Create`;
        try {
            const response = await AXIOS_INSTANCE.post<ICreateAdminTemplate>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    updatePdfTemplate: async (payload: IUpdateAdminTemplate): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TemplateCommand/Update`;
        try {
            const response = await AXIOS_INSTANCE.post<IUpdateAdminTemplate>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    deletePdfTemplate: async (payload: IDeleteAdminTemplate): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TemplateCommand/Delete`;
        try {
            const response = await AXIOS_INSTANCE.post<IDeleteAdminTemplate>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getEmailTemplates: async (): Promise<any> => {
        const url = APP_CONFIG.mailUrl + `TemplateQuery/Templates`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getEmailTemplate: async (payload: ISingleAdminEmailTemplatePayload): Promise<any> => {
        const url = APP_CONFIG.mailUrl + `TemplateQuery/Template${processQuery(payload)}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getEmailConfigurations: async (): Promise<any> => {
        const url = APP_CONFIG.mailUrl + `ConfigurationQuery/Configurations`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    createEmailTemplate: async (payload: ICreateAdminEmailTemplate): Promise<any> => {
        const url = APP_CONFIG.mailUrl + `TemplateCommand/Create`;
        try {
            const response = await AXIOS_INSTANCE.post<ICreateAdminEmailTemplate>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err.response;
        }
    },

    updateEmailTemplate: async (payload: ICreateAdminEmailTemplate): Promise<any> => {
        const url = APP_CONFIG.mailUrl + `TemplateCommand/Update`;
        try {
            const response = await AXIOS_INSTANCE.post<ICreateAdminEmailTemplate>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err.response;
        }
    },

    deleteEmailTemplate: async (payload: IDeleteAdminEmailTemplate): Promise<any> => {
        const url = APP_CONFIG.mailUrl + `TemplateCommand/Delete`;
        try {
            const response = await AXIOS_INSTANCE.post<IDeleteAdminTemplate>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err.response;
        }
    },
};
