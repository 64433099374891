import { CoreTitle } from '@packages';
import './ReviewStep.scss';
import { Checkbox } from 'antd';

const roles = ['admin', 'appuser', 'anonymous'];

const ReviewStep = () => {
    return (
        <div className='review-wrapper'>
            <div className='review-custom-table'>
                <div className='table-row header'>
                    <div className='table-cell'>Username</div>
                    <div className='table-cell'>Require password reset</div>
                </div>
                <div className='table-row'>
                    <div className='table-cell-value'>abc@example.com</div>
                    <div className='table-cell-value'>Yes</div>
                </div>
            </div>

            <div className='review-summary'>
                <CoreTitle tag='h5' text='Permission Summary' />
                <div className='checkbox-section'>
                    {roles.map((item, index) => (
                        <div
                            key={index}
                            style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}
                        >
                            <Checkbox defaultChecked className='custom-checkbox'/>
                            <p className='checkbox-label'>{item}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ReviewStep;
