import { useState } from 'react';
import { useAppSelector } from '@store/hooks';
import { subscribe } from '@store/slices/subscribeSlice';
import { Col, Divider, Progress, Row } from 'antd';
import { CoreTitle } from '@packages';
import './OverviewSummary.scss';
import { useNavigate } from 'react-router-dom';
import { GET_DEFAULT_CURRENCY_CODE } from '@utils/CurrencyCodeOptions';
import CancelSubscriptionModal from './cancel-subscription-modal/CancelSubscriptionModal';

const OverviewSummary = () => {
    const navigate = useNavigate();
    const { subscription, configuration } = useAppSelector(subscribe);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);

    const payAsYouGoTotalPrice =
        subscription?.Usage?.Invoices * configuration?.PayAsYouGo?.InvoicePrice +
        subscription?.Usage?.Organizations * configuration?.PayAsYouGo?.OrganizationPrice +
        subscription?.Usage?.Entities * configuration?.PayAsYouGo?.EntityPrice +
        subscription?.Usage?.Devices * configuration?.PayAsYouGo?.DevicePrice +
        subscription?.Usage?.Users * configuration?.PayAsYouGo?.UserPrice;

    const usagePercentOfInvoice = Math.round(
        (subscription?.Usage?.Invoices * 100) / subscription?.Limit?.Invoices,
    );

    const usagePercentOfOrganizations = Math.round(
        (subscription?.Usage?.Organizations * 100) / subscription?.Limit?.Organizations,
    );

    const usagePercentOfEntities = Math.round(
        (subscription?.Usage?.Entities * 100) / subscription?.Limit?.Entities,
    );
    const usagePercentOfDevices = Math.round(
        (subscription?.Usage?.Devices * 100) / subscription?.Limit?.Devices,
    );

    const usagePercentOfUsers = Math.round(
        (subscription?.Usage?.Users * 100) / subscription?.Limit?.Users,
    );

    const CancelSubscription = () => {
        return (
            <>
                {!subscription.IsSetToCancel && !subscription.IsCancelled ? (
                    <div className='subscription-button-group'>
                        <span
                            onClick={() => {
                                setIsCancelModalOpen(true);
                            }}
                        >
                            Cancel subscription
                        </span>
                        <span
                            className='upgrade-btn'
                            onClick={() => {
                                navigate('/subscribe/pricing-plans');
                            }}
                        >
                            Upgrade
                        </span>
                    </div>
                ) : null}
            </>
        );
    };

    return (
        <div className='round-box-shadow overview-summary-wrapper'>
            {subscription?.IsPayAsYouGo ? (
                <>
                    <Row gutter={16} justify='space-between' className='margin-bottom-30'>
                        <Col span={8}>
                            <span className='plan-title'>Your Plan</span>

                            <CoreTitle
                                tag='h4'
                                text={
                                    subscription?.TierName === 'PAY-AS-YOU-GO'
                                        ? 'Pay as you Go'
                                        : subscription?.TierName
                                }
                            />
                        </Col>
                        <Col span={8}>
                            <span className='plan-title'>Est. Cost</span>
                            <div className='plan-price'>
                                <CoreTitle
                                    tag='h4'
                                    text={`${GET_DEFAULT_CURRENCY_CODE} ${payAsYouGoTotalPrice.toFixed(
                                        2,
                                    )}`}
                                />
                            </div>
                        </Col>
                        <Col span={8}>
                            <CancelSubscription />
                        </Col>
                    </Row>
                    <div className='pay-as-you-go-table'>
                        <div className='name-col'>
                            <div className='title'>Usage</div>
                            <div className='value'>Invoices</div>
                            <div className='value'>Organizations</div>
                            <div className='value'>Entities</div>
                            <div className='value'>Devices</div>
                            <div className='value'>Users</div>
                        </div>
                        <div className='unit-col'>
                            <div className='title'>Unit</div>
                            <div className='value'>{subscription?.Usage?.Invoices}</div>
                            <div className='value'>{subscription?.Usage?.Organizations}</div>
                            <div className='value'>{subscription?.Usage?.Entities}</div>
                            <div className='value'>{subscription?.Usage?.Devices}</div>
                            <div className='value'>{subscription?.Usage?.Users}</div>
                        </div>
                        <div className='price-col'>
                            <div className='title'>Unit Price</div>
                            <div className='value'>
                                {subscription?.Limit?.Currency || GET_DEFAULT_CURRENCY_CODE}{' '}
                                {configuration?.PayAsYouGo?.InvoicePrice.toFixed(4)}
                            </div>
                            <div className='value'>
                                {subscription?.Limit?.Currency || GET_DEFAULT_CURRENCY_CODE}{' '}
                                {configuration?.PayAsYouGo?.OrganizationPrice.toFixed(4)}
                            </div>
                            <div className='value'>
                                {subscription?.Limit?.Currency || GET_DEFAULT_CURRENCY_CODE}{' '}
                                {configuration?.PayAsYouGo?.EntityPrice.toFixed(4)}
                            </div>
                            <div className='value'>
                                {subscription?.Limit?.Currency || GET_DEFAULT_CURRENCY_CODE}{' '}
                                {configuration?.PayAsYouGo?.DevicePrice.toFixed(4)}
                            </div>
                            <div className='value'>
                                {subscription?.Limit?.Currency || GET_DEFAULT_CURRENCY_CODE}{' '}
                                {configuration?.PayAsYouGo?.UserPrice.toFixed(4)}
                            </div>
                        </div>
                        <div className='price-col'>
                            <div className='title'>Est. Cost</div>
                            <div className='value'>
                                {subscription?.Limit?.Currency || GET_DEFAULT_CURRENCY_CODE}{' '}
                                {(
                                    subscription?.Usage?.Invoices *
                                    configuration?.PayAsYouGo?.InvoicePrice
                                ).toFixed(2)}
                            </div>
                            <div className='value'>
                                {subscription?.Limit?.Currency || GET_DEFAULT_CURRENCY_CODE}{' '}
                                {(
                                    subscription?.Usage?.Organizations *
                                    configuration?.PayAsYouGo?.OrganizationPrice *
                                    subscription?.UsageHours
                                ).toFixed(2)}
                            </div>
                            <div className='value'>
                                {subscription?.Limit?.Currency || GET_DEFAULT_CURRENCY_CODE}{' '}
                                {(
                                    subscription?.Usage?.Entities *
                                    configuration?.PayAsYouGo?.EntityPrice *
                                    subscription?.UsageHours
                                ).toFixed(2)}
                            </div>
                            <div className='value'>
                                {subscription?.Limit?.Currency || GET_DEFAULT_CURRENCY_CODE}{' '}
                                {(
                                    subscription?.Usage?.Devices *
                                    configuration?.PayAsYouGo?.DevicePrice *
                                    subscription?.UsageHours
                                ).toFixed(2)}
                            </div>
                            <div className='value'>
                                {subscription?.Limit?.Currency || GET_DEFAULT_CURRENCY_CODE}{' '}
                                {(
                                    subscription?.Usage?.Users *
                                    configuration?.PayAsYouGo?.UserPrice *
                                    subscription?.UsageHours
                                ).toFixed(2)}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <Row gutter={16} className='padding-20'>
                        <Col span={8}>
                            <span className='plan-title'>Your Plan</span>
                            <CoreTitle tag='h4' text={subscription?.TierName} />
                        </Col>
                        <Col span={8}>
                            <span className='plan-title'>Payment</span>
                            <div className='plan-price'>
                                <CoreTitle
                                    tag='h4'
                                    text={
                                        subscription?.Limit?.Currency +
                                        ' ' +
                                        subscription?.Limit?.Price.toString()
                                    }
                                />{' '}
                                <span>per {subscription?.Recurring}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <CancelSubscription />
                        </Col>
                    </Row>
                    <Divider style={{ background: '#EAECF0', margin: '8px 0' }} />
                    <Row gutter={16} className='progress-wrapper'>
                        <Col span={8} className='margin-bottom-10'>
                            <span className='plan-title'>Invoice</span>
                            <Progress
                                percent={usagePercentOfInvoice}
                                strokeColor='#12B76A'
                                format={() => null}
                                size='default'
                                className='custom-progress'
                            />
                            <div className='bottom-label'>
                                <div className='value'>
                                    Usage: <span>{subscription?.Usage?.Invoices}</span>
                                </div>
                                <div className='value'>
                                    Limit: <span>{subscription?.Limit?.Invoices}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={8} className='margin-bottom-10'>
                            <span className='plan-title'>Organizations</span>
                            <Progress
                                percent={usagePercentOfOrganizations}
                                strokeColor='#F79009'
                                format={() => null}
                                size='default'
                                className='custom-progress'
                                success={{ strokeColor: '#285FF6' }}
                            />
                            <div className='bottom-label'>
                                <div className='value'>
                                    Usage: <span>{subscription?.Usage?.Organizations}</span>
                                </div>
                                <div className='value'>
                                    Limit: <span>{subscription?.Limit?.Organizations}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={8} className='margin-bottom-10'>
                            <span className='plan-title'>Entities</span>
                            <Progress
                                percent={usagePercentOfEntities}
                                strokeColor='#EABFFF'
                                format={() => null}
                                size='default'
                                className='custom-progress'
                            />
                            <div className='bottom-label'>
                                <div className='value'>
                                    Usage: <span>{subscription?.Usage?.Entities}</span>
                                </div>
                                <div className='value'>
                                    Limit: <span>{subscription?.Limit?.Entities}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={8} className='margin-bottom-10'>
                            <span className='plan-title'>Devices</span>
                            <Progress
                                percent={usagePercentOfDevices}
                                strokeColor='#3176FF'
                                format={() => null}
                                size='default'
                                className='custom-progress'
                            />
                            <div className='bottom-label'>
                                <div className='value'>
                                    Usage:<span>{subscription?.Usage?.Devices}</span>
                                </div>
                                <div className='value'>
                                    Limit: <span>{subscription?.Limit?.Devices}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={8} className='margin-bottom-10'>
                            <span className='plan-title'>Users</span>
                            <Progress
                                percent={usagePercentOfUsers}
                                strokeColor='#FFADAD'
                                format={() => null}
                                size='default'
                                className='custom-progress'
                            />
                            <div className='bottom-label'>
                                <div className='value'>
                                    Usage: <span>{subscription?.Usage?.Users}</span>
                                </div>
                                <div className='value'>
                                    Limit: <span>{subscription?.Limit?.Users}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </>
            )}

            <CancelSubscriptionModal
                isModalOpen={isCancelModalOpen}
                onConfirm={() => setIsCancelModalOpen(false)}
                onClose={() => setIsCancelModalOpen(false)}
            />
        </div>
    );
};
export default OverviewSummary;
