export const PDF_FORMATS = [
    { value: 'A0', label: '841 x 1189 mm (A0)' },
    { value: 'A1', label: '594 x 841 mm (A1)' },
    { value: 'A2', label: '420 x 594 mm (A2)' },
    { value: 'A3', label: '297 x 420 mm (A3)' },
    { value: 'A4', label: '210 x 297 mm (A4)' },
    { value: 'A5', label: '148 x 210 mm (A5)' },
    { value: 'A6', label: '105 x 148 mm (A6)' },
    { value: 'A7', label: '74 x 105 mm (A7)' },
    { value: 'A8', label: '52 x 74 mm (A8)' },
    { value: 'A9', label: '37 x 52 mm (A9)' },
    { value: 'A10', label: '26 x 37 mm (A10)' },
    { value: 'B0', label: '1000 x 1414 mm (B0)' },
    { value: 'B1', label: '707 x 1000 mm (B1)' },
    { value: 'B2', label: '500 x 707 mm (B2)' },
    { value: 'B3', label: '353 x 500 mm (B3)' },
    { value: 'B4', label: '250 x 353 mm (B4)' },
    { value: 'B5', label: '176 x 250 mm (B5)' },
    { value: 'B6', label: '125 x 176 mm (B6)' },
    { value: 'B7', label: '88 x 125 mm (B7)' },
    { value: 'B8', label: '62 x 88 mm (B8)' },
    { value: 'B9', label: '44 x 62 mm (B9)' },
    { value: 'B10', label: '31 x 44 mm (B10)' },
    { value: 'C0', label: '917 x 1297 mm (C0)' },
    { value: 'C1', label: '648 x 917 mm (C1)' },
    { value: 'C2', label: '458 x 648 mm (C2)' },
    { value: 'C3', label: '324 x 458 mm (C3)' },
    { value: 'C4', label: '229 x 324 mm (C4)' },
    { value: 'C5', label: '162 x 229 mm (C5)' },
    { value: 'C6', label: '114 x 162 mm (C6)' },
    { value: 'C7', label: '81 x 114 mm (C7)' },
    { value: 'C8', label: '57 x 81 mm (C8)' },
    { value: 'C9', label: '40 x 57 mm (C9)' },
    { value: 'C10', label: '28 x 40 mm (C10)' },
    { value: 'Letter', label: '8.5 x 11 inches (Letter)' },
    { value: 'Legal', label: '8.5 x 14 inches (Legal)' },
    { value: 'Tabloid', label: '11 x 17 inches (Tabloid)' },
    { value: 'Executive', label: '7.25 x 10.5 inches (Executive)' },
    { value: 'Ledger', label: '17 x 11 inches (Ledger)' },
    { value: 'Folio', label: '210 x 330 mm (Folio)' },
    { value: 'Half Letter', label: '5.5 x 8.5 inches (Half Letter)' },
    { value: 'Quarto', label: '215 x 275 mm (Quarto)' },
    { value: 'Junior Legal', label: '5 x 8 inches (Junior Legal)' },
];
