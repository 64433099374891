import { AxiosError } from 'axios';
import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { IAddBillingAddress, IUpdateBillingAddress } from '@models/billing.model';
import { processQuery } from '@utils/processQuery';

export const BillingAddressAPI = {
    getBillingAddresses: async (): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `AddressQuery/Addresses`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response;
        }
    },

    getBillingAddress: async (payload: { BillingAddressId: string }): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `AddressQuery/Address${processQuery(payload)}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response;
        }
    },

    addBillingAddress: async (payload: IAddBillingAddress): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `AddressCommand/Create`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response;
        }
    },

    deleteBillingAddress: async (payload: { BillingAddressId: string }): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `AddressCommand/Delete`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response;
        }
    },

    setAsDefault: async (payload: { BillingAddressId: string }): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `AddressCommand/SetAsDefault`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response;
        }
    },

    updateBillingAddress: async (payload: IUpdateBillingAddress): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `AddressCommand/Update`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response;
        }
    },
};
