import { RequestStatus } from '@models/async-status.enum';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { IRoleGetPayload, IRoleList } from '@models/role.model';
import { RoleAPI } from '@service/role.service';

interface IRoleState {
    roles: {
        status: RequestStatus;
        data: IRoleList;
        count: number | null;
        next?: string;
        nonce?: string;
    };
}

const initialState: IRoleState = {
    roles: {
        status: 'idle',
        data: [],
        count: null,
        next: undefined,
        nonce: undefined,
    },
};

export const getRoles = createAsyncThunk('getRoles', async (payload: IRoleGetPayload) => {
    try {
        return await RoleAPI.getRoles(payload);
    } catch (error: any) {
        throw error as any;
    }
});

export const RoleSlice = createSlice({
    name: 'RoleSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRoles.pending, (state) => {
                state.roles.status = 'loading';
            })
            .addCase(getRoles.rejected, (state) => {
                state.roles.status = 'failed';
                state.roles.data = [];
            })
            .addCase(getRoles.fulfilled, (state, action) => {
                state.roles.status = 'idle';
                state.roles.data = action.payload.Roles || [];
            });
    },
});

export const selectRolesInfo = (state: RootState) => state.role.roles;

export default RoleSlice.reducer;
