import { ITier } from '@models/billing.model';
import { CoreTitle } from '@packages';
import { payAsGoTiredData, payAsYouGoId } from '@utils/payAsGoTireData';
import { Divider } from 'antd';
import React, { useEffect } from 'react';
import './OrderSummary.scss';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getCredits } from '@store/slices/subscriptionOverviewSlice';

export interface IOrderSummaryProps {
    planId: string;
    recurringType: string;
    singleTier: ITier | null;
}

const OrderSummary = ({ planId, recurringType, singleTier }: IOrderSummaryProps) => {
    const { credits } = useAppSelector((state) => state.subscriptionOverview);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getCredits());
    }, []);
    const TotalCredit = credits.reduce((sum, item) => sum + item.TotalAmount, 0)
    const TotalUsed = credits.reduce((sum, item) => sum + item.Used, 0)

    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-US', {
        month: 'long', // Full month name
        day: 'numeric', // Day of the month
        year: 'numeric', // Full year
    });

    return (
        <div className='order-summary-component-wrapper'>
            <div className='summary-title-wrapper'>
                <CoreTitle tag='h4' text='Summary' />
                <p>Starts on {formattedDate}</p>
            </div>
            <div className='summary-details-wrapper'>
                <p className='normal-text'>{recurringType === 'month' ? 'Monthly' : 'Yearly'}</p>
                <p className='bold-text'>
                    {planId === payAsYouGoId
                        ? `${payAsGoTiredData?.YearlyPrice} ${payAsGoTiredData?.Currency}`
                        : `${recurringType === 'month'
                            ? singleTier?.MonthlyPrice
                            : singleTier?.YearlyPrice
                        } ${singleTier?.Currency}`}
                </p>
            </div>

            <Divider style={{ margin: '0', borderBlockStart: '1px solid #EAECF0' }} />

            <div className='summary-details-wrapper'>
                <p className='normal-text'>Total after using credit</p>
                <p className='bold-text'>
                    {planId === payAsYouGoId
                        ? `${payAsGoTiredData?.YearlyPrice} ${payAsGoTiredData?.Currency}`
                        : `${TotalCredit - TotalUsed < 0 ? 0 : TotalCredit - TotalUsed
                        } ${singleTier?.Currency}`}
                </p>
            </div>
        </div>
    );
};

export default OrderSummary;
