export const PAYMENT_STATUS_TYPE = {
    UNPAID: 'UNPAID',
    PAID: 'PAID',
    PARTIALLY_PAID: 'PARTIALLY_PAID',
    FAILED: 'FAILED',
    PAID_IN_ADVANCED: 'PAID_IN_ADVANCED',
} as const;

interface PaymentStatusOption {
    label: string;
    value: keyof typeof PAYMENT_STATUS_TYPE;
}

export const paymentStatusOptions: PaymentStatusOption[] = [
    { label: 'Unpaid', value: 'UNPAID' },
    { label: 'Paid', value: 'PAID' },
    { label: 'Partially Paid', value: 'PARTIALLY_PAID' },
    { label: 'Failed', value: 'FAILED' },
    { label: 'Paid in Advanced', value: 'PAID_IN_ADVANCED' },
];
