import { ISubscriptionInvoicePayload } from '@models/admin/subscription.model';
import { ISubscribePlanDetails, ISubscriptionInvoices } from '@models/subscription.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AdminSubscriptionAPI } from '@service/admin/subscription.service';

interface IAdminSubscriptionState {
    subscriptionInvoices: {
        data: [];
        isLoading: boolean;
    };
    subscriptionInvoice: {
        data: ISubscriptionInvoices | null;
        isLoading: boolean;
    };
    subscriptions: {
        data: ISubscribePlanDetails[];
        Count: number;
        Next: string;
        Nonce: string;
        isLoading: boolean;
    };
    subscription: {
        data: ISubscribePlanDetails | null;
        isLoading: boolean;
    };
}

const initialState: IAdminSubscriptionState = {
    subscriptionInvoices: {
        data: [],
        isLoading: false,
    },
    subscriptionInvoice: {
        data: null,
        isLoading: false,
    },
    subscriptions: {
        data: [],
        Count: 0,
        Next: '',
        Nonce: '',
        isLoading: false,
    },
    subscription: {
        data: null,
        isLoading: false,
    },
};

export const getSubscriptionInvoices = createAsyncThunk(
    'getSubscriptionInvoices',
    async ({
        SubscriptionId,
        payload,
    }: {
        SubscriptionId: string;
        payload: ISubscriptionInvoicePayload;
    }) => {
        try {
            const response = await AdminSubscriptionAPI.GetSubscriptionInvoices(
                SubscriptionId,
                payload,
            );
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const getAdminSubscriptions = createAsyncThunk(
    'getAdminSubscriptions',
    async (payload: any) => {
        try {
            const response = await AdminSubscriptionAPI.GetAdminSubscriptions(payload);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const getAdminSubscription = createAsyncThunk(
    'getAdminSubscription',
    async (payload: { SubscriptionId: string }) => {
        try {
            const response = await AdminSubscriptionAPI.GetAdminSubscription(payload);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const getSubscriptionInvoice = createAsyncThunk(
    'getSubscriptionInvoice',
    async (payload: { SubscriptionId: string; InvoiceId: string }) => {
        try {
            const response = await AdminSubscriptionAPI.GetSubscriptionInvoice(payload);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const AdminSubscriptionSlice = createSlice({
    name: 'AdminSubscriptionSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSubscriptionInvoices.pending, (state) => {
                state.subscriptionInvoices.isLoading = true;
            })
            .addCase(getSubscriptionInvoices.rejected, (state) => {
                state.subscriptionInvoices.isLoading = false;
            })
            .addCase(getSubscriptionInvoices.fulfilled, (state, action) => {
                state.subscriptionInvoices.isLoading = false;
                state.subscriptionInvoices.data = action.payload?.Invoices;
            })

            .addCase(getAdminSubscriptions.pending, (state) => {
                state.subscriptions.isLoading = true;
            })
            .addCase(getAdminSubscriptions.rejected, (state) => {
                state.subscriptions.isLoading = false;
            })
            .addCase(getAdminSubscriptions.fulfilled, (state, action) => {
                state.subscriptions.isLoading = false;
                state.subscriptions.Count = action.payload.Count;
                state.subscriptions.Next = action.payload.Next;
                state.subscriptions.Nonce = action.payload.Nonce;
                state.subscriptions.data = action.payload.Subscriptions;
            })

            .addCase(getAdminSubscription.pending, (state) => {
                state.subscriptions.isLoading = true;
            })
            .addCase(getAdminSubscription.rejected, (state) => {
                state.subscriptions.isLoading = false;
            })
            .addCase(getAdminSubscription.fulfilled, (state, action) => {
                state.subscriptions.isLoading = false;
                state.subscriptions.data = action.payload.Subscriptions;
            })

            .addCase(getSubscriptionInvoice.pending, (state) => {
                state.subscriptionInvoice.isLoading = true;
            })
            .addCase(getSubscriptionInvoice.rejected, (state) => {
                state.subscriptionInvoice.isLoading = false;
            })
            .addCase(getSubscriptionInvoice.fulfilled, (state, action) => {
                state.subscriptionInvoice.isLoading = false;
                state.subscriptionInvoice.data = action.payload;
            });
    },
});

export default AdminSubscriptionSlice.reducer;
