import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ActionAPI } from '@service/action.service';
import { EResourceType } from '@utils/resourceService';
import { IRole } from '@models/admin/user.model';

interface IUserState {
    email: string;
    autoActivate: boolean;
    password: string;
    roles: IRole[];
}

const initialState: IUserState = {
    email: '',
    autoActivate: false,
    password: '',
    roles: [],
};

export const getResourcePaths = createAsyncThunk(
    'resource-path',
    async (fromService: EResourceType) => {
        try {
            const response = await ActionAPI.getResource(fromService);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const UserSlice = createSlice({
    name: 'entity',
    initialState,
    reducers: {
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
        setAutoActivate: (state, action: PayloadAction<boolean>) => {
            state.autoActivate = action.payload;
        },
        addRole: (state, action: PayloadAction<IRole>) => {
            state.roles.push(action.payload);
        },
        resetUser: () => initialState,
    },
    extraReducers: () => {
        // builder
        //     .addCase(getResourcePaths.pending, (state) => {
        //         state.resourcePath.status = 'loading';
        //     })
        //     .addCase(getResourcePaths.rejected, (state) => {
        //         state.resourcePath.status = 'failed';
        //     })
        //     .addCase(getResourcePaths.fulfilled, (state, action) => {
        //         state.resourcePath.status = 'idle';
        //         state.resourcePath.data = action.payload;
        //     });
    },
});

export const { setEmail, setPassword, setAutoActivate, addRole, resetUser } = UserSlice.actions;

export default UserSlice.reducer;
