import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { ISubscriptionInvoicePayload, ISubscriptionResponse } from '@models/admin/subscription.model';
import { processQuery } from '@utils/processQuery';
import { AxiosError } from 'axios';

export const AdminSubscriptionAPI = {
    GetSubscriptionInvoices: async (
        SubscriptionId: string,
        payload: ISubscriptionInvoicePayload,
    ) => {
        const url =
            APP_CONFIG.eisUrl +
            `SubscriptionCommand/Invoices${processQuery({ SubscriptionId: SubscriptionId })}`;

        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetSubscriptionInvoice: async (payload: { SubscriptionId: string; InvoiceId: string }) => {
        const url = APP_CONFIG.eisUrl + `SubscriptionQuery/Invoice${processQuery(payload)}`;

        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetAdminSubscriptions: async (payload: any) => {
        const url = APP_CONFIG.eisUrl + `SubscriptionCommand/Subscriptions`;
        try {
            const response = await AXIOS_INSTANCE.post<ISubscriptionResponse>(url, payload);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    GetAdminSubscription: async (payload: { SubscriptionId: string }) => {
        const url = APP_CONFIG.eisUrl + `SubscriptionQuery/Subscription${processQuery(payload)}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
