import { FC, useState } from 'react';
import './CustomTable.scss';
import Table, { ColumnsType } from 'antd/es/table';
import CustomPagination from './CustomPagination';
import { Spin } from 'antd';
import filterAndPaginateData from '@utils/filterAndPaginateData';
import { DEFAULT_TABLE_SIZE } from '@config/constants';

interface ICustomTable {
    columns: ColumnsType<any>;
    isLoading: boolean;
    data: any[];
    onRowClick?: (rowData: any) => void;
    pageSize?: number;
    currentPage: number;
    uniqueId: string;
    hasNext: boolean;
    goNextPage?: () => void;
    className?: string;
}

const CustomTable: FC<ICustomTable> = ({
    columns,
    isLoading,
    data = [],
    uniqueId,
    onRowClick,
    hasNext,
    goNextPage,
    className,
    pageSize = DEFAULT_TABLE_SIZE,
}) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const totalElements = data?.length;
    const paginateData = filterAndPaginateData({ data, pageNumber: currentPage, pageSize });

    return (
        <Spin spinning={isLoading}>
            <div className={`ksa-table-wrapper ${className || ''}`}>
                <Table
                    columns={columns}
                    dataSource={paginateData}
                    rowKey={uniqueId}
                    rowClassName='cursor-pointer'
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: () => onRowClick && onRowClick(record), // click row
                        };
                    }}
                    pagination={false}
                />
                <CustomPagination
                    total={totalElements}
                    hasNext={hasNext}
                    goNextPage={() => {
                        goNextPage && goNextPage();
                        setCurrentPage((pre) => pre + 1);
                    }}
                    currentPage={currentPage}
                    goLocalPage={setCurrentPage}
                />
            </div>
        </Spin>
    );
};

export default CustomTable;
