import './PricingPlans.scss';
import { CoreBackButton, CoreTitle } from '@packages';
import { Collapse, CollapseProps, Tabs } from 'antd';
import PriceCard from '../../components/price-card/PriceCard';
import { useNavigate } from 'react-router-dom';
import { CalculatorOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import SubscribeLayout from '@components/main-layout/header-layout/HeaderLayout';
import { BalanceIcon } from '@assets/svg';
import { useState } from 'react';

const CollapseContent = [
    {
        key: '1',
        label: 'Is there a free trial available?',
        content: `Enjoy access to all features during your 14-day free trial, with some usage limitations to let you explore the platform risk-free. After your trial, upgrade to a subscription plan or our flexible "Pay as you go" model and unlock unlimited access based on your needs.`,
    },
    {
        key: '2',
        label: 'Do you need my credit card for the free trial?',
        content:
            'No, we don’t require a credit card for the 14-day free trial. Enjoy exploring our platform with no strings attached! After the trial ends, you’ll need to add a credit or debit card to subscribe to one of our tiers or opt for the "Pay as you go" model.',
    },
    {
        key: '3',
        label: "How do I cancel if I'm not super impressed?",
        content:
            'You can cancel your subscription anytime—no questions asked. Simply navigate to the subscription overview in your subscription area and select the "Cancel subscription" option. You\'re in complete control, and you can unsubscribe whenever you want.',
    },
];

const PricingPlans = () => {
    const navigate = useNavigate();
    const [tireName, updateTire] = useState<string>();

    const CollapseItems: CollapseProps['items'] = CollapseContent.map((item) => ({
        key: item.key,
        label: item.label,
        children: <p>{item.content}</p>,
    }));

    const tabItems = [
        {
            label: 'Monthly Billing',
            key: '1',
            children: <PriceCard type='month' updateCurrentTire={updateTire} />,
        },
        {
            label: <div>Annual </div>,
            key: '2',
            children: <PriceCard type='year' />,
        },
    ];

    return (
        <SubscribeLayout>
            <div className='pricing-plans-wrapper'>
                <div className='pricing-plans-column'>
                    <div className='column-one'>
                        <div>
                            <CoreBackButton
                                text={''}
                                onClick={() => {
                                    navigate('/subscribe');
                                }}
                            />
                        </div>
                        <div className='collapse-section'>
                            <p className='welcome-message'>Welcome to e-invocie solutions</p>
                            <CoreTitle tag='h1' text='Select your Plan' />
                            <div className='collapse-wrapper'>
                                <Collapse
                                    items={CollapseItems}
                                    defaultActiveKey={['1']}
                                    bordered={false}
                                    expandIcon={({ isActive }) =>
                                        isActive ? (
                                            <MinusCircleOutlined
                                                style={{ fontSize: '18px', color: '#A4A7AE' }}
                                            />
                                        ) : (
                                            <PlusCircleOutlined
                                                style={{ fontSize: '18px', color: '#A4A7AE' }}
                                            />
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='column-two'>
                        <div className='pricing-plans-card-section'>
                            <div className='pricing-plans-card-wrapper'>
                                <div className='pricing-plans-inner-wrapper'>
                                    <Tabs items={tabItems} centered={true} />
                                </div>
                                <div className='comparison-action'>
                                    {tireName === 'payasyougo' && (
                                        <div
                                            className='center-align'
                                            aria-hidden='true'
                                            onClick={() => {
                                                navigate('/subscribe/pricing-calculator');
                                            }}
                                        >
                                            Pricing Calculator
                                            <CalculatorOutlined
                                                style={{
                                                    marginLeft: '5px',
                                                }}
                                            />
                                        </div>
                                    )}
                                    <div
                                        aria-hidden='true'
                                        onClick={() => {
                                            navigate('/plan-comparison');
                                        }}
                                        className='center-align'
                                    >
                                        Show full comparison
                                        <span
                                            className='center-align'
                                            style={{
                                                marginLeft: '5px',
                                            }}
                                        >
                                            <BalanceIcon />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SubscribeLayout>
    );
};

export default PricingPlans;
