import { Radio } from 'antd';
import './PlanCard.scss';
import { CoreTitle } from '@packages';

interface IPlanCardProps {
    title: string;
    subtitle: string;
    price: string;
    period: string;
    value: string;
    radioValue: string;
}

const PlanCard = ({ title, subtitle, price, period, value, radioValue }: IPlanCardProps) => {
    return (
        <div
            className='plan-card-wrapper round-box-shadow'
            style={(radioValue && radioValue === value && { border: '1px solid #285FF6' }) || {}}
        >
            <Radio className='radio-button-wrapper' value={value}>
                <div className='radio-button-inner-wrapper'>
                    <div className='plan-card-header'>
                        <CoreTitle tag='h3' text={title} />
                        <p className='plan-card-price'>
                            {price}
                            {period}
                        </p>
                    </div>

                    <div className='plan-card-sub-title'>
                        <p>{subtitle}</p>
                    </div>
                </div>
            </Radio>
        </div>
    );
};

export default PlanCard;
