import React from 'react';
import './PricingCalculatorCard.scss';
import { PlusOutlined } from '@ant-design/icons'; // Assuming Ant Design for icons
import { CoreButton, CoreTitle } from '@packages';
import { selectPaymentCalculatorList } from '@store/slices/paymentCalculator';
import { useAppSelector } from '@store/hooks';
import EmptyCard from './EmptyCard';
import PricingCalculatorList from './PricingCalculatorList';
import { displayCurrency } from '@utils/displayCurrency';

export interface IPricingCalculatorEmptyCardPros {
    onPressEstimation: () => void;
}
export default function PricingCalculatorEmptyCard({
    onPressEstimation,
}: IPricingCalculatorEmptyCardPros) {
    const paymentCalculator = useAppSelector(selectPaymentCalculatorList);

    const isFoundPaymentCalculator = paymentCalculator?.length > 0 ? true : false;

    const totalPaymentAmount = paymentCalculator.reduce((pre, cur) => {
        return pre + cur.total;
    }, 0);

    return (
        <div className='pricing-empty-card'>
            <div>
                <div className='card-header'>
                    <CoreTitle className='pricing-empty-card-title' tag='h4' text='Cost details' />
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CoreButton
                        className='pricing-cal-empty-card-button'
                        type='secondary'
                        text='Add to estimate'
                        icon={<PlusOutlined />}
                        onClick={onPressEstimation}
                    />
                </div>

                {isFoundPaymentCalculator ? (
                    <PricingCalculatorList pricingData={paymentCalculator} />
                ) : (
                    <EmptyCard />
                )}
            </div>

            <div className='estimated-cost-footer'>
                <div className='estimated-text'>
                    <p className='estimated-label'>ESTIMATED COST</p>
                    <p className='estimated-value'>
                        {totalPaymentAmount
                            ? displayCurrency({ Amount: totalPaymentAmount, Code: 'SAR' })
                            : '--'}{' '}
                        / mo
                    </p>
                </div>
            </div>
        </div>
    );
}
