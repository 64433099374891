import React from "react";

// Component to decode and render escaped HTML
type EscapedHTMLRendererProps = {
  escapedHtml: string;
};

const EscapedHTMLRenderer: React.FC<EscapedHTMLRendererProps> = ({ escapedHtml }) => {
  // Function to decode escaped HTML entities
  const decodeHTML = (html: string): string => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  // Decode the input escaped HTML string
  const decodedHtml = decodeHTML(escapedHtml);

  return (
    <div>
      <br/>
      <div
        style={{ border: "1px solid #ccc", padding: "10px", marginTop: "10px" }}
        dangerouslySetInnerHTML={{ __html: decodedHtml }}
      />
    </div>
  );
};

export default EscapedHTMLRenderer;
