import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { IResourceGroupResponse, IResourceGroupsPayload } from '@models/admin/resource-group.model';
import { AxiosError } from 'axios';

export const ResourceGroupAPI = {
    getResourceGroups: async (payload: IResourceGroupsPayload): Promise<IResourceGroupResponse> => {
        const url = APP_CONFIG.identityUrl + `ResourceGroupCommand/ResourceGroups`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data as IResourceGroupResponse;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
