import { IBillingAddressResponse } from '@models/billing.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BillingAddressAPI } from '@service/billingAddress.service';
import { RootState } from '@store/store';

interface IBillingAddressState {
    isLoading: boolean;
    billingAddress: IBillingAddressResponse[];
    singleAddress: IBillingAddressResponse | null;
}

const initialState: IBillingAddressState = {
    isLoading: true,
    billingAddress: [],
    singleAddress: null,
};

export const getBillingAddresses = createAsyncThunk('getBillingAddresses', async () => {
    try {
        const response = await BillingAddressAPI.getBillingAddresses();
        return response?.Result || [];
    } catch (error: any) {
        throw error?.response?.Message || error?.message;
    }
});

export const getBillingAddress = createAsyncThunk(
    'getBillingAddress',
    async (payload: { BillingAddressId: string }) => {
        try {
            const response = await BillingAddressAPI.getBillingAddress(payload);
            return response?.Result || [];
        } catch (error: any) {
            throw error?.response?.Message || error?.message;
        }
    },
);

export const billingAddressSlice = createSlice({
    name: 'billingAddress',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getBillingAddresses.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getBillingAddresses.fulfilled, (state, action) => {
            state.isLoading = false;
            state.billingAddress = action.payload;
        });
        builder.addCase(getBillingAddresses.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getBillingAddress.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getBillingAddress.fulfilled, (state, action) => {
            state.isLoading = false;
            state.singleAddress = action.payload;
        });
        builder.addCase(getBillingAddress.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const billingAddressList = (state: RootState) => state.billingAddress.billingAddress;
export const singleBillingAddress = (state: RootState) => state.billingAddress.singleAddress;

export default billingAddressSlice.reducer;
