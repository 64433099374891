import React, { useEffect, useState } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import { Select } from 'antd';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getSubscriptionUsage } from '@store/slices/subscriptionOverviewSlice';
import { CoreTitle } from '@packages';

const filterOptions = [
    { value: 7, label: 'Last 7 days' },
    { value: 15, label: 'Last 15 Days' },
    { value: 30, label: 'Last 30 Days' },
];

const StackedBarChart = () => {
    const dispatch = useAppDispatch();
    const [selectedDays, setSelectedDays] = useState<number>(7);
    const { subscriptionUsage } = useAppSelector((state) => state.subscriptionOverview);

    useEffect(() => {
        dispatch(getSubscriptionUsage(7));
    }, []);

    const handleChange = (value: number) => {
        dispatch(getSubscriptionUsage(value));
        setSelectedDays(value);
    };

    return (
        <div className='round-box-shadow pd-20'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CoreTitle tag='h6' text='Usage' />
                <Select
                    className='general-select'
                    onChange={handleChange}
                    value={selectedDays}
                    options={filterOptions}
                />
            </div>
            <ResponsiveContainer width='100%' height={400}>
                <BarChart
                    width={800}
                    height={300}
                    data={subscriptionUsage}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='name' />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey='invoices' stackId='a' fill='#12B76A' />
                    <Bar dataKey='organizations' stackId='a' fill='#F79009' />
                    <Bar dataKey='entities' stackId='a' fill='#EABFFF' />
                    <Bar dataKey='devices' stackId='a' fill='#3176FF' />
                    <Bar dataKey='users' stackId='a' fill='#FFADAD' />
                    <Bar dataKey='others' stackId='a' fill='#000' />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default StackedBarChart;
