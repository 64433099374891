import _ from 'lodash';

interface IFilterAndPaginateData {
    data: any[];
    pageNumber: number;
    pageSize: number;
}

export default function filterAndPaginateData({
    data,
    pageNumber,
    pageSize,
}: IFilterAndPaginateData) {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    // Use lodash's chain function to apply multiple operations
    return _.chain(data)
        .slice(startIndex, endIndex) // Get the desired page of data
        .value(); // Extract the final result from the chain
}
