import { useTranslation } from 'react-i18next';
import './EmailTemplateDetails.scss';
import { CoreBackButton, CoreButton, CoreList, CoreTitle } from '@packages';
import { useAppDispatch } from '@store/hooks';
import { useEffect, useState } from 'react';
import { getEmailTemplateInformation } from '@utils/helper';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import EscapedHTMLRenderer from './PreviewEmail';
import { Modal } from 'antd';
import { getEmailTemplate } from '@store/slices/admin/adminTemplateSlice';

type CoreList = { key: string; value: string }[];

const EmailTemplateDetails = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const queryParams = new URLSearchParams(location.search);
    const mailPurpose = queryParams.get('MailPurpose');
    const language = queryParams.get('Language');
    const [templateInfoData, setTemplateInfo] = useState<CoreList>([]);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    const { emailTemplate } = useSelector((state: RootState) => state.adminTemplate);

    useEffect(() => {
        if (mailPurpose && language) {
            dispatch(
                getEmailTemplate({ MailPurpose: mailPurpose, Language: language }),
            );
        }
    },[mailPurpose, language, dispatch]);

    useEffect(() => {
        if (emailTemplate?.data) {
            const templateInfo = getEmailTemplateInformation(emailTemplate?.data, t);
            setTemplateInfo(templateInfo);
        }
    }, [emailTemplate?.data, t]);

    return (
        <div className='template-details-wrapper'>
            <CoreBackButton text='' />
            <div className='template-details-inner-wrapper'>
                <div className='template-details-header'>
                    <CoreTitle tag='h4' text='Template Details' />
                    <CoreButton
                        type='primary'
                        text='Preview Email'
                        size='small'
                        onClick={() => setModalOpen(true)}
                    />
                </div>
            </div>

            <div className='template-details-content'>
                <CoreList listData={templateInfoData} />
            </div>
            {isModalOpen && (
                <Modal
                className='email-template-modal-wrapper'
                width={'50vw'}
                open={isModalOpen}
                onCancel={() => setModalOpen(false)}
                footer={[<CoreButton key={1} text={t('CLOSE')} type='primary' onClick={() => setModalOpen(false)} />]}
              >
                <EscapedHTMLRenderer escapedHtml={emailTemplate?.data?.EscapedTemplateBody || ''} />
                </Modal>
            )}
        </div>
    );
};
export default EmailTemplateDetails;

