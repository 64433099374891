import { PlusOutlined } from '@ant-design/icons';
import { CoreButton, CoreTitle } from '@packages';
import BillingFormModal from '../../billing/billing-form-modal/BillingFormModal';
import './BillingAddressSection.scss';
import { useEffect, useState } from 'react';
import BillingCardView from '../billing-card-view/BillingCardView';
import { Radio, RadioChangeEvent } from 'antd';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { billingAddressList, getBillingAddresses } from '@store/slices/billingAddressSlice';

const BillingAddressSection = () => {
    const [isAddModalVisible, setAddIsModalVisible] = useState<boolean>(false);
    const [cardId, setCardId] = useState<string>();
    const dispatch = useAppDispatch();

    const billingAddress = useAppSelector(billingAddressList);

    useEffect(() => {
        dispatch(getBillingAddresses());
    }, []);

    const onRadioChange = (e: RadioChangeEvent) => {
        setCardId(e.target.value);
    };

    return (
        <div className='billing-address-info'>
            <div className='summary-title-wrapper'>
                <CoreTitle tag='h4' text='Billing Address' />
                <CoreButton
                    text='Add New Address'
                    antType='link'
                    size='small'
                    icon={<PlusOutlined />}
                    onClick={() => setAddIsModalVisible(true)}
                />
                <BillingFormModal
                    isVisible={isAddModalVisible}
                    onClose={() => setAddIsModalVisible(false)}
                    onConfirm={() => setAddIsModalVisible(false)}
                />
            </div>

            <div className='billing-address-actions'>
                <Radio.Group onChange={onRadioChange}>
                    {billingAddress.map((address) => (
                        <BillingCardView
                            key={address?.ItemId}
                            selectedCardId={cardId}
                            addressName={address?.AddressTitle}
                            addressDetails={address?.Address}
                            addressId={address?.ItemId}
                        />
                    ))}
                </Radio.Group>
            </div>
        </div>
    );
};

export default BillingAddressSection;
