import { CoreButton, CoreTitle } from '@packages';
import { Modal } from 'antd';
import './BillingActionModal.scss';
import { HomeFilled } from '@ant-design/icons';

interface IBillingActionModalProps {
    title: string;
    subTitle: string;
    isVisible: boolean;
    onClose: () => void;
    onConfirm: () => void;
    actionType: 'Delete' | 'Update';
    addressName: string;
    addressDetails: string;
    isLoading: boolean;
}

const BillingActionModal = ({
    title,
    subTitle,
    isVisible,
    onClose,
    onConfirm,
    actionType,
    addressName,
    addressDetails,
    isLoading,
}: IBillingActionModalProps) => {
    return (
        <Modal
            className='billing-action-modal-wrapper'
            open={isVisible}
            onCancel={onClose}
            closable={false}
            footer={[]}
        >
            <div className='billing-action-modal-header'>
                <CoreTitle tag='h4' text={title} />
                <p>{subTitle}</p>
            </div>
            <div className='card-info'>
                <p className='info-card-title'>
                    <HomeFilled />
                    {addressName}
                </p>
                <p className='info-card-sub-title'>{addressDetails}</p>
            </div>

            <div className='modal-submition-actions'>
                <CoreButton size='middle' type='basic' text='Cancel' onClick={onClose} />
                <CoreButton
                    size='middle'
                    type={actionType === 'Delete' ? 'danger' : 'primary'}
                    text={actionType}
                    loading={isLoading}
                    onClick={onConfirm}
                />
            </div>
        </Modal>
    );
};

export default BillingActionModal;
