import { FileTextOutlined } from '@ant-design/icons';
import { getItem, MenuItem } from './menu-items';
import { Link } from 'react-router-dom';
import { TFunction } from 'i18next';
export const AdminMenuItems = (t: TFunction<'translation', undefined>): MenuItem[] =>
    [
        getItem(
            <div className='full-width-height'>IAM</div>,
            'user-management',
            <FileTextOutlined />,
            [
                getItem(
                    <Link to='/admin/iam/users' className='justify-between'>
                        <div>User Managements</div>
                    </Link>,
                    'users',
                ),
            ],
        ),
        getItem(
            <div className='full-width-height'>Subscription Management</div>,
            'subscription-management',
            <FileTextOutlined />,
            [
                getItem(
                    <Link to='/admin/subscription' className='justify-between'>
                        <div>Subscriptions</div>
                    </Link>,
                    'subscriptions',
                ),
                getItem(
                    <Link to='/admin/subscription/tiers' className='justify-between'>
                        <div>Tiers</div>
                    </Link>,
                    'tiers',
                ),
                getItem(
                    <Link to='/admin/subscription/coupons' className='justify-between'>
                        <div>Coupons</div>
                    </Link>,
                    'coupons',
                ),
                getItem(
                    <Link to='/admin/subscription/configuration' className='justify-between'>
                        <div>Configuration</div>
                    </Link>,
                    'configuration',
                ),
            ],
        ),
        getItem(
            <div className='full-width-height'>Templates</div>,
            'templates',
            <FileTextOutlined />,
            [
                getItem(
                    <Link to='/admin/template/email' className='justify-between'>
                        <div>Email</div>
                    </Link>,
                    'email',
                ),
                getItem(
                    <Link to='/admin/template/pdf' className='justify-between'>
                        <div>PDF</div>
                    </Link>,
                    'pdf',
                ),
            ],
        ),
        getItem(
            <Link to='/admin/resource-group'>{t('Resource Groups')}</Link>,
            'resource-group',
            <FileTextOutlined />,
        ),
    ].filter(Boolean);
