import { configuration, getSubscriptionConfiguration } from '@store/slices/subscribeSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useEffect } from 'react';
import { CoreButton, CoreTitle } from '@packages';
import './SubscriptionConfiguration.scss';
import { DEFAULT_EMPTY_VALUE } from '@config/constants';

const SubscriptionConfiguration = () => {
    const dispatch = useAppDispatch();
    const configurationData = useAppSelector(configuration);

    useEffect(() => {
        dispatch(getSubscriptionConfiguration());
    }, [dispatch]);

    return (
        <div className='configuration-wrapper'>
            <div className='configuration-inner-wrapper'>
                <div className='configuration-actions'>
                    <CoreButton
                        text={configurationData ? 'Update' : 'Create'}
                        linkTo='/admin/subscription/configuration/update'
                        type='primary'
                    />
                </div>
                <div className='configuration-inner-wrapper'>
                    <CoreTitle text='Trail SubscriptionConfiguration' tag='h4' />
                    <div className='configuration-block-wrapper round-box-shadow'>
                        <div className='configuration-block '>
                            <div>
                                <CoreTitle text='Organizations' tag='h6' />
                                <p className='running-total-amount'>
                                    {configurationData?.Trial?.Organizations || DEFAULT_EMPTY_VALUE}
                                </p>
                            </div>
                            <div>
                                <CoreTitle text='Entities' tag='h6' />
                                <p className='running-total-amount'>
                                    {configurationData?.Trial?.Entities || DEFAULT_EMPTY_VALUE}
                                </p>
                            </div>
                            <div>
                                <CoreTitle text='Devices' tag='h6' />
                                <p className='running-total-amount'>
                                    {configurationData?.Trial?.Devices || DEFAULT_EMPTY_VALUE}
                                </p>
                            </div>
                        </div>

                        <div className='configuration-divider' />

                        <div className='configuration-block'>
                            <div>
                                <CoreTitle text='Invoice' tag='h6' />
                                <p className='running-total-amount'>
                                    {configurationData?.Trial?.Invoice || DEFAULT_EMPTY_VALUE}
                                </p>
                            </div>
                            <div>
                                <CoreTitle text='Users' tag='h6' />
                                <p className='running-total-amount'>
                                    {configurationData?.Trial?.Users || DEFAULT_EMPTY_VALUE}
                                </p>
                            </div>
                        </div>

                        <div className='configuration-divider' />

                        <div className='configuration-block'>
                            <div>
                                <CoreTitle text='Phase' tag='h6' />
                                <p className='running-total-amount'>
                                    {configurationData?.Trial?.Phase || DEFAULT_EMPTY_VALUE}
                                </p>
                            </div>
                            <div>
                                <CoreTitle text='SDKValidation' tag='h6' />
                                <p className='running-total-amount'>
                                    {configurationData?.Trial?.SDKValidation !== undefined
                                        ? configurationData.Trial.SDKValidation.toString().toUpperCase()
                                        : DEFAULT_EMPTY_VALUE}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='configuration-inner-wrapper'>
                    <CoreTitle text='Pay as you go SubscriptionConfiguration' tag='h4' />
                    <div className='configuration-block-wrapper round-box-shadow'>
                        <div className='configuration-block '>
                            <div>
                                <CoreTitle text='Organization Price' tag='h6' />
                                <p className='running-total-amount'>
                                    {configurationData?.PayAsYouGo?.OrganizationPrice ||
                                        DEFAULT_EMPTY_VALUE}
                                </p>
                            </div>
                            <div>
                                <CoreTitle text='Entity Price' tag='h6' />
                                <p className='running-total-amount'>
                                    {configurationData?.PayAsYouGo?.EntityPrice ||
                                        DEFAULT_EMPTY_VALUE}
                                </p>
                            </div>
                        </div>

                        <div className='configuration-divider' />

                        <div className='configuration-block'>
                            <div>
                                <CoreTitle text='Device Price' tag='h6' />
                                <p className='running-total-amount'>
                                    {configurationData?.PayAsYouGo?.DevicePrice ||
                                        DEFAULT_EMPTY_VALUE}
                                </p>
                            </div>
                            <div>
                                <CoreTitle text='Invoice Price' tag='h6' />
                                <p className='running-total-amount'>
                                    {configurationData?.PayAsYouGo?.InvoicePrice ||
                                        DEFAULT_EMPTY_VALUE}
                                </p>
                            </div>
                        </div>

                        <div className='configuration-divider' />

                        <div className='configuration-block'>
                            <div>
                                <CoreTitle text='User Price' tag='h6' />
                                <p className='running-total-amount'>
                                    {configurationData?.PayAsYouGo?.UserPrice ||
                                        DEFAULT_EMPTY_VALUE}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='configuration-inner-wrapper'>
                    <CoreTitle text='AddOns SubscriptionConfiguration' tag='h4' />
                    <div className='configuration-block-wrapper round-box-shadow'>
                        <div className='configuration-block '>
                            <div>
                                <CoreTitle text='Organization Price' tag='h6' />
                                <p className='running-total-amount'>
                                    {configurationData?.AddOns?.OrganizationPrice ||
                                        DEFAULT_EMPTY_VALUE}
                                </p>
                            </div>
                            <div>
                                <CoreTitle text='Entity Price' tag='h6' />
                                <p className='running-total-amount'>
                                    {configurationData?.AddOns?.EntityPrice || DEFAULT_EMPTY_VALUE}
                                </p>
                            </div>
                        </div>

                        <div className='configuration-divider' />

                        <div className='configuration-block'>
                            <div>
                                <CoreTitle text='Device Price' tag='h6' />
                                <p className='running-total-amount'>
                                    {configurationData?.AddOns?.DevicePrice || DEFAULT_EMPTY_VALUE}
                                </p>
                            </div>
                            <div>
                                <CoreTitle text='Invoice Price' tag='h6' />
                                <p className='running-total-amount'>
                                    {configurationData?.AddOns?.InvoicePrice || DEFAULT_EMPTY_VALUE}
                                </p>
                            </div>
                        </div>

                        <div className='configuration-divider' />

                        <div className='configuration-block'>
                            <div>
                                <CoreTitle text='User Price' tag='h6' />
                                <p className='running-total-amount'>
                                    {configurationData?.AddOns?.UserPrice || DEFAULT_EMPTY_VALUE}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionConfiguration;
