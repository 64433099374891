import { useParams } from 'react-router-dom';
import './UserDetails.scss';
import { useAppDispatch } from '@store/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { getUserByMail } from '@store/slices/userSlice';
import { useEffect } from 'react';

const UserDetails = () => {
    const { email } = useParams();
    const dispatch = useAppDispatch();
    const { userDetailsByEmail } = useSelector((state: RootState) => state.user);

    useEffect(() => {
        dispatch(getUserByMail({ Email: email || '' }));
    }, [dispatch]);

    return <div className='user-details-wrapper'>{userDetailsByEmail?.Email}</div>;
};

export default UserDetails;
