import { Modal } from 'antd';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddPaymentForm from './AddPaymentForm';
import { APP_CONFIG } from '@config/constants';

interface IAddPaymentProps {
    isOpenModal: boolean;
    onCancelModal: () => void;
}
const stripePromise = loadStripe(APP_CONFIG.stripeApiKey || '');

export default function AddPayment({ isOpenModal, onCancelModal }: IAddPaymentProps) {
    return (
        <Modal
            width={480}
            open={isOpenModal}
            closable={true}
            title={
                <div className='add-payment-header'>
                    <p className='title'>Add Payment Method</p>
                    <p className='sub-title'>Add your card details</p>
                </div>
            }
            footer={[]}
            className='add-payment-modal'
        >
            <Elements stripe={stripePromise}>
                <AddPaymentForm onCancelModal={onCancelModal} />
            </Elements>
        </Modal>
    );
}
