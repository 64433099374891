import { IPayAsYouGo } from '@models/subscription.model';
import { PaymentCalculatorType } from '@store/slices/paymentCalculator';

export function getPayAsGoAmountByItemType(type: PaymentCalculatorType, payAsGo: IPayAsYouGo) {
    if (type === 'invoices') {
        return payAsGo?.InvoicePrice || 0;
    }
    if (type === 'devices') {
        return payAsGo?.DevicePrice || 0;
    }
    if (type === 'entities') {
        return payAsGo?.EntityPrice || 0;
    }
    if (type === 'organizations') {
        return payAsGo?.OrganizationPrice || 0;
    }

    if (type === 'users') {
        return payAsGo?.UserPrice || 0;
    }

    return 0;
}
