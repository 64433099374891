import { Col, Row, Tag } from 'antd';
import { CoreButton, CoreSearchInput } from '@packages';
import { useNavigate } from 'react-router-dom';
import './UserList.scss';
import { useState } from 'react';
import { IUserFilterPayload } from '@models/user.model';
import { FilterDropDown } from '@components';
import CustomUserFIlter, {
    ICustomUserFilterData,
} from '../../components/custom-user-filter/CustomUserFIlter';
import { identity, isEmpty, omit, pickBy } from 'lodash';
import { splitCamelCase } from '@utils/splitCamelCase';
import { useTranslation } from 'react-i18next';
import UserListTable from '../../components/user-list-table/UserListTable';

const initialFilterData: IUserFilterPayload = {
    Email: '',
    IsActive: null,
    IsVerified: null,
    Name: '',
    PhoneNumber: '',
    SubscriptionId: '',
};

function UserList() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [filterData, setFilterData] = useState<IUserFilterPayload>(initialFilterData);

    const clearAllFilters = () => {
        setFilterData(initialFilterData);
    };

    const clearFilter = (item: string) => {
        if (!filterData) return;
        const removeFilter = omit(filterData, item) as any;
        setFilterData(removeFilter);
    };

    const handleFilterSubmit = (data: ICustomUserFilterData) => {
        setFilterData(data);
    };

    const filterNullFilterData = pickBy(filterData, identity);

    return (
        <>
            <div className='user-filter-header margin-30'>
                <div className='filter-button-group'>
                    <CoreSearchInput placeholder='Search' />
                    <FilterDropDown
                        content={
                            <CustomUserFIlter
                                handleFilterSubmit={handleFilterSubmit}
                                defaultValues={filterData}
                            />
                        }
                    />
                    <div>
                        <CoreButton
                            type='primary'
                            text='Create User'
                            isFullWidth={true}
                            onClick={() => navigate(`/admin/iam/user/create`)}
                        />
                    </div>
                </div>

                {!isEmpty(filterNullFilterData) && (
                    <Row className='filter-wrapper' justify='space-between'>
                        <Col span={20}>
                            {Object.entries(filterNullFilterData).map(([key, value]) => (
                                <Tag
                                    key={key}
                                    className='margin-bottom-10'
                                    closable
                                    onClose={() => clearFilter(key)}
                                >
                                    <span>{t(splitCamelCase(key))}</span>:{' '}
                                    <span className='filter-value'>{value}</span>
                                </Tag>
                            ))}
                        </Col>
                        <Col span={4}>
                            <Row justify='end'>
                                <div>
                                    <CoreButton
                                        type='basic'
                                        isFullWidth={false}
                                        text={t('CLEAR_ALL')}
                                        onClick={clearAllFilters}
                                    />
                                </div>
                            </Row>
                        </Col>
                    </Row>
                )}
            </div>
            <div>
                <UserListTable filterData={filterData} />
            </div>
        </>
    );
}

export default UserList;
