import { CoreButton } from '@packages';
import BillingCard from '../billing-card/BillingCard';
import BillingFormModal from '../billing-form-modal/BillingFormModal';
import './BillingList.scss';
import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { billingAddressList, getBillingAddresses } from '@store/slices/billingAddressSlice';
import { subscribe } from '@store/slices/subscribeSlice';

const BillingList = () => {
    const [isAddModalVisible, setAddIsModalVisible] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const billingAddress = useAppSelector(billingAddressList);
    const { subscription } = useAppSelector(subscribe);

    useEffect(() => {
        dispatch(getBillingAddresses());
    }, []);

    return (
        <>
            <div className='billing-list-wrapper'>
                {billingAddress?.map((address) => (
                    <BillingCard
                        key={address?.ItemId}
                        addressName={address?.AddressTitle}
                        addressDetails={address?.Address}
                        addressId={address?.ItemId}
                        isDefault={subscription?.DefaultBillingAddressId === address?.ItemId}
                    />
                ))}
            </div>

            <div className='margin-top-16'>
                <CoreButton
                    size='small'
                    antType='link'
                    text='Add Address'
                    icon={<PlusOutlined />}
                    onClick={() => setAddIsModalVisible(true)}
                />
            </div>

            {isAddModalVisible && (
                <BillingFormModal
                    isVisible={isAddModalVisible}
                    onClose={() => setAddIsModalVisible(false)}
                    onConfirm={() => setAddIsModalVisible(false)}
                />
            )}
        </>
    );
};

export default BillingList;
