import { Spin, Tabs, TabsProps, Timeline } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { UTCToShortDate } from '@utils/date';
import { useTranslation } from 'react-i18next';
import './WhatsNew.scss';

const WhatsNew = () => {
    const { t } = useTranslation();
    const isLoading = false;

    const latestUpdates = [
        {
            key: '1',
            time: '2024-10-15T00:00:00Z',
            title: 'New Format in Currency',
            description:
                "We've added a new format to the currency where an apostrophe is used as a thousands separator and the dot is used as a decimal separator. This format is commonly used in Switzerland.",
        },
        {
            key: '2',
            time: '2024-10-04T00:00:00Z',
            title: 'Bulk Export Invoices as ZIP',
            description:
                'You can now bulk export up to 100 invoices as PDFs within a single ZIP file. If you use custom templates, a maximum of 50 invoices can be exported per ZIP.',
        },
        {
            key: '3',
            time: '2024-08-14T00:00:00Z',
            title: 'Improved Search Functionality',
            description:
                'The search feature has been improved to support filtering by invoice status, date, and client name, making it easier to find the data you need.',
        },
    ];

    const upcoming = [
        {
            key: '1',
            time: '2025-02-20T00:00:00Z',
            title: 'Enhanced Analytics Dashboard',
            description:
                "We're introducing a comprehensive analytics dashboard to provide deeper insights into your invoice and payment trends.",
            special: 'Featured',
        },
        {
            key: '2',
            time: '2025-03-10T00:00:00Z',
            title: 'Customizable Email Templates',
            description:
                'Create and personalize email templates for sending invoices to match your brand identity. Include logos, custom footers, and more.',
        },
        {
            key: '3',
            time: '2025-04-25T00:00:00Z',
            title: 'Multi-Currency Invoicing',
            description:
                'Generate invoices in multiple currencies with real-time exchange rate support. Perfect for international clients and businesses.',
        },
        {
            key: '4',
            time: '2025-05-15T00:00:00Z',
            title: 'Integration with Payment Gateways',
            description:
                'Seamless integration with popular payment gateways like PayPal, Stripe, and Square to make receiving payments easier than ever.',
        },
    ];

    const formatTimelineItems = (data: any[]) =>
        data.map((item) => ({
            children: (
                <div>
                    <p>{UTCToShortDate(item.time)}</p>
                    <p>
                        <strong>{item.title}</strong>
                        {item.special && <p className='special'>{item.special}</p>}
                    </p>
                    <p>{item.description}</p>
                </div>
            ),
        }));

    const tabItems: TabsProps['items'] = [
        {
            key: '1',
            label: t('LATEST_UPDATES'),
            children: (
                <Timeline className='whats-new-list-wrapper' items={formatTimelineItems(latestUpdates)} />
            ),
        },
        {
            key: '2',
            label: t('UPCOMING'),
            children: <Timeline className='whats-new-list-wrapper' items={formatTimelineItems(upcoming)} />,
        },
    ];

    return (
        <Spin spinning={isLoading}>
            <div className='container'>
                <div className='left-tab-wrapper'>
                    <Tabs defaultActiveKey='1' items={tabItems} moreIcon={<ArrowRightOutlined />} />
                </div>
            </div>
        </Spin>
    );
};

export default WhatsNew;
