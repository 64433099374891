import './PlanComparison.scss';
import { Tabs } from 'antd';
import ComparisonTab from '../../components/comparison-tab/ComparisonTab';
import HeaderLayout from '@components/main-layout/header-layout/HeaderLayout';

const getTabItems = [
    {
        label: 'Monthly Billing',
        key: '1',
        children: <ComparisonTab type='month' />,
    },
    {
        label: (
            <div>
                Annual <span className='saving-text'>(Save 20%)</span>
            </div>
        ),
        key: '2',
        children: <ComparisonTab type='year' />,
    },
];

const PlanComparison = () => {
    return (
        <HeaderLayout>
            <div className='plan-comparison-wrapper'>
                <Tabs destroyInactiveTabPane={true} items={getTabItems} centered={true} />
            </div>
        </HeaderLayout>
    );
};

export default PlanComparison;
