import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ICredit,
    ISubscribeUsage,
    ISubscriptionInvoices,
    ISubscriptionInvoicesPayload,
    tabType,
} from '@models/subscription.model';
import { SubscribeAPI } from '@service/subscription.service';

interface ISubscriptionOverviewState {
    isLoading: boolean;
    credits: ICredit[];
    subscriptionInvoices: ISubscriptionInvoices[];
    subscriptionInvoice: ISubscriptionInvoices | null;
    subscriptionUsage: ISubscribeUsage[];
    activeTabKey: tabType;
}

const initialState: ISubscriptionOverviewState = {
    isLoading: false,
    credits: [],
    subscriptionInvoices: [],
    subscriptionInvoice: null,
    subscriptionUsage: [],
    activeTabKey: 'overview',
};

export const getCredits = createAsyncThunk('getCredits', async () => {
    try {
        const response = await SubscribeAPI.GetCredits();
        return response;
    } catch (error: any) {
        throw error as any;
    }
});
export const getSubscriptionInvoices = createAsyncThunk(
    'getSubscriptionInvoices',
    async (payload: ISubscriptionInvoicesPayload) => {
        try {
            const response = await SubscribeAPI.GetSubscriptionInvoices(payload);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);
export const getSubscriptionInvoice = createAsyncThunk(
    'getSubscriptionInvoice',
    async (invoiceId: string) => {
        try {
            const response = await SubscribeAPI.GetSubscriptionInvoice(invoiceId);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);

export const getSubscriptionUsage = createAsyncThunk(
    'getSubscriptionUsage',
    async (days: number) => {
        try {
            const response = await SubscribeAPI.GetSubscriptionUsage(days);
            return response;
        } catch (error: any) {
            throw error as any;
        }
    },
);
export const subscriptionOverview = createSlice({
    name: 'subscriptionOverview',
    initialState,
    reducers: {
        setActiveTabkey(state, action: PayloadAction<tabType>) {
            state.activeTabKey = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCredits.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCredits.fulfilled, (state, action) => {
                state.isLoading = false;
                state.credits = action.payload;
            })
            .addCase(getCredits.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getSubscriptionInvoices.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSubscriptionInvoices.fulfilled, (state, action) => {
                state.isLoading = false;
                state.subscriptionInvoices = action.payload;
            })
            .addCase(getSubscriptionInvoices.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getSubscriptionInvoice.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSubscriptionInvoice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.subscriptionInvoice = action.payload;
            })
            .addCase(getSubscriptionInvoice.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getSubscriptionUsage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSubscriptionUsage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.subscriptionUsage = action.payload;
            })
            .addCase(getSubscriptionUsage.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const { setActiveTabkey } = subscriptionOverview.actions;
export default subscriptionOverview.reducer;
