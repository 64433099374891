import { useEffect, useState } from 'react';
import { Col, Row, Tabs } from 'antd';
import { CoreBackButton, CoreTitle, CoreStatus, CoreButton } from '@packages';
import './OrganizationDetails.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '@store/hooks';
import { getOrganization } from '@store/slices/organizationSlice';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { UtcToLocalDate } from '@utils/date';
import { useTranslation } from 'react-i18next';
import { OrganizationDetail, OrganizationDevice, OrganizationEntity } from '../../index';
import { ConfirmationModal } from '@components';

type tabsType = 'organization' | 'device' | 'entity';

const OrganizationDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { organization } = useSelector((state: RootState) => state.organization);
    const [invoicePhase] = useState(organization?.InvoicePhase);

    const { organizationId = '' } = useParams();
    const dispatch = useAppDispatch();

    const { state }: { state?: { currentTab: tabsType } } = useLocation();
    const [activeTabsKey, setActiveTabsKey] = useState<tabsType>(
        state?.currentTab || 'organization',
    );

    const [isUpgradePhaseModal, setIsUpgradePhaseModal] = useState(false);

    useEffect(() => {
        dispatch(getOrganization(organizationId));
    }, [organizationId]);

    const tabItems = [
        {
            label: t('DETAILS'),
            key: 'organization',
            children: <OrganizationDetail />,
        },
        {
            label: t('ENTITY'),
            key: 'entity',
            children: <OrganizationEntity />,
        },
        {
            label: t('DEVICE'),
            key: 'device',
            children: <OrganizationDevice />,
        },
    ];

    const onPhaseUpdate = () => {
        navigate(`/organization-setup/${organizationId}/invoice-phase?phase=${invoicePhase}`);
    };

    return (
        <div className='container organization-details'>
            <Row justify='space-between'>
                <Col span={18}>
                    <div className='organization-name-wrapper'>
                        <CoreBackButton
                            text={''}
                            onClick={() => {
                                navigate('/organizations');
                            }}
                        />
                        <CoreTitle tag='h2' text={organization?.LegalRegistrationName || ''} />
                        {organization?.InvoicePhase === 1 ? (
                            <div
                                className='upgrade-phase'
                                onClick={() => setIsUpgradePhaseModal(true)}
                                aria-hidden='true'
                            >
                                Upgrade to Phase 2
                            </div>
                        ) : null}
                    </div>
                    <div>
                        {t('PHASE')}:{' '}
                        <CoreStatus
                            status={organization?.InvoicePhase === 1 ? '1' : '2'}
                            type='basic'
                        />{' '}
                        | {t('VAT_REGISTRATION_NUMBER')}:{' '}
                        <CoreStatus
                            status={organization?.VatRegistrationNumber || ''}
                            type='basic'
                        />{' '}
                        | {t('CREATED_DATE')}: {UtcToLocalDate(organization?.CreatedAt)} |{' '}
                        {t('LAST_UPDATED_DATE')}: {UtcToLocalDate(organization?.UpdatedAt)}
                    </div>
                </Col>
                <Col span={6}>
                    <div className='edit-button'>
                        <CoreButton
                            text={t('EDIT_ORGANIZATION')}
                            type='primary'
                            linkTo={`/organizations/update/${organizationId}`}
                        />
                    </div>
                </Col>
            </Row>

            <div className='left-tab-wrapper'>
                <Tabs
                    activeKey={activeTabsKey}
                    onChange={(value) => setActiveTabsKey(value as tabsType)}
                    tabPosition='left'
                    type='card'
                    items={tabItems}
                />
            </div>
            <ConfirmationModal
                title='Upgrade to Phase 2'
                description='You are about to upgrade this device from Phase One to Phase Two. Please note that this change is irreversible and may require additional configuration. Confirm to proceed.'
                buttonText='Upgrade'
                buttonType='primary'
                isDeleteModalOpen={isUpgradePhaseModal}
                handleDeleteOk={onPhaseUpdate}
                handleDeleteCancel={() => setIsUpgradePhaseModal(false)}
            />
        </div>
    );
};

export default OrganizationDetails;
