import { AXIOS_INSTANCE, DefaultHeaders } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { IFile } from '../models/file-storage.model';
import { generateUUID } from '@utils/index';
import axios from 'axios';

export const fileStorageAPI = {
    getGetPresignedUrlForUpload: async (fileName: string): Promise<{ url: string; id: string }> => {
        const url = APP_CONFIG.storageUrl + 'StorageCommand/GetPreSignedUrl';
        try {
            const itemId = generateUUID();
            const presignedPayload = { ItemId: itemId, name: fileName, AccessModifier: 0 };
            const response = await AXIOS_INSTANCE.post<any>(url, presignedPayload);
            return { url: response.data.Result, id: itemId };
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    uploadFile: async (file: File): Promise<string> => {
        try {
            const url = APP_CONFIG.storageUrl + 'StorageCommand/UploadFile';
            const bodyFormData = new FormData();
            bodyFormData.append('File', file);
            bodyFormData.append('extata', file);
            const response = await AXIOS_INSTANCE.put(url, bodyFormData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            return response.data.Result;
        } catch (error) {
            console.error('upload failed test', { error });
            throw error;
        }
    },
    getFileUrl: async (fileId: string): Promise<string> => {
        try {
            const path = APP_CONFIG.storageUrl + `StorageQuery/GetFile?FileId=${fileId}`;
            const response = await AXIOS_INSTANCE.get<IFile>(path);
            const url = response.data?.Url;
            return url ? url.replace(/\s/g, '%20') : url;
        } catch (error) {
            console.error('File Id Load Failed', { error });
            return '';
        }
    },
    getBase64File: async (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                const base64Url = reader.result as string;

                resolve(base64Url); // Resolve with null to prevent default file upload behavior
            };

            reader.onerror = (error) => {
                reject(error); // Reject the promise in case of error
            };

            reader.readAsDataURL(file); // Read file as Data URL
        });
    },
    readFileFromURL: async (file: string): Promise<any> => {
        const response = await axios.get<any>(file);
        return response?.data;
    },
};
