import { CoreBackButton, CoreTitle } from '@packages';
import { Col, Divider, Dropdown, Image, MenuProps, message, Row, Spin, Table } from 'antd';
import './InvoiceNewDetails.scss';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useEffect, useState } from 'react';
import { getInvoice, isLoadingSingleInvoice } from '@store/slices/einvoiceSlice';
import { useParams } from 'react-router-dom';
import { RootState } from '@store/store';
import { CopyOutlined, DownOutlined, PlusOutlined, SendOutlined } from '@ant-design/icons';
import { UtcToLocalDate } from '@utils/date';
import { ColumnsType } from 'antd/es/table';
import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import { useTranslation } from 'react-i18next';
import { displayCurrency } from '@utils/displayCurrency';
import InvoiceAttachments from '@client-features/e-invoice/components/invoice-attachments/InvoiceAttachments';
import { getFixedInvoiceType } from '@utils/InvoiceTypeSelectOption';
import { EInvoiceTypeCode } from '@utils/InvoiceUtils';
import XmlView from '@components/file-view/XmlView';
import { fileStorageAPI } from '@service/file-storage.service';
import PdfView from '@components/file-view/PdfView';
import JsonViewer from '@components/file-view/JsonViewer';
import {
    getChargeDiscountAdditionValue,
    getTotalChargeValue,
    getTotalDiscountValue,
} from '@utils/InvoiceCalculation';
import { PAYMENT_STATUS_TYPE, paymentStatusOptions } from '@client-features/e-invoice/constant';
import LogActivity from '@client-features/e-invoice/components/log-activity/LogActivity';

interface IEinvoiceLineTableData {
    Id: number;
    Quantity: number | string;
    UnitCode: string;
    TaxAmount: number | string;
    TaxCategory: string;
    TaxExemptionCode: string;
    RoundingAmount: number | string;
    ItemName: string;
    TaxPercent: number | string;
    PriceAmount: number | string;
}

const InvoiceNewDetails = () => {
    const { t } = useTranslation();
    const { invoiceId = '' } = useParams();
    const dispatch = useAppDispatch();
    const { invoice } = useAppSelector((state: RootState) => state.einvoice);
    const isLoading = useAppSelector(isLoadingSingleInvoice);

    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isViewing, setViewing] = useState<boolean>(false);
    const [invoiceLines, setInvoiceLines] = useState<IEinvoiceLineTableData[]>([]);
    const [xmlUrl, setXmlUrl] = useState<string>();
    const [pdfUrl, setPDFUrl] = useState<string>();
    const [isXmlModalOpen, setXmlModalOpen] = useState<boolean>(false);
    const [isJsonModalOpen, setJsonModalOpen] = useState<boolean>(false);
    const [isPdfModalOpen, setPdfModalOpen] = useState<boolean>(false);
    const [xmlText, setXmlText] = useState<string>();

    const [selectedStatus, setSelectedStatus] = useState<keyof typeof PAYMENT_STATUS_TYPE>(
        (invoice?.PaymentStatus as keyof typeof PAYMENT_STATUS_TYPE) || '',
    );

    useEffect(() => {
        dispatch(getInvoice(invoiceId));
    }, []);

    useEffect(() => {
        prepareInvoiceLinesItem();
    }, [invoice?.InvoiceLines]);

    const prepareInvoiceLinesItem = () => {
        const lines: IEinvoiceLineTableData[] = [];
        invoice?.InvoiceLines.forEach((data) => {
            lines.push({
                Id: data.ID,
                Quantity: data.Quantity || DEFAULT_EMPTY_VALUE,
                UnitCode: data.UnitCode || DEFAULT_EMPTY_VALUE,
                TaxAmount: data.TaxAmount || DEFAULT_EMPTY_VALUE,
                TaxCategory: data.TaxCategory || DEFAULT_EMPTY_VALUE,
                RoundingAmount: data.RoundingAmount || DEFAULT_EMPTY_VALUE,
                ItemName: data.ItemName || DEFAULT_EMPTY_VALUE,
                TaxPercent: data.TaxPercent ?? DEFAULT_EMPTY_VALUE,
                PriceAmount: data.PriceAmount ?? DEFAULT_EMPTY_VALUE,
                TaxExemptionCode: data.TaxExemptionCode ?? DEFAULT_EMPTY_VALUE,
            });
        });
        setInvoiceLines(lines);
    };

    const columns: ColumnsType<IEinvoiceLineTableData> = [
        {
            title: t('ID'),
            dataIndex: 'Id',
        },
        {
            title: t('ITEM_NAME'),
            dataIndex: 'ItemName',
        },
        {
            title: t('QUANTITY'),
            dataIndex: 'Quantity',
        },
        {
            title: t('SALE_PRICE'),
            dataIndex: 'PriceAmount',
            render: (text: string, data) => (
                <span>
                    {(data?.PriceAmount &&
                        displayCurrency({
                            Amount: data?.PriceAmount,
                            Code: `${invoice?.DocumentCurrencyCode}`,
                        })) ??
                        DEFAULT_EMPTY_VALUE}
                </span>
            ),
        },
        {
            title: t('UNIT_OF_MEASURE'),
            dataIndex: 'UnitCode',
        },
        {
            title: t('VAT_RATE'),
            dataIndex: 'TaxPercent',
            render: (text: string, data) => {
                return <span>{data?.TaxPercent ?? DEFAULT_EMPTY_VALUE}%</span>;
            },
        },
        {
            title: t('VAT_AMOUNT'),
            dataIndex: 'TaxAmount',
            render: (text: string, data) => (
                <span>
                    {(data?.TaxAmount &&
                        displayCurrency({
                            Amount: isNaN(data?.TaxAmount as number) ? 0 : data?.TaxAmount,
                            Code: 'SAR',
                        })) ||
                        DEFAULT_EMPTY_VALUE}
                </span>
            ),
        },
        {
            title: t('ROUNDING_AMOUNT'),
            dataIndex: 'RoundingAmount',
            render: (text: string, data) => (
                <span>
                    {displayCurrency({
                        Amount: isNaN(data?.RoundingAmount as number) ? 0 : data?.RoundingAmount,
                        Code: `${invoice?.DocumentCurrencyCode}`,
                    })}
                </span>
            ),
        },
    ];

    const onDownloadItem = async (itemId: string, fileName: 'XML' | 'PDF') => {
        if (fileName === 'XML' && xmlUrl) return xmlUrl;
        if (fileName === 'PDF' && pdfUrl) return pdfUrl;
        try {
            const response = await fileStorageAPI.getFileUrl(itemId);
            fileName === 'XML' && setXmlUrl(response);
            fileName === 'PDF' && setPDFUrl(response);
            return response;
        } catch (error) {
            setIsDownloading(false);
            setViewing(false);
            message.error('File information not found ');
            throw error;
        }
    };

    const readPdfFile = async () => {
        if (!invoice?.PdfFile.FileId) return;
        if (pdfUrl) {
            setPdfModalOpen(true);
            return;
        }
        setViewing(true);
        const fileUrl = await onDownloadItem(invoice?.PdfFile.FileId, 'PDF');
        setPDFUrl(fileUrl);
        setPdfModalOpen(true);
        setViewing(false);
    };

    const readXmlFile = async () => {
        if (!invoice?.XmlFile.FileId) return;

        if (xmlText) {
            setXmlModalOpen(true);
            return;
        }
        setViewing(true);
        const fileUrl = await onDownloadItem(invoice?.XmlFile.FileId, 'XML');
        fileStorageAPI.readFileFromURL(fileUrl).then((response) => {
            setXmlText(response);
            setXmlModalOpen(true);
        });
        setViewing(false);
    };

    const readJsonFile = async () => {
        setJsonModalOpen(true);
    };

    const downloadXml = async () => {
        if (!invoice?.XmlFile?.FileId) return;
        setIsDownloading(true);
        const file = await onDownloadItem(invoice?.XmlFile.FileId, 'XML');
        window.open(file, '_blank');
        setIsDownloading(false);
    };

    const downloadPdf = async () => {
        if (!invoice?.PdfFile?.FileId) return;
        setIsDownloading(true);
        const file = await onDownloadItem(invoice?.PdfFile.FileId, 'PDF');
        setIsDownloading(false);
        window.open(file, '_blank');
    };

    const dropDownItems: MenuProps['items'] = [
        {
            key: '1',
            label: 'Lock Invoice',
        },
        {
            type: 'divider',
        },
        {
            key: '2',
            label: 'View XML',
            onClick: () => readXmlFile(),
        },
        {
            key: '3',
            label: 'View PDF',
            onClick: () => readPdfFile(),
        },
        {
            key: '4',
            label: 'View ZATCA Response',
            onClick: () => readJsonFile(),
        },
        {
            type: 'divider',
        },
        {
            key: '5',
            label: 'Raise Credit Note',
            onClick: () => readXmlFile(),
        },
        {
            key: '6',
            label: 'Raise Debit Note',
        },
        {
            type: 'divider',
        },
        {
            key: '7',
            label: 'Download XML',
            onClick: () => downloadXml(),
        },
        {
            key: '8',
            label: 'Download PDF',
            onClick: () => downloadPdf(),
        },
    ];

    const handleMenuClick = (value: any) => {
        setSelectedStatus(value);
        console.log(value, 'value');
    };

    const paymentItems: MenuProps['items'] = paymentStatusOptions.map((option) => ({
        key: option.value,
        label: option.label,
        onClick: () => handleMenuClick(option.value),
    }));

    return (
        <Spin spinning={isLoading}>
            <div className='invoice-details-wrapper'>
                <div className='container invoice-details-inner-wrapper'>
                    <Row gutter={12}>
                        <Col span={14}>
                            <div className='header-wrapper'>
                                <CoreBackButton text={''} />
                                <div className='invoice-text-wrapper'>
                                    <p>Invoice</p>
                                </div>
                            </div>
                            <div className='invoice-details'>
                                <div className='invoice-id-section'>
                                    <CoreTitle tag='h1' text={invoice?.InvoiceId || ''}/>
                                    <p>for {invoice?.DocumentCurrencyCode} {invoice?.LegalMonetaryTotal?.PayableAmount}</p>
                                    <Dropdown menu={{ items: paymentItems }} trigger={['click']}>
                                        <div
                                            className='payment-status-dropdown'
                                            onClick={(e) => e.preventDefault()}>
                                            {selectedStatus
                                                ? paymentStatusOptions.find(
                                                      (option) => option.value === selectedStatus,
                                                  )?.label
                                                : 'Select Payment Status'}
                                            <DownOutlined />
                                        </div>
                                    </Dropdown>
                                </div>
                                <div className='invoice-action-section'>
                                    <div className='action-button'>
                                        <PlusOutlined />
                                        <p>Add Note</p>
                                    </div>

                                    <Divider type='vertical' className='action-divider' />

                                    <div className='action-button'>
                                        <SendOutlined style={{ transform: `rotate(-45deg)` }} />
                                        <p>Send Invoice</p>
                                    </div>

                                    <Divider type='vertical' className='action-divider' />

                                    <Dropdown menu={{ items: dropDownItems }} trigger={['click']}>
                                        <div className='action-button'>
                                            <p style={{ margin: 0 }}>More</p>
                                            <DownOutlined />
                                        </div>
                                    </Dropdown>
                                </div>
                            </div>
                            <div style={{ marginTop: '28px' }}>
                                <CoreTitle tag='h4' text='Summary' />
                            </div>
                        </Col>
                        <Col span={10}>
                            <div className='qr-code-wrapper'>
                                <Image
                                    src={invoice?.QrCode}
                                    width={200}
                                    height={200}
                                    preview={false}
                                />
                            </div>
                        </Col>
                    </Row>

                    <div className='invoice-summary-section-wrapper'>
                        <div className='invoice-summary-section round-box-shadow'>
                            <div className='summary-single-box'>
                                <div className='summary-single-box-text'>
                                    <p className='core-title'>{'Invoice ID'}</p>
                                    <p className='core-title'>
                                        {invoice?.InvoiceId || DEFAULT_EMPTY_VALUE}
                                    </p>
                                </div>
                                <div className='summary-single-box-text'>
                                    <p className='core-title'>{'Currency'}</p>
                                    <p className='core-title'>
                                        {invoice?.DocumentCurrencyCode || DEFAULT_EMPTY_VALUE}
                                    </p>
                                </div>
                                <div className='summary-single-box-text'>
                                    <p className='core-title'>{'Issue Date'}</p>
                                    <p className='core-title'>
                                        {UtcToLocalDate(invoice?.IssueDate) || DEFAULT_EMPTY_VALUE}
                                    </p>
                                </div>
                                <div className='summary-single-box-text'>
                                    <p className='core-title'>{'Issue Time'}</p>
                                    <p className='core-title'>
                                        {invoice?.IssueTime || DEFAULT_EMPTY_VALUE}
                                    </p>
                                </div>
                                <div className='summary-single-box-text'>
                                    <p className='core-title'>{'Invoice Type'}</p>
                                    <p className='core-title'>
                                        {getFixedInvoiceType(invoice?.InvoiceTypeCode).includes(
                                            EInvoiceTypeCode.B2B,
                                        )
                                            ? t('STANDARD_TAX_INVOICE')
                                            : t('SIMPLIFIED_TAX_INVOICE') || DEFAULT_EMPTY_VALUE}
                                    </p>
                                </div>
                            </div>

                            <Divider
                                type='vertical'
                                style={{ background: '#DDDDDD', margin: '0px', height: 'auto' }}
                            />

                            <div className='summary-single-box'>
                                <div className='summary-single-box-text'>
                                    <p className='core-title'>{'Customer Name'}</p>
                                    <p className='core-title'>
                                        {invoice?.CustomerNameEn || DEFAULT_EMPTY_VALUE}
                                    </p>
                                </div>
                                <div className='summary-single-box-text'>
                                    <p className='core-title'>{'Vat Registration Number'}</p>
                                    <p className='core-title'>
                                        {invoice?.VatRegistrationNumber || DEFAULT_EMPTY_VALUE}
                                    </p>
                                </div>
                                <div className='summary-single-box-text'>
                                    <p className='core-title'>{'Additional Buyer Ref'}</p>
                                    <p className='core-title'>
                                        {UtcToLocalDate(invoice?.IssueDate) || DEFAULT_EMPTY_VALUE}
                                    </p>
                                </div>
                                <div className='summary-single-box-text'>
                                    <p className='core-title'>{'Email'}</p>
                                    <p className='core-title'>
                                        {invoice?.IssueTime || DEFAULT_EMPTY_VALUE}
                                    </p>
                                </div>
                            </div>

                            <Divider
                                type='vertical'
                                style={{ background: '#DDDDDD', margin: '0px', height: 'auto' }}
                            />

                            <div className='summary-single-box'>
                                <div className='summary-single-box-text'>
                                    <p className='core-title'>{'Supply Start Date'}</p>
                                    <p className='core-title'>
                                        {UtcToLocalDate(invoice?.Delivery?.ActualDeliveryDate) || DEFAULT_EMPTY_VALUE}
                                    </p>
                                </div>
                                <div className='summary-single-box-text'>
                                    <p className='core-title'>{'Supply End Date'}</p>
                                    <p className='core-title'>
                                        {UtcToLocalDate(invoice?.Delivery?.LatestDeliveryDate) || DEFAULT_EMPTY_VALUE}
                                    </p>
                                </div>
                                <div className='summary-single-box-text'>
                                    <p className='core-title'>{'Contract Document ID'}</p>
                                    <p className='core-title'>
                                        {invoice?.ContractDocumentID || DEFAULT_EMPTY_VALUE}
                                    </p>
                                </div>
                                <div className='summary-single-box-text'>
                                    <p className='core-title'>{'Order Reference Id'}</p>
                                    <p className='core-title'>
                                        {invoice?.OrderReferenceID || DEFAULT_EMPTY_VALUE}
                                    </p>
                                </div>
                                <div className='summary-single-box-text'>
                                    <p className='core-title'>{'ZATCA Status'}</p>
                                    <p className='core-title'>
                                        {invoice?.ZatcaStatus || DEFAULT_EMPTY_VALUE}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='invoice-lines-section'>
                        <Table
                            rowKey={t('ID')}
                            columns={columns}
                            dataSource={invoiceLines}
                            pagination={false}
                            scroll={{ x: '600px' }}
                            className='invoice-lines-table'
                        />
                    </div>

                    <div className='invoice-calculation-section-wrapper'>
                        <div className='invoice-calculation-section'>
                            <div className='invoice-calculation-box'>
                                <p>Exchange Rate</p>
                                <p>{invoice?.ExchangeRate?.toString() || DEFAULT_EMPTY_VALUE}</p>
                            </div>

                            <div className='invoice-calculation-box'>
                                <p>Discount</p>
                                <p>
                                    {displayCurrency({
                                        Amount: invoice?.LegalMonetaryTotal?.ChargeTotalAmount,
                                        Code: invoice?.DocumentCurrencyCode || '',
                                    })}
                                </p>
                            </div>

                            <div className='invoice-calculation-box'>
                                <p>Allowance Charge</p>
                                <p>
                                    {displayCurrency({
                                        Amount: invoice?.LegalMonetaryTotal?.AllowanceTotalAmount,
                                        Code: invoice?.DocumentCurrencyCode || '',
                                    })}
                                </p>
                            </div>

                            <div className='invoice-calculation-box'>
                                <p>Prepaid Amount</p>
                                <p>
                                    {invoice?.LegalMonetaryTotal?.PrepaidAmount
                                        ? displayCurrency({
                                            Amount: invoice?.LegalMonetaryTotal?.PrepaidAmount,
                                            Code: invoice.TaxCurrencyCode,
                                        })
                                        : displayCurrency({
                                            Amount: 0,
                                            Code: invoice?.TaxCurrencyCode || '',
                                        })}
                                </p>
                            </div>

                        </div>
                        <div className='invoice-calculation-section'>

                            <div className='invoice-calculation-box'>
                                <p>Line Extension Amount</p>
                                <p>
                                    {invoice?.LegalMonetaryTotal?.LineExtensionAmount
                                        ? displayCurrency({
                                            Amount: invoice?.LegalMonetaryTotal
                                                ?.LineExtensionAmount,
                                            Code: invoice.DocumentCurrencyCode,
                                        })
                                        : displayCurrency({
                                            Amount: 0,
                                            Code: invoice?.DocumentCurrencyCode || '',
                                        })}
                                </p>
                            </div>

                            <div className='invoice-calculation-box'>
                                <p>Tax Exclusive Amount</p>
                                <p>
                                    {invoice?.LegalMonetaryTotal?.TaxExclusiveAmount
                                        ? displayCurrency({
                                            Amount: invoice?.LegalMonetaryTotal
                                                ?.TaxExclusiveAmount,
                                            Code: invoice.DocumentCurrencyCode,
                                        })
                                        : displayCurrency({
                                            Amount: 0,
                                            Code: invoice?.DocumentCurrencyCode || '',
                                        })}
                                </p>
                            </div>

                            <div className='invoice-calculation-box'>
                                <p>Tax Inclusive Amount</p>
                                <p>
                                    {invoice?.LegalMonetaryTotal?.TaxInclusiveAmount
                                        ? displayCurrency({
                                            Amount: invoice?.LegalMonetaryTotal
                                                ?.TaxInclusiveAmount,
                                            Code: invoice.DocumentCurrencyCode,
                                        })
                                        : displayCurrency({
                                            Amount: 0,
                                            Code: invoice?.DocumentCurrencyCode || '',
                                        })}
                                </p>
                            </div>

                            <div className='invoice-calculation-box'>
                                <p>Tax Amount</p>
                                <p>
                                    {invoice?.LegalMonetaryTotal?.TaxAmount
                                        ? displayCurrency({
                                            Amount: invoice?.LegalMonetaryTotal?.TaxAmount,
                                            Code: invoice.TaxCurrencyCode,
                                        })
                                        : displayCurrency({
                                            Amount: 0,
                                            Code: invoice?.TaxCurrencyCode || '',
                                        })}
                                </p>
                            </div>

                            <div className='invoice-calculation-box'>
                                <p>Payable Amount</p>
                                <p>
                                    {invoice?.LegalMonetaryTotal?.PayableAmount
                                        ? displayCurrency({
                                            Amount: invoice?.LegalMonetaryTotal?.PayableAmount,
                                            Code: invoice.DocumentCurrencyCode,
                                        })
                                        : displayCurrency({
                                            Amount: 0,
                                            Code: invoice?.DocumentCurrencyCode || '',
                                        })}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='invoice-activity-attachment-section'>
                        <Row gutter={[ 24, 24 ]}>
                            <Col xs={24} lg={12}>
                                <LogActivity/>
                            </Col>
                            <Col xs={24} lg={12}>
                                <InvoiceAttachments/>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <XmlView
                xmlText={xmlText || ''}
                isOpenModal={isXmlModalOpen}
                closeModal={() => setXmlModalOpen(false)}
            />

            {pdfUrl && (
                <PdfView
                    url={pdfUrl}
                    isOpenModal={isPdfModalOpen}
                    closeModal={() => setPdfModalOpen(false)}
                />
            )}

            <JsonViewer
                jsonData={invoice?.ZatcaResponse}
                isOpenModal={isJsonModalOpen}
                closeModal={() => setJsonModalOpen(false)}
            />
        </Spin>
    );
};

export default InvoiceNewDetails;
