import { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import './UploadListTable.scss';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { DEFAULT_EMPTY_VALUE, DEFAULT_TABLE_SIZE } from '@config/constants';
import { useTranslation } from 'react-i18next';
import { selectGetUploadCsvConfigs, uploadCSVConfigFiles } from '@store/slices/invoiceMappingSlice';
import { getIdFormUUID } from '@utils/getIdFromUUID';

const UploadListTable = () => {
    const { t } = useTranslation();
    const [pageInfo, setPageInfo] = useState<{ PageLimit: number; PageNumber: number }>({
        PageLimit: 10,
        PageNumber: 1,
    });
    const uploadCsvConfigs = useAppSelector(selectGetUploadCsvConfigs);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const columns: ColumnsType<any> = [
        {
            title: t('Map Config ID'),
            dataIndex: 'MapConfigId',
            render: (text: string) => <span>{getIdFormUUID(text) || DEFAULT_EMPTY_VALUE}</span>,
        },
        {
            title: t('Map SubscriptionConfiguration Name'),
            dataIndex: 'MapConfigurationName',
            render: (text: string) => <span>{text || DEFAULT_EMPTY_VALUE}</span>,
        },
    ];

    const handleRowClick = (record: any) => {
        navigate(`/upload-invoices/details/${record.MapConfigId}`);
    };

    const getCsvConfig = () => {
        dispatch(uploadCSVConfigFiles());
    };

    useEffect(() => {
        getCsvConfig();
    }, []);

    return (
        <div className='ksa-table-wrapper table-auto-scroll-with-header'>
            <Table
                loading={uploadCsvConfigs.status === 'loading'}
                columns={columns}
                dataSource={uploadCsvConfigs.data}
                rowKey={'MapConfigId'}
                rowClassName='cursor-pointer'
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => handleRowClick(record), // click row
                    };
                }}
                pagination={{
                    pageSize: DEFAULT_TABLE_SIZE,
                    total: uploadCsvConfigs?.data?.length ?? 0,
                    showSizeChanger: false,
                    showQuickJumper: false,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    onChange: (PageNumber, PageLimit) => {
                        setPageInfo({ PageNumber, PageLimit });
                    },
                    current: pageInfo.PageNumber,
                }}
            />
        </div>
    );
};

export default UploadListTable;
