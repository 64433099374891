import './ComparisonCard.scss';
import _ from 'lodash';
import { CoreButton, CoreTitle } from '@packages';
import { Divider } from 'antd';
import { ISubscribePlan } from '@models/subscription.model';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useAppSelector } from '@store/hooks';
import { selectCurrentSubscriptions } from '@store/slices/subscribeSlice';
import { useNavigate } from 'react-router-dom';

export interface IComparisonCardProps {
    title: string;
    description: string;
    price: string;
    period: string;
    type: string;
    tier: ISubscribePlan;
}

const ComparisonCard = ({
    title,
    description,
    price,
    period,
    type,
    tier,
}: IComparisonCardProps) => {
    const detailsData = getFormattedData(tier, type);
    const navigate = useNavigate();
    const { TierId } = useAppSelector(selectCurrentSubscriptions) || {};

    if (!detailsData) return null;

    const renderIcon = (value: boolean) =>
        value ? (
            <CheckCircleOutlined style={{ color: 'green' }} />
        ) : (
            <CloseCircleOutlined style={{ color: 'red' }} />
        );

    return (
        <div className='comparison-card-wrapper round-box-shadow '>
            <div className='comparison-card-header'>
                <p>Best For Starters & Small Teams</p>
            </div>
            <div className='comparison-card-inner-wrapper'>
                <div className='tire-basic-info'>
                    <CoreTitle tag='h4' text={title} />
                    <div className='currency-line'>
                        <CoreTitle tag='h1' text={price} />
                    </div>
                    <p className='duration-line'>per {period}</p>
                </div>

                <Divider className='comparison-card-divider' />
                <div className='comparison-card-description-wrapper'>
                    <p className='comparison-card-description'>{description}</p>
                    <CoreButton
                        text={tier?.ItemId == TierId ? 'Current Plan' : 'Select Plan'}
                        type={tier?.ItemId == TierId ? 'basic' : 'primary'}
                        disabled={tier?.ItemId == TierId}
                        isFullWidth={true}
                        onClick={() => {
                            navigate(
                                `/subscribe/manage-subscription?id=${tier?.ItemId}&type=${type}`,
                            );
                        }}
                    />
                </div>
                <Divider className='comparison-card-divider' />

                <div className='tire-description-info-wrapper'>
                    {detailsData?.map(
                        ([key, value], id) =>
                            key && (
                                <div key={id} className='tire-description-info'>
                                    <p>{key}:</p>
                                    <p>
                                        {typeof value === 'boolean' ? (
                                            renderIcon(value)
                                        ) : (
                                            <span>{value}</span>
                                        )}
                                    </p>
                                </div>
                            ),
                    )}
                </div>
            </div>
        </div>
    );
};

export default ComparisonCard;

const getFormattedData = (data: any, type: string): [string, any][] => {
    const excludeKeys = [
        'ItemId',
        'Name',
        'Currency',
        'IsPublic',
        'IsActive',
        'Description',
        type === 'month' ? 'YearlyPrice' : 'MonthlyPrice',
    ];

    return Object.entries(data)
        .filter(([key]) => !excludeKeys.includes(key))
        .map(([key, value]) => [_.startCase(key), value] as [string, any]);
};
