import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import CustomPagination from '@components/table/CustomPagination';
import CustomTable from '@components/table/CustomTable';
import { IUserFilterPayload, IUserTable } from '@models/user.model';
import { useAppDispatch } from '@store/hooks';
import { getUsers } from '@store/slices/userSlice';
import { RootState } from '@store/store';
import { UtcToLocalDate } from '@utils/date';
import { ColumnsType } from 'antd/es/table';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface IUserListTableProps {
    filterData?: IUserFilterPayload;
}

const pageLimit = 10;

const UserListTable = ({ filterData }: IUserListTableProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { data, Next, Nonce, status } = useSelector((state: RootState) => state.user.users);

    useEffect(() => {
        dispatch(getUsers({ PageLimit: 50, ...filterData }));
    }, [filterData, dispatch]);

    const goToUserEdit = (e: any, record: any) => {
        e.stopPropagation();
        navigate(`/iam/user/edit/${record.ItemId}`);
    };
    const onDeleteClicked = (e: any) => {
        e.stopPropagation();
    };

    const columns: ColumnsType<any> = [
        {
            key: '1',
            title: 'Name',
            dataIndex: 'UserName',
        },
        {
            key: '2',
            title: 'Email',
            dataIndex: 'Email',
        },
        // {
        //     key: '3',
        //     title: 'Password Age',
        //     dataIndex: 'PasswordLastChanged',
        //     render: (text: string) => <span>{calculateTimeDifference(text)}</span>,
        // },
        {
            key: '3',
            title: 'Created At',
            dataIndex: 'CratedAt',
            render: (text: string) => <span>{UtcToLocalDate(text)}</span>,
        },
        {
            key: '5',
            title: '',
            dataIndex: 'Actions',
            render: (_, record) => (
                <div className='action-buttons'>
                    <div className='edit-table-icon' onClick={(e) => goToUserEdit(e, record)}>
                        <EditOutlined />
                    </div>
                    <div className='edit-table-icon'>
                        <DeleteOutlined
                            onClick={(e) => {
                                onDeleteClicked(e);
                            }}
                            style={{ color: 'red' }}
                        />
                    </div>
                </div>
            ),
        },
    ];

    const handleRowClick = (record: IUserTable) => {
        navigate(`/admin/iam/user/${record.Email}`);
    };

    const nextPage = () => {
        dispatch(getUsers({ PageLimit: pageLimit, Next, Nonce, isConcat: true }));
    };

    return (
        <div className='ksa-table-wrapper table-auto-scroll-without-header'>
            <CustomTable
                className='table-auto-scroll-with-header'
                columns={columns}
                hasNext={!!Next}
                onRowClick={handleRowClick}
                data={data}
                isLoading={status === 'loading' ? true : false}
                uniqueId='ItemId'
                currentPage={1}
                goNextPage={nextPage}
            />
        </div>
    );
};

export default UserListTable;
