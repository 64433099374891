import { useEffect, useState } from 'react';
import { useAppDispatch } from '@store/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { getEmailTemplates } from '@store/slices/admin/adminTemplateSlice';
import { useNavigate } from 'react-router-dom';
import { IEmailTemplate, IEmailTemplateTable } from '@models/admin/template.model';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { AdminTemplateAPI } from '@service/admin/template.service';
import { message, Table } from 'antd';
import { CoreButton, CoreSearchInput } from '@packages';
import { ConfirmationModal } from '@components';
import { unwrapResult } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';

const EmailTemplateList = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { emailTemplates } = useSelector((state: RootState) => state.adminTemplate);
    const [templatesTableData, setTemplatesTableData] = useState<IEmailTemplateTable[]>([]);
    const [isDeleteTemplateModalOpen, setIsDeleteTemplateModalOpen] = useState(false);
    const [template, setTemplate] = useState<IEmailTemplate | null>(null);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getEmailTemplates());
    }, []);

    useEffect(() => {
        if (!emailTemplates.isLoading && emailTemplates) {
            formatTemplateData();
        }
    }, [emailTemplates]);

    const formatTemplateData = () => {
        const data: IEmailTemplateTable[] = [];
        if (emailTemplates?.data?.length) {
            (emailTemplates.data as IEmailTemplate[]).forEach((template) => {
                data.push({
                    MailConfigurationId: template.MailConfigurationId,
                    Subject: template.Subject,
                    MailPurpose: template.MailPurpose,
                    Language: template.Language,
                });
            });
        }
        setTemplatesTableData(data);
    };

    const columns: ColumnsType<IEmailTemplate> = [
        {
            title: 'Mail ConfigurationId Id',
            dataIndex: 'MailConfigurationId',
            render: (TemplateId: string) => <>{TemplateId.slice(0, 5)}</>,
        },
        {
            title: 'Subject',
            dataIndex: 'Subject',
        },
        {
            title: 'Mail Purpose',
            dataIndex: 'MailPurpose',
        },
        {
            title: '',
            dataIndex: 'MailConfigurationId',
            render: (TemplateId: string, data: IEmailTemplate) => (
                <div className='action-buttons'>
                    <div
                        className='edit-table-icon'
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                                `/admin/template/email/update/${TemplateId}?MailPurpose=${data?.MailPurpose}&Language=${data?.Language}`,
                            );
                        }}
                    >
                        <EditOutlined />
                    </div>
                    <div className='edit-table-icon' onClick={(e) => handleDeleteClick(e, data)}>
                        <DeleteOutlined />
                    </div>
                </div>
            ),
        },
    ];

    const handleRowClick = async (e: any, template: IEmailTemplate) => {
        e.stopPropagation();
        navigate(`/admin/template/email/${template?.MailConfigurationId}?MailPurpose=${template?.MailPurpose}&Language=${template?.Language}`);
    };

    const handleDeleteClick = (e: any, Template: IEmailTemplate) => {
        e.stopPropagation();
        setIsDeleteTemplateModalOpen(true);
        setTemplate(Template);
    };

    const onTemplateDelete = async () => {
        if (!template) return;
        setButtonLoading(true);

        await AdminTemplateAPI.deleteEmailTemplate({
            MailPurpose: template?.MailPurpose,
            Language: template?.Language,
        })
            .then(unwrapResult)
            .then(() => {
                setButtonLoading(false);
                message.success(`Email Template Deleted Successfully`);
                setTemplate(null);
                dispatch(getEmailTemplates());
                setIsDeleteTemplateModalOpen(false);
            })
            .catch((err) => {
                message.error(err.data.Message || 'Email Template Delete Failed');
                setButtonLoading(false);
            });
    };

    return (
        <div className='template-list-wrapper'>
            <div className='template-list-header'>
                <CoreSearchInput placeholder={'Search'} />

                <CoreButton
                    type='primary'
                    text='Add Template'
                    onClick={() => navigate('/admin/template/email/create')}
                />
            </div>

            <Table
                loading={emailTemplates?.isLoading}
                columns={columns}
                dataSource={templatesTableData}
                rowClassName='cursor-pointer'
                rowKey={uniqueId()}
                onRow={(record) => {
                    return {
                        onClick: (e) => handleRowClick(e, record),
                    };
                }}
            />

            <ConfirmationModal
                title='Delete Template'
                description='Would you like to delete this Template?'
                buttonText='Delete'
                isDeleteModalOpen={isDeleteTemplateModalOpen}
                handleDeleteOk={() => onTemplateDelete()}
                handleDeleteCancel={() => setIsDeleteTemplateModalOpen(false)}
                isLoading={buttonLoading}
            />
        </div>
    );
};
export default EmailTemplateList;
