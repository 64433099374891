import React from 'react';
import './BillingTab.scss';
import { Col, Row } from 'antd';
import { CoreTitle } from '@packages';
import BillingList from '../billing-list/BillingList';

const BillingTab = () => {
    return (
        <div className='billing-tab-wrapper'>
            <Row gutter={[24, 12]} className='margin-top-16'>
                <Col xs={24} lg={10} xl={8}>
                    <div className='billing-tab-actions'>
                        <p className='billing-tab-title'>Billing Addresses</p>
                        <p className='billing-tab-sub-title'>Manage your billing addresses</p>
                    </div>
                </Col>
                <Col xs={24} lg={12} xl={10}>
                    <BillingList />
                </Col>
            </Row>
        </div>
    );
};

export default BillingTab;
