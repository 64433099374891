import { AxiosError } from 'axios';
import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { IUpdateUser, IUser, IUserGetPayload, IUserResponse } from '@models/user.model';
import { processQuery } from '@utils/processQuery';

export const UserAPI = {
    GetUserDetails: async (): Promise<IUser> => {
        const url = APP_CONFIG.identityUrl + `UserQuery/GetUserDetails`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    updateLoggedUserInfo: async (user: IUpdateUser): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `UserCommand/UpdateUser`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, user);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getUsers: async (payload: IUserGetPayload): Promise<IUserResponse> => {
        const url = APP_CONFIG.identityUrl + `UserQuery/GetUsers`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result as IUserResponse;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getUserByMail: async (payload: { Email: string }): Promise<any> => {
        const url = APP_CONFIG.identityUrl + `UserQuery/GetUserByMail${processQuery(payload)}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
