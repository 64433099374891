import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { AxiosError } from 'axios';

export const AdminUserAPI = {
    createUser: async (payload: any) => {
        const url = APP_CONFIG.eisUrl + `AccessManagement/CreateWithRoles`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
