import { useEffect } from 'react';
import './ManageSubscription.scss';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getPaymentMethods } from '@store/slices/paymentSlice';
import { CoreBackButton, CoreButton, CoreTitle } from '@packages';
import { useLocation, useNavigate } from 'react-router-dom';
import { Collapse, CollapseProps, Divider } from 'antd';
import { getTier, singleTierData } from '@store/slices/subscribeSlice';
import { payAsGoTiredData, payAsYouGoId } from '@utils/payAsGoTireData';
import {
    CalculatorOutlined,
    CheckCircleFilled,
    MinusCircleOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';
import OrderSummary from '../../components/manage-subscriptions/order-summary/OrderSummary';
import BillingAddressSection from '../../components/manage-subscriptions/billing-address-section/BillingAddressSection';
import PaymentMethodSection from '../../components/manage-subscriptions/payment-method-section/PaymentMethodSection';
import HeaderLayout from '@components/main-layout/header-layout/HeaderLayout';

const CollapseContent = [
    {
        key: '1',
        label: 'Is there a free trial available?',
        content: `Enjoy access to all features during your 14-day free trial, with some usage limitations to let you explore the platform risk-free. After your trial, upgrade to a subscription plan or our flexible "Pay as you go" model and unlock unlimited access based on your needs.`,
    },
    {
        key: '2',
        label: 'Do you need my credit card for the free trial?',
        content:
            'No, we don’t require a credit card for the 14-day free trial. Enjoy exploring our platform with no strings attached! After the trial ends, you’ll need to add a credit or debit card to subscribe to one of our tiers or opt for the "Pay as you go" model.',
    },
    {
        key: '3',
        label: "How do I cancel if I'm not super impressed?",
        content:
            'You can cancel your subscription anytime—no questions asked. Simply navigate to the subscription overview in your subscription area and select the "Cancel subscription" option. You\'re in complete control, and you can unsubscribe whenever you want.',
    },
];

export default function ManageSubscription() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const planId = queryParams.get('id');
    const recurringType = queryParams.get('type');
    const isPayAsYouGo = planId === payAsYouGoId;
    const { data: singleTier } = useAppSelector(singleTierData);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getPaymentMethods());
        if (planId !== payAsYouGoId) {
            dispatch(getTier({ TierId: planId || '' }));
        }
    }, []);

    const CollapseItems: CollapseProps['items'] = CollapseContent.map((item) => ({
        key: item.key,
        label: item.label,
        children: <p>{item.content}</p>,
    }));

    return (
        <HeaderLayout>
            <div>
                <CoreBackButton
                    text={''}
                    onClick={() => {
                        navigate('/subscribe/pricing-plans');
                    }}
                />
            </div>
            <div className='mange-subscription-wrapper'>
                <div className='mange-subscription-column'>
                    <div className='column-one'>
                        <div className='left-collapse-section'>
                            <p className='complete-step-message'>The Last Step</p>
                            <CoreTitle tag='h1' text='Complete payment' />
                            <div className='collapse-wrapper'>
                                <Collapse
                                    items={CollapseItems}
                                    defaultActiveKey={['1']}
                                    bordered={false}
                                    expandIcon={({ isActive }) =>
                                        isActive ? (
                                            <MinusCircleOutlined
                                                style={{ fontSize: '18px', color: '#A4A7AE' }}
                                            />
                                        ) : (
                                            <PlusCircleOutlined
                                                style={{ fontSize: '18px', color: '#A4A7AE' }}
                                            />
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='column-two'>
                        <div className='order-summary-wrapper'>
                            <div className='order-summary round-box-shadow '>
                                <div className='order-header-wrapper'>
                                    <p>Your Plan</p>
                                    <div className='plan-name'>
                                        <p>
                                            {isPayAsYouGo
                                                ? payAsGoTiredData?.Name
                                                : singleTier?.Name}
                                        </p>
                                        <CheckCircleFilled className='checked-icon' />
                                    </div>
                                </div>
                                <div className='order-details'>
                                    <OrderSummary
                                        planId={planId || ''}
                                        recurringType={recurringType || ''}
                                        singleTier={singleTier || null}
                                    />

                                    <Divider
                                        style={{
                                            margin: '10px 0',
                                            borderBlockStart: '1px solid #EAECF0',
                                        }}
                                    />

                                    <BillingAddressSection />

                                    <Divider
                                        style={{
                                            margin: '10px 0',
                                            borderBlockStart: '1px solid #EAECF0',
                                        }}
                                    />

                                    <PaymentMethodSection
                                        planId={planId || ''}
                                        recurringType={recurringType || ''}
                                    />

                                    {isPayAsYouGo && (
                                        <div
                                            aria-hidden='true'
                                            className='pricing-calculator'
                                            onClick={() =>
                                                navigate('/subscribe/pricing-calculator')
                                            }
                                        >
                                            <p>Pricing Calculator</p>
                                            <CalculatorOutlined
                                                style={{
                                                    marginLeft: '5px',
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </HeaderLayout>
    );
}
