import './PdfTemplateDetails.scss';
import { CoreBackButton, CoreButton, CoreList, CoreTitle } from '@packages';
import { useAppDispatch } from '@store/hooks';
import { useEffect, useState } from 'react';
import { getPdfTemplate } from '@store/slices/admin/adminTemplateSlice';
import { useParams } from 'react-router-dom';
import { getTemplateInformation } from '@utils/helper';
import { useTranslation } from 'react-i18next';
import PdfView from '@components/file-view/PdfView';
import { fileStorageAPI } from '@service/file-storage.service';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';

type CoreList = { key: string; value: string }[];

const PdfTemplateDetails = () => {
    const { t } = useTranslation();
    const { TemplateId } = useParams();
    const dispatch = useAppDispatch();
    const [templateInfoData, setTemplateInfo] = useState<CoreList>([]);
    const [pdfUrl, setPDFUrl] = useState<string>();
    const [isPdfModalOpen, setPdfModalOpen] = useState<boolean>(false);
    const [isLoadingTemplate, setLoadingTemplate] = useState<boolean>(false);

    const { pdfTemplate } = useSelector((state: RootState) => state.adminTemplate);

    useEffect(() => {
        dispatch(getPdfTemplate({ TemplateId: TemplateId || '' }));
    }, []);

    useEffect(() => {
        if (pdfTemplate?.data) {
            const templateInfo = getTemplateInformation(pdfTemplate?.data, t);
            setTemplateInfo(templateInfo);
        }
    }, [pdfTemplate?.data]);

    const readPdfFile = async () => {
        if (!pdfTemplate?.data?.PreviewTemplatePdfId) {
            return;
        }
        if (pdfUrl) {
            setPdfModalOpen(true);
            return;
        }
        setLoadingTemplate(true);
        const response = await fileStorageAPI.getFileUrl(pdfTemplate?.data?.PreviewTemplatePdfId);
        setPDFUrl(response);
        setPdfModalOpen(true);
        setLoadingTemplate(false);
    };

    return (
        <div className='template-details-wrapper'>
            <CoreBackButton text='' />
            <div className='template-details-inner-wrapper'>
                <div className='template-details-header'>
                    <CoreTitle tag='h4' text='Template Details' />
                    <CoreButton
                        type='primary'
                        text='Preview PDF'
                        size='small'
                        onClick={readPdfFile}
                        loading={isLoadingTemplate}
                    />
                </div>
            </div>

            <div className='template-details-content'>
                <CoreList listData={templateInfoData} />
            </div>

            {pdfUrl && (
                <PdfView
                    url={pdfUrl}
                    isOpenModal={isPdfModalOpen}
                    closeModal={() => setPdfModalOpen(false)}
                />
            )}
        </div>
    );
};

export default PdfTemplateDetails;
