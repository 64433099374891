import { Steps } from 'antd';
import './UserInitiate.scss';
import { useRef, useState } from 'react';
import { CoreButton, CoreTitle } from '@packages';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import UserDetailsStep from '../../components/user-create-stepper/user-details-step/UserDetailsStep';
import RolePermissonStep from '../../components/user-create-stepper/role-permission-step/RolePermissionStep';
import ReviewStep from '../../components/user-create-stepper/review-step/ReviewStep';

const UserInitiate = () => {
    const [current, setCurrent] = useState(0);
    const navigate = useNavigate();
    const rolePermissionRef = useRef<{ triggerSubmit: () => void } | null>(null);

    const steps = [
        { title: 'User Details' },
        { title: 'Roles and Permission' },
        { title: 'Review and Create ' },
    ];

    const onStepChange = (newStep: number) => {
        setCurrent(newStep);
    };

    const goToNextStep = () => {
        setCurrent((prev) => prev + 1);
    };

    const goToPreviousStep = () => {
        if (current === 0) {
            navigate('/admin/iam/users');
        } else {
            setCurrent((prev) => prev - 1);
        }
    };

    const handleContinue = () => {
        console.log('hello bro');
        if (rolePermissionRef.current) {
            rolePermissionRef.current.triggerSubmit();
        }
    };

    return (
        <div className='user-initiate-wrapper'>
            <div className='user-stepper-header'>
                <div className='left-section'>
                    <CoreButton text='' icon={<LeftOutlined />} onClick={goToPreviousStep} />

                    <div className='left-titles'>
                        <CoreTitle text={steps[current].title || ''} tag='h6' />
                        <p>Step {current + 1} of 3</p>
                    </div>
                </div>

                <div className='right-section'>
                    {current === 1 && (
                        <CoreButton type='primary' text='Continue' onClick={handleContinue} />
                    )}

                    {current === 2 && (
                        <div className='step-three-actions'>
                            <CoreButton
                                text='Cancel'
                                onClick={() => navigate('/admin/iam/users')}
                            />
                            <CoreButton type='primary' text='Create' onClick={goToNextStep} />
                        </div>
                    )}
                </div>
            </div>

            <Steps
                current={current}
                onChange={onStepChange}
                size='small'
                items={steps}
                style={{ display: 'none' }}
            />

            <div>
                {current === 0 && <UserDetailsStep goToNextStep={goToNextStep} />}
                {current === 1 && (
                    <RolePermissonStep ref={rolePermissionRef} />
                )}
                {current === 2 && <ReviewStep />}
            </div>
        </div>
    );
};

export default UserInitiate;
