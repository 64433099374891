import { Menu } from 'antd';
import './MenuItems.scss';
import { useTranslation } from 'react-i18next';
import { useGetMenuSelectedKey } from '@hooks';
import { useAppSelector } from '@store/hooks';
import { userSelectRole } from '@store/slices/userSlice';
import { AdminMenuItems } from './Admin-menu-items';
import { ClientsMenuItems } from './Client-menu-items';

export const MenuItems = () => {
    const { t } = useTranslation();
    const { isAdmin } = useAppSelector(userSelectRole);
    const selectedKey = useGetMenuSelectedKey();
    const menuItems = isAdmin ? AdminMenuItems(t) : ClientsMenuItems(t);
    return (
        <div className='menu-wrapper'>
            <Menu
                mode='inline'
                defaultSelectedKeys={['']}
                defaultOpenKeys={[]}
                style={{ height: '100%' }}
                items={menuItems}
                selectedKeys={selectedKey}
            />
        </div>
    );
};

export default MenuItems;
