import InvoicePdfIcon from '@assets/svg/InvoicePdfIcon';
import { CoreTitle } from '@packages';
import './SingleAttachment.scss';

interface ISingleAttachmentProps {
    name: string;
    date: string;
}

export const SingleAttachment = ({ name, date }: ISingleAttachmentProps) => {
    return (
        <div className='single-attachment-wrapper'>
            <div className='single-attachment-header'>
                <div className='single-attachment-header-left'>
                    <InvoicePdfIcon />
                    <CoreTitle text={name} tag='h6' />
                </div>
                <CoreTitle text={date} tag='h6' />
            </div>

            <div className='single-attachment-actions'>
                <p className='text-btn'>Preview</p>
                <p className='text-btn'>Download</p>
            </div>
        </div>
    );
};
