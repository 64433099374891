import React from 'react';
import './PricingCalculatorEmptyContainer.scss';
import { CoreButton, CoreTitle } from '@packages';
import { PlusOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import pricingCalculatorIcon from '@assets/images/pricing-calculator-icon.png';

export interface IPricingCalculatorEmptyContainerPros {
    onPressEstimation: () => void;
}

export default function PricingCalculatorEmptyContainer({
    onPressEstimation,
}: IPricingCalculatorEmptyContainerPros) {
    return (
        <div className='pricing-calculator-container'>
            <CoreTitle tag='h1' text='Welcome to E-Invoicing Solutions pricing calculator' />
            <div className='pricing-calculator-component-container'>
                <div className='pricing-calculator-text-container'>
                    <CoreTitle tag='h4' text='Get started with your estimate' />
                    <p className='pricing-calculator-text'>
                        Add and configure products to get a cost estimate to share with your team.
                    </p>
                    <CoreButton
                        type='primary'
                        text='Add to estimate'
                        icon={<PlusOutlined />}
                        onClick={onPressEstimation}
                    />
                </div>

                <div className='pricing-calculator-image-container'>
                    <Image src={pricingCalculatorIcon} preview={false} />
                </div>
            </div>

            <div className='footer-text-section'>
                <hr className='pricing-calculator-separator-line' />
                <p className='pricing-calculator-footer-text'>
                    This tool creates cost estimates based on assumptions that you provide. These
                    estimates may not accurately reflect the final costs on your monthly Google
                    Cloud bill.
                </p>
            </div>
        </div>
    );
}
