import { IPricingCalculatorAmountList } from '@store/slices/paymentCalculator';
import { displayCurrency } from '@utils/displayCurrency';
import React from 'react';
import { capitalizeFirstLetter } from '@utils/capitilizeFirstLetter';

interface IPricingCalculatorListProps {
    pricingData: IPricingCalculatorAmountList;
}

export default function PricingCalculatorList({ pricingData }: IPricingCalculatorListProps) {
    return (
        <div>
            {pricingData.map((data) => (
                <div
                    key={data.key}
                    className='card-calculator-container'
                    style={{ marginBottom: 20 }}
                >
                    <span>{capitalizeFirstLetter(data?.key)}</span>
                    <span>{displayCurrency({ Amount: data.total, Code: 'SAR' })}</span>
                </div>
            ))}
        </div>
    );
}
