import { Popover } from 'antd';
import { CoreButton } from '@packages';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { FilterOutlined } from '@ant-design/icons';

interface IFilterDropDownProps {
    content: React.ReactNode;
}

// eslint-disable-next-line react/display-name
const FilterDropDown = forwardRef((props: IFilterDropDownProps, ref) => {
    const [openFilter, setOpenFilter] = useState<boolean>(false);

    const { content } = props;

    useImperativeHandle(ref, () => ({
        setHideFilter() {
            setOpenFilter(false);
        },
    }));

    return (
        <div className='filterDropDown'>
            <Popover
                placement='bottomRight'
                showArrow={false}
                content={content}
                open={openFilter}
                onOpenChange={(value) => setOpenFilter(value)}
                trigger='click'
            >
                <CoreButton type='basic' icon={<FilterOutlined />} />
            </Popover>
        </div>
    );
});

export default FilterDropDown;
