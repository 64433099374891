import dayjs from 'dayjs';

export function UtcToLocalDate(data?: string | null): string {
    if (!data) {
        return '';
    }

    const date = new Date(data);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Add 1 because months are zero-based
    const year = date.getUTCFullYear();
    const monthText = month < 10 ? '0' + month : month;
    const dayText = day < 10 ? '0' + day : day;
    return `${year}/${monthText}/${dayText}`;
}

export function UTCToShortDate(date?: string) {
    if (!date) return '';
    return dayjs(date).format('MMM D, YYYY');
}

export function getDashboardDate(key: string) {
    const currentDate = new Date();
    const previousDate = new Date(currentDate);

    if (key == '1d') {
        previousDate.setDate(previousDate.getDate());
    } else if (key === '7d') {
        previousDate.setDate(previousDate.getDate() - 7);
    } else if (key === '30d') {
        previousDate.setDate(previousDate.getDate() - 30);
    }

    const formattedCurrentDate = currentDate.toISOString().slice(0, 10);
    const formattedPreviousDate = previousDate.toISOString().slice(0, 10);

    return {
        currentDate: formattedCurrentDate,
        previousDate: formattedPreviousDate,
    };
}

const toDate = new Date();
const fromDate = new Date(toDate);
fromDate.setDate(fromDate.getDate() - 7);

export const initialCurrentDate = toDate.toISOString().slice(0, 10);
export const initialPreviousDate = fromDate.toISOString().slice(0, 10);

export function dayCountBetweenTwoDate(date: string) {
    const today = dayjs(); // Start date
    const futureDate = dayjs(date); // End date

    // Calculate the difference in days
    const dayCount = futureDate.diff(today, 'day');
    return dayCount;
}
