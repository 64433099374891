import React from 'react';
import './PricingCalculatorEstimationCard.scss';
import { Tooltip } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
    changeCalculatorItem,
    IPricingCalculatorAmount,
    removeCalculatorItem,
} from '@store/slices/paymentCalculator';
import { useAppDispatch } from '@store/hooks';
import { capitalizeFirstLetter } from '@utils/capitilizeFirstLetter';
import { displayCurrency } from '@utils/displayCurrency';
interface IPricingCalculatorEstimationCardPros {
    pricingAmount: IPricingCalculatorAmount;
}
export default function PricingCalculatorEstimationCard({
    pricingAmount,
}: IPricingCalculatorEstimationCardPros) {
    const { value, key: type, total } = pricingAmount;
    const dispatch = useAppDispatch();
    const onDecrease = () => {
        dispatch(changeCalculatorItem({ count: value - 1, name: type }));
    };
    const onIncrease = () => {
        dispatch(changeCalculatorItem({ count: value + 1, name: type }));
    };

    const onRemoveItem = () => {
        dispatch(removeCalculatorItem(type));
    };
    return (
        <div className='invoice-card'>
            <div className='header'>
                <h3>{capitalizeFirstLetter(type)}</h3>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h3 className='price'>
                        {displayCurrency({ Amount: total, Code: 'SAR' })} / Month
                    </h3>
                    <div style={{ marginLeft: 20 }} onClick={onRemoveItem} aria-hidden='true'>
                        <DeleteOutlined className='delete-icon' />
                    </div>
                </div>
            </div>

            <div className='estimation-card-content'>
                <div className='estimation-card-label-text'>
                    Number of {type}*
                    <Tooltip className='generic-tooltip'>
                        <ExclamationCircleOutlined />
                    </Tooltip>
                </div>

                <div className='estimation-card-input-control'>
                    <div className='estimation-card-input-container'>
                        <button onClick={onDecrease} disabled={value <= 0}>
                            -
                        </button>
                        <input type='text' value={value} readOnly />
                        <button onClick={onIncrease}>+</button>
                    </div>
                    <div className='estimation-card-billing-cycle'>
                        per month
                        <span className='estimation-card-dropdown-icon'>▼</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
