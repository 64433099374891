import React, { useState } from 'react';
import './BillingCard.scss';
import BillingActionModal from '../billing-action-modal/BillingActionModal';
import { Button, message } from 'antd';
import { BillingAddressAPI } from '@service/billingAddress.service';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '@store/hooks';
import { getBillingAddresses } from '@store/slices/billingAddressSlice';
import { getSubscription } from '@store/slices/subscribeSlice';
import BillingFormModal from '../billing-form-modal/BillingFormModal';

interface IBillingCardProps {
    addressName: string;
    addressDetails: string;
    addressId: string;
    isDefault: boolean;
}

const BillingCard = ({ addressName, addressDetails, addressId, isDefault }: IBillingCardProps) => {
    const [isDeleteModalVisible, setDeleteIsModalVisible] = useState<boolean>(false);
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState<boolean>(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const handleDelateConfirm = async () => {
        setIsLoading(true);
        await BillingAddressAPI.deleteBillingAddress({ BillingAddressId: addressId })
            .then((result) => {
                message.success('Billing address successfully deleted');
                dispatch(getBillingAddresses());
            })
            .catch((err) => {
                message.error(err?.data?.Message || 'Billing address delete denied');
            })
            .finally(() => {
                setIsLoading(false);
            });
        setDeleteIsModalVisible(false);
    };

    const handleUpdateConfirm = async () => {
        setIsLoading(true);
        await BillingAddressAPI.setAsDefault({ BillingAddressId: addressId })
            .then(unwrapResult)
            .then((result) => {
                message.success('Billing address successfully set as default');
                dispatch(getSubscription());
                dispatch(getBillingAddresses());
            })
            .catch((err) => {
                message.error(err?.data?.Message || 'Billing address set as default denied');
            })
            .finally(() => {
                setIsLoading(false);
            });
        setIsUpdateModalVisible(false);
    };

    return (
        <div className='billing-card-wrapper round-box-shadow' key={addressId}>
            <div className='billing-card-info'>
                <p className='address-name'>{addressName}</p>
                <p className='address-details'>{addressDetails}</p>
                <div className='billing-card-btn'>
                    {isDefault ? (
                        <Button size='small' type='link' className='set-default-button'>
                            Default
                        </Button>
                    ) : (
                        <Button
                            size='small'
                            type='link'
                            className='set-default-button'
                            onClick={() => setIsUpdateModalVisible(true)}
                        >
                            Set as Default
                        </Button>
                    )}
                    <Button
                        size='small'
                        type='link'
                        className='default-button'
                        onClick={() => setIsEditModalVisible(true)}
                    >
                        Edit
                    </Button>
                </div>
            </div>
            <div className='billing-card-actions'>
                <Button
                    danger
                    size='small'
                    type='link'
                    className='remove-btn'
                    onClick={() => setDeleteIsModalVisible(true)}
                >
                    Remove
                </Button>
            </div>

            {isDeleteModalVisible && (
                <BillingActionModal
                    title='Are you sure you want to delete this address?'
                    subTitle='This address will be deleted.'
                    isVisible={isDeleteModalVisible}
                    onClose={() => setDeleteIsModalVisible(false)}
                    onConfirm={handleDelateConfirm}
                    actionType='Delete'
                    addressName={addressName}
                    addressDetails={addressDetails}
                    isLoading={isLoading}
                />
            )}

            {isUpdateModalVisible && (
                <BillingActionModal
                    title='Update default card'
                    subTitle='This address will be used by default for billing.'
                    isVisible={isUpdateModalVisible}
                    onClose={() => setIsUpdateModalVisible(false)}
                    onConfirm={handleUpdateConfirm}
                    actionType='Update'
                    addressName={addressName}
                    addressDetails={addressDetails}
                    isLoading={isLoading}
                />
            )}

            {isEditModalVisible && (
                <BillingFormModal
                    isVisible={isEditModalVisible}
                    onClose={() => setIsEditModalVisible(false)}
                    onConfirm={() => setIsEditModalVisible(false)}
                    addressId={addressId}
                />
            )}
        </div>
    );
};

export default BillingCard;
