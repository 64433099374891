import { MenuFoldOutlined } from '@ant-design/icons';
import { useGetMenuSelectedKey } from '@hooks';
import { Drawer, Image, Layout, Menu, MenuProps } from 'antd';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.scss';
import LogoWithNameSvg from '../../svg/logo-with-name.svg';
import HomeLanguageMenu from '../home-language-menu/HomeLanguageMenu';
import { CoreButton } from '@packages';

const { Header } = Layout;

const Navbar = () => {
    type MenuItem = Required<MenuProps>['items'][number];
    const selectedKey = useGetMenuSelectedKey();

    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
            type,
        } as MenuItem;
    }

    const items: MenuItem[] = [
        getItem(
            <Link to='/Products' className='nav-items'>
                Products
            </Link>,
            'products',
        ),
        getItem(
            <Link to='/industries' className='nav-items'>
                Industries
            </Link>,
            'industries',
        ),
        getItem(
            <Link to='/resources' className='nav-items'>
                Resources
            </Link>,
            'resources',
        ),
        getItem(
            <Link to='/about-us' className='nav-items'>
                About Us
            </Link>,
            'about-us',
        ),
    ];

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const goToHome = () => {
        navigate('/');
    };

    return (
        <div className='navbar-wrapper'>
            <Layout>
                <Header
                    style={{
                        background: 'transparent',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        padding: '0',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <div className='navbar-inner-wrapper'>
                        <div className='logo-svg' onClick={goToHome}>
                            <Image
                                className=''
                                src={LogoWithNameSvg}
                                alt='main logo'
                                preview={false}
                            />
                        </div>

                        <Menu
                            mode='horizontal'
                            defaultSelectedKeys={['']}
                            defaultOpenKeys={[]}
                            items={items}
                            selectedKeys={selectedKey}
                            className='home-menu-wrapper'
                            style={{
                                background: '#FAFAFA',
                            }}
                        />

                        <div className='home-nav-actions'>
                            <Link to='/login'>
                                <CoreButton antType='text' text='Login' />
                            </Link>
                            <HomeLanguageMenu />
                        </div>

                        <MenuFoldOutlined className='home-drawer-button' onClick={showDrawer} />

                        <Drawer
                            placement={'right'}
                            closable={true}
                            onClose={onClose}
                            open={open}
                            key={'right'}
                            className='home-drawer-wrapper'
                        >
                            <HomeLanguageMenu />
                            <Menu
                                mode='vertical'
                                defaultSelectedKeys={['1']}
                                defaultOpenKeys={['sub1']}
                                items={items}
                                className=''
                                style={{
                                    background: '#FAFAFA',
                                }}
                            />
                            <Link to='/login'>
                                <CoreButton type='primary' text='Login' size='small' />
                            </Link>
                        </Drawer>
                    </div>
                </Header>
            </Layout>
        </div>
    );
};
export default Navbar;
