import { useRef, useState } from 'react';
import { Button, Col, Dropdown, MenuProps, Row, Select, Tag } from 'antd';
import './InvoiceList.scss';

import { useNavigate } from 'react-router-dom';
import {
    GET_ALL_INVOICE_TYPES,
    GET_INVOICE_DATA_INFO,
    GET_INVOICE_TYPE_CODE_BY_NAME,
} from '@utils/InvoiceUtils';
import { EInvoiceType, IInvoicePayload } from '@models/invoice.model';
import { useTranslation } from 'react-i18next';
import { CoreButton, CoreSearchInput } from '@packages';
import { ConfirmationModal, FilterDropDown } from '@components';
import { find, identity, isEmpty, omit, pickBy } from 'lodash';
import EinvoiceFilterInputs from '../../components/e-invoice-filter-inputs/EinvoiceFilterInputs';
import { useAppSelector } from '@store/hooks';
import { selectInvoices } from '@store/slices/einvoiceSlice';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { UtcToLocalDate } from '@utils/date';
import { splitCamelCase } from '@utils/splitCamelCase';
import { EllipsisOutlined } from '@ant-design/icons';
import InvoiceListTable from '../../components/invoice-list-table/InvoiceListTable';

interface IInvoiceListParams {
    isHideCreateButton?: boolean;
}

const InvoiceList = ({ isHideCreateButton = false }: IInvoiceListParams) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [filterData, setFilterData] = useState<Partial<IInvoicePayload>>();
    const [invoiceSelectType, setInvoiceSelectType] = useState('All');

    const myFilterRef = useRef<any>();
    const handleFilterSubmit = (data: IInvoicePayload) => {
        setFilterData((pre) => ({ ...pre, ...data }));
        myFilterRef?.current?.setHideFilter();
    };

    const { totalCount } = useAppSelector(selectInvoices);
    const { subscription } = useSelector((state: RootState) => state.subscribe);
    const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

    const clearAllFilters = () => {
        setFilterData({} as IInvoicePayload);
        setInvoiceSelectType('All');
    };

    const clearFilter = (item: string | string[]) => {
        if (!filterData) return;
        const removeFilter = omit(filterData, item) as any;
        setFilterData(removeFilter);
    };
    const filterNullFilterData = pickBy(filterData, identity);
    const removeInvoiceTypeFormFilter = omit(filterNullFilterData, [
        'InvoiceType',
        'InvoiceTypeCode',
        'Search',
    ]);
    const onInvoiceTypeChange = (value: string) => {
        setInvoiceSelectType(value);
        const getInvoiceTypeInfo = GET_INVOICE_TYPE_CODE_BY_NAME(value as EInvoiceType);
        let params: Partial<IInvoicePayload> = { InvoiceType: '', InvoiceTypeCode: '' };
        if (getInvoiceTypeInfo) {
            params = {
                InvoiceType: getInvoiceTypeInfo.InvoiceType,
                InvoiceTypeCode: getInvoiceTypeInfo.InvoiceTypeCode,
            };
        }
        setFilterData((pre) => ({ ...pre, ...params }));
    };

    const getInvoiceTypeName = () => {
        if (!filterNullFilterData || !filterNullFilterData?.InvoiceType) return <></>;
        const invoiceTypeInfo = {
            InvoiceType: filterNullFilterData?.InvoiceType,
            InvoiceTypeCode: filterNullFilterData?.InvoiceTypeCode,
        };
        const invoiceTypeName = find(GET_INVOICE_DATA_INFO, invoiceTypeInfo) as any;
        if (!invoiceTypeName) return <></>;
        const invoiceTypeDisplayName = find(GET_ALL_INVOICE_TYPES, {
            value: invoiceTypeName?.name,
        });
        return (
            <Tag
                key={'InvoiceType'}
                className='margin-bottom-10'
                closable
                onClose={() => {
                    clearFilter(['InvoiceType', 'InvoiceTypeCode']);
                    setInvoiceSelectType('All');
                }}
            >
                <span>Invoice Type</span>:{' '}
                <span className='filter-value'>{invoiceTypeDisplayName?.label}</span>
            </Tag>
        );
    };

    const handleGoBack = () => {
        setIsUpgradeModalOpen(!isUpgradeModalOpen);
    };

    const handleCreateNew = () => {
        if (subscription?.TierName === '') {
            navigate('/e-invoices/create');
        } else if (totalCount >= subscription?.Remaining?.Invoices && !subscription?.IsPayAsYouGo) {
            setIsUpgradeModalOpen(!isUpgradeModalOpen);
        } else {
            navigate('/e-invoices/create');
        }
    };

    const items: MenuProps['items'] = [
        {
            key: 'Upload',
            label: (
                <span onClick={() => navigate('/upload-invoices')} aria-hidden='true'>
                    Imported CSV
                </span>
            ),
        },
        {
            key: 'CsvTemplates',
            label: (
                <span onClick={() => navigate('/upload-invoices/list')} aria-hidden='true'>
                    CSV Templates
                </span>
            ),
        },
    ];

    return (
        <div className='invoice-list-wrapper'>
            <div className='invoice-list-action'>
                <div className='filter-header'>
                    <CoreSearchInput
                        placeholder={t('SEARCH')}
                        handleInputChange={(value) =>
                            setFilterData((pre) => ({ ...pre, Search: value }))
                        }
                    />
                    <FilterDropDown
                        content={
                            <EinvoiceFilterInputs
                                handleFilterSubmit={handleFilterSubmit}
                                defaultValues={filterData}
                                onInvoiceTypeChange={onInvoiceTypeChange}
                                invoiceSelectType={invoiceSelectType}
                            />
                        }
                        ref={myFilterRef}
                    ></FilterDropDown>

                    {!isHideCreateButton && (
                        <>
                            <CoreButton
                                className='submit-button'
                                type='primary'
                                text={t('CREATE_NEW')}
                                isFullWidth={true}
                                onClick={handleCreateNew}
                            />

                            <Dropdown menu={{ items }} trigger={['click']}>
                                <Button
                                    style={{ marginLeft: 15 }}
                                    type='default'
                                    icon={<EllipsisOutlined />}
                                />
                            </Dropdown>
                        </>
                    )}
                </div>
            </div>
            <div>
                {!isEmpty(filterNullFilterData) && (
                    <Row className='filter-wrapper' justify='space-between'>
                        <Col span={20}>
                            <>{getInvoiceTypeName()}</>
                            <>
                                {Object.entries(removeInvoiceTypeFormFilter).map(([key, value]) => (
                                    <Tag
                                        key={key}
                                        className='margin-bottom-10'
                                        closable
                                        onClose={() => clearFilter(key)}
                                    >
                                        <span>{t(splitCamelCase(key))}</span>:{' '}
                                        {['CreatedAt', 'UpdatedAt', 'ActualDeliveryDate'].includes(
                                            key,
                                        ) ? (
                                            <span className='filter-value'>
                                                {UtcToLocalDate(value as string)}
                                            </span>
                                        ) : (
                                            <span className='filter-value'>{value}</span>
                                        )}
                                    </Tag>
                                ))}
                            </>
                        </Col>
                        <Col span={4}>
                            <Row justify='end'>
                                <div>
                                    <CoreButton
                                        type='basic'
                                        isFullWidth={false}
                                        text={t('CLEAR_ALL')}
                                        onClick={clearAllFilters}
                                    />
                                </div>
                            </Row>
                        </Col>
                    </Row>
                )}
            </div>
            <div className='invoice-list-table'>
                <InvoiceListTable filterData={filterData} />
            </div>
            <ConfirmationModal
                title='Reached invoice limit.'
                description='To continue creating e-Invoices, please upgrade your subscription.'
                isDeleteModalOpen={isUpgradeModalOpen}
                handleDeleteOk={() => navigate('/subscribe')}
                handleDeleteCancel={handleGoBack}
                buttonType={'Upgrade'}
            />
        </div>
    );
};

export default InvoiceList;
