import { DEFAULT_EMPTY_VALUE, DEFAULT_TABLE_SIZE } from '@config/constants';
import { IOrganization } from '@models/organization.model';
import { CoreStatus } from '@packages';
import { useAppDispatch } from '@store/hooks';
import { getOrganizations } from '@store/slices/organizationSlice';
import { RootState } from '@store/store';
import { UtcToLocalDate } from '@utils/date';
import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface IOrganizationTableData {
    key: React.Key;
    OrganizationId: string;
    LegalRegistrationName: string;
    LegalRegistrationNameEn: string;
    VATRegistrationNumber: string;
    CompanyRegistrationNumber: string;
    CreatedAt: string;
    IsActive: boolean;
    IsCompleted: boolean;
}

const Organizations = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const [organizationList, setOrganizationList] = useState<IOrganizationTableData[]>([]);

    const { isLoading, organizations } = useSelector((state: RootState) => state.organization);

    useEffect(() => {
        dispatch(getOrganizations());
    }, [dispatch]);

    useEffect(() => {
        formatOrganizationListData();
    }, [organizations]);

    const formatOrganizationListData = () => {
        const data: IOrganizationTableData[] = [];
        organizations.forEach((organization: IOrganization) => {
            data.push({
                key: organization.OrganizationId || '',
                OrganizationId: organization.OrganizationId,
                LegalRegistrationName: organization.LegalRegistrationName,
                LegalRegistrationNameEn: organization.LegalRegistrationNameEn,
                VATRegistrationNumber: organization.VatRegistrationNumber,
                CompanyRegistrationNumber: organization.CompanyRegistrationNumber,
                CreatedAt: organization?.CreatedAt || '',
                IsActive: organization?.IsActive,
                IsCompleted: organization?.Workflow?.Completed,
            });
        });
        setOrganizationList(data);
    };

    const columns: ColumnsType<IOrganizationTableData> = [
        {
            title: t('LEGAL_REGISTRATION_NAME'),
            dataIndex: i18n.language === 'sa' ? 'LegalRegistrationName' : 'LegalRegistrationNameEn',
            render: (text, data) => (
                <Tooltip
                    title={
                        i18n.language === 'sa'
                            ? data?.LegalRegistrationNameEn
                            : data?.LegalRegistrationName
                    }
                >
                    <span>{text || DEFAULT_EMPTY_VALUE}</span>
                </Tooltip>
            ),
        },
        {
            title: t('VAT_REGISTRATION_NUMBER'),
            dataIndex: 'VATRegistrationNumber',
        },
        {
            title: t('COMPANY_REGISTRATION_NUMBER'),
            dataIndex: 'CompanyRegistrationNumber',
        },
        {
            title: t('CREATED_DATE'),
            dataIndex: 'CreatedAt',
            render: (text: string) => <span>{UtcToLocalDate(text)}</span>,
        },
        {
            title: t('ACTIVE_STATUS'),
            dataIndex: 'IsActive',
            render: (IsActive: string) => (
                <CoreStatus
                    type={IsActive ? 'success' : 'inactive'}
                    status={IsActive ? t('ACTIVE') : t('INACTIVE')}
                />
            ),
        },
        {
            title: t('COMPLETE_STATUS'),
            dataIndex: 'IsCompleted',
            render: (IsCompleted: boolean) => (
                <CoreStatus
                    type={IsCompleted ? 'success' : 'inactive'}
                    status={IsCompleted ? t('COMPLETE') : t('INCOMPLETE')}
                />
            ),
        },
    ];

    const handleRowClick = (record: IOrganizationTableData) => {
        console.log(record);
    };

    return (
        <div>
            <Table
                loading={isLoading}
                columns={columns}
                dataSource={organizationList}
                rowClassName='cursor-pointer'
                onRow={(record) => {
                    return {
                        onClick: () => handleRowClick(record),
                    };
                }}
                scroll={{ x: 600 }}
                pagination={{
                    pageSize: DEFAULT_TABLE_SIZE,
                    total: organizationList?.length ?? 0,
                    showSizeChanger: false,
                    showQuickJumper: false,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} ${t('OF')} ${total} ${t('ITEMS')}`,
                }}
            />
        </div>
    );
};

export default Organizations;
