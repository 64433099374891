import { LayoutWrapper } from '@components';
import { ArchivesList } from '../client-features/archives';
import TemplateHeader from '../client-features/template-generator/template-header/TemplateHeader';
import TemplateTotal from '../client-features/template-generator/template-total/TemplateTotal';
import TemplateFooter from '../client-features/template-generator/template-footer/TemplateFooter';
import TemplateProperties from '../client-features/template-generator/template-properties/TemplateProperties';
import TemplateItemTable from '../client-features/template-generator/template-item-table/TemplateItemTable';
import TemplateList from '../client-features/templates/template-list/TemplateList';
import { BillingHome } from '../client-features/billing';
import { Profile } from '../client-features/profile';

import PdfTemplateCreate from '@admin-features/template/pdf/template-create/PdfTemplateCreate';
import PdfTemplateUpdate from '@admin-features/template/pdf/template-update/PdfTemplateUpdate';
import PdfTemplateDetails from '@admin-features/template/pdf/template-details/PdfTemplateDetails';
import UserList from '../admin-features/user-management/user/pages/user-list/UserList';
import { Route, Routes } from 'react-router-dom';
import UserDetails from '../admin-features/user-management/user/pages/user-details/UserDetails';
import UserInitiate from '../admin-features/user-management/user/pages/user-initiate/UserInitiate';
import Tiers from '@admin-features/subscription/Tiers';
import Coupons from '@admin-features/subscription/Coupons';
import ResourceGroupList from '@admin-features/resource-group/pages/list/resource-group-list';
import EmailTemplateList from '@admin-features/template/email/pages/template-list/EmailTemplateList';
import PdfTemplateList from '@admin-features/template/pdf/template-list/PdfTemplateList';
import ResourceGroupCreate from '@admin-features/resource-group/pages/create/resource-group-create';
import EmailTemplateCreate from '@admin-features/template/email/pages/template-create/EmailTemplateCreate';
import EmailTemplateUpdate from '@admin-features/template/email/pages/template-update/EmailTemplateUpdate';
import SubscriptionDetails from '@admin-features/subscription/page/subscription-details/SubscriptionDetails';
import InvoiceDetails from '@admin-features/subscription/page/invoice-details/InvoiceDetails';
import SubscriptionConfiguration from '@admin-features/subscription/page/subscription-configuration/SubscriptionConfiguration';
import EmailTemplateDetails from '@admin-features/template/email/pages/template-details/EmailTemplateDetails';
import Subscription from '@admin-features/subscription/page/subscription-view/Subscription';
import SubscriptionConfigurationUpdate from '@admin-features/subscription/page/subscription-configuration-update/SubscriptionConfigurationUpdate';

export default function AdminRouter() {
    return (
        <Routes>
            <Route path='' element={<LayoutWrapper />}>
                <Route path='profile' element={<Profile />}></Route>
                <Route path='iam/users' element={<UserList />}></Route>
                <Route path='iam/user/create' element={<UserInitiate />}></Route>
                <Route path='iam/user/:email' element={<UserDetails />}></Route>
                <Route path='archives' element={<ArchivesList />}></Route>

                <Route path='/pdf-templates' element={<TemplateList />}></Route>
                <Route path='/template-header' element={<TemplateHeader />}></Route>
                <Route path='/template-total' element={<TemplateTotal />}></Route>
                <Route path='/template-properties' element={<TemplateProperties />}></Route>
                <Route path='/template-footer' element={<TemplateFooter />}></Route>
                <Route path='/template-item-table' element={<TemplateItemTable />}></Route>
                <Route path='/billing' element={<BillingHome />}></Route>
                <Route path='/resource-group' element={<ResourceGroupList />}></Route>
                <Route path='/resource-group/create' element={<ResourceGroupCreate />}></Route>

                <Route path='/subscription' element={<Subscription />}></Route>
                <Route
                    path='/subscription/:subscriptionId'
                    element={<SubscriptionDetails />}
                ></Route>
                <Route
                    path='/subscription/:subscriptionId/invoice/:invoiceId'
                    element={<InvoiceDetails />}
                ></Route>
                <Route path='/subscription/tiers' element={<Tiers />}></Route>
                <Route path='/subscription/coupons' element={<Coupons />}></Route>
                <Route
                    path='/subscription/configuration'
                    element={<SubscriptionConfiguration />}
                ></Route>
                <Route
                    path='/subscription/configuration/update'
                    element={<SubscriptionConfigurationUpdate />}
                ></Route>

                <Route path='/template/email' element={<EmailTemplateList />}></Route>
                <Route path='/template/email/create' element={<EmailTemplateCreate />}></Route>
                <Route
                    path='/template/email/update/:TemplateId'
                    element={<EmailTemplateUpdate />}
                ></Route>
                <Route
                    path='/template/email/:TemplateId'
                    element={<EmailTemplateDetails />}
                ></Route>

                <Route path='/template/pdf' element={<PdfTemplateList />}></Route>
                <Route path='/template/pdf/create' element={<PdfTemplateCreate />}></Route>
                <Route path='/template/pdf/:TemplateId' element={<PdfTemplateDetails />}></Route>
                <Route
                    path='/template/pdf/update/:TemplateId'
                    element={<PdfTemplateUpdate />}
                ></Route>
            </Route>
        </Routes>
    );
}
