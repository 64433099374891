import FlagIcon from '@assets/svg/FlagIcon';
import { UTCToShortDate } from '@utils/date';
import { Timeline } from 'antd';
import './LogActivity.scss';
import { CoreTitle } from '@packages';

const LogActivity = () => {
    const formatTimelineItems = (data: any[]) =>
        data.map((item) => ({
            dot: <FlagIcon />,
            children: (
                <div className='single-log-activity'>
                    <p>{item.title}</p>
                    <p>{UTCToShortDate(item.time)}</p>
                </div>
            ),
        }));

    const latestUpdates = [
        {
            key: '1',
            time: '2024-10-15T00:00:00Z',
            title: 'Invoice was sent to',
        },
        {
            key: '2',
            time: '2024-10-04T00:00:00Z',
            title: 'PDF Generated',
        },
        {
            key: '3',
            time: '2024-08-14T00:00:00Z',
            title: 'XML Generated & Signed',
        },
    ];

    return (
        <div className='log-activity-wrapper'>
            <CoreTitle text='Log Activity' tag='h5' />
            <Timeline items={formatTimelineItems(latestUpdates)} />
        </div>
    );
};

export default LogActivity;
