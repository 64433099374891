import React, { useEffect } from 'react';
import NavigationBar from '../layout-wrapper/navigation-bar/NavigationBar';
import './HeaderLayout.scss';
import { CoreLogo } from '@packages';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@store/hooks';
import { getSubscription } from '@store/slices/subscribeSlice';

interface IHeaderLayoutWrapper {
    children: React.ReactNode;
}

const HeaderLayout = (props: IHeaderLayoutWrapper) => {
    const { children } = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getSubscription());
    });

    return (
        <>
            <div className='nav-layout-wrapper'>
                <div
                    className='logo-wrapper'
                    onClick={() => navigate('/dashboard')}
                    aria-hidden='true'
                >
                    <CoreLogo size={'medium'} />
                </div>
                <NavigationBar />
            </div>
            <div className='header-new-layout-wrapper'>
                <div className='header-new-layout-inner-wrapper'>{children}</div>
            </div>
        </>
    );
};

export default HeaderLayout;
