import GenericInputMaxLimit from '@components/common/GenericInputMaxLimit';
import InlineGenericInput from '@components/common/InlineGenericInput';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import InlineGenericTextArea from '@components/common/InlineGenericTextArea';
import { RegexValidators } from '@config/regex-validator-constant';
import { IAddBillingAddress } from '@models/billing.model';
import { ICountry } from '@models/generic.model';
import { CoreButton, CoreTitle } from '@packages';
import { unwrapResult } from '@reduxjs/toolkit';
import { BillingAddressAPI } from '@service/billingAddress.service';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
    getBillingAddress,
    getBillingAddresses,
    singleBillingAddress,
} from '@store/slices/billingAddressSlice';
import { Col, message, Modal, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import countryList from 'react-select-country-list';

interface IBillingFormModalProps {
    isVisible: boolean;
    onClose: () => void;
    onConfirm: () => void;
    addressId?: string;
}

const BillingFormModal = ({
    isVisible,
    onClose,
    onConfirm,
    addressId = undefined,
}: IBillingFormModalProps) => {
    const countryOptions: ICountry[] = useMemo(() => countryList().getData(), []);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const billingData = useAppSelector(singleBillingAddress);

    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        formState: { errors },
        reset,
    } = useForm<IAddBillingAddress>({ mode: 'onTouched' });

    useEffect(() => {
        if (addressId) {
            dispatch(getBillingAddress({ BillingAddressId: addressId }));
        }
    }, [addressId]);

    useEffect(() => {
        if (addressId && billingData) {
            updateForm();
        }
    }, [addressId, billingData]);

    const updateForm = () => {
        setFormValue('AddressTitle', billingData?.AddressTitle || '');
        setFormValue('Name', billingData?.Name || '');
        setFormValue('Surname', billingData?.Surname || '');
        setFormValue('Phone', billingData?.Phone || '');
        setFormValue('Country', billingData?.Country || '');
        setFormValue('City', billingData?.City || '');
        setFormValue('Address', billingData?.Address || '');
    };

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        if (addressId) {
            data.BillingAddressId = addressId;
            await BillingAddressAPI.updateBillingAddress(data)
                .then(unwrapResult)
                .then((result) => {
                    message.success('Billing address successfully updated');
                    dispatch(getBillingAddresses());
                })
                .catch((err) => {
                    message.error(err?.data?.Message || 'Billing address update denied');
                });
        } else {
            await BillingAddressAPI.addBillingAddress(data)
                .then(unwrapResult)
                .then((result) => {
                    message.success('Billing address successfully added');
                    dispatch(getBillingAddresses());
                })
                .catch((err) => {
                    message.error(err?.data?.Message || 'Billing address addition denied');
                });
        }
        setIsLoading(false);
        reset();
        onConfirm();
    };

    const closeModal = () => {
        reset();
        onClose();
    };

    return (
        <Modal
            className='billing-form-modal-wrapper'
            open={isVisible}
            onCancel={closeModal}
            closable={true}
            footer={[]}
        >
            <div className='billing-from-wrapper'>
                <CoreTitle text={addressId ? 'Update Address' : 'Add New Address'} tag='h4' />
                <form onSubmit={handleSubmit(onSubmit)} className='margin-top-16'>
                    <InlineGenericInput
                        inputCol={24}
                        levelCol={24}
                        control={control}
                        errors={errors}
                        dataKey='AddressTitle'
                        level='Address Title'
                        errorMessageSize='small'
                        marginBottom={10}
                        rules={{
                            required: true,
                        }}
                    />

                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <InlineGenericInput
                                inputCol={24}
                                levelCol={24}
                                control={control}
                                errors={errors}
                                dataKey='Name'
                                level='Name'
                                errorMessageSize='small'
                                marginBottom={10}
                                rules={{
                                    required: true,
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <InlineGenericInput
                                inputCol={24}
                                levelCol={24}
                                control={control}
                                errors={errors}
                                dataKey='Surname'
                                level='Surname'
                                errorMessageSize='small'
                                marginBottom={10}
                                rules={{
                                    required: true,
                                }}
                            />
                        </Col>
                    </Row>

                    <InlineGenericInput
                        inputCol={24}
                        levelCol={24}
                        control={control}
                        errors={errors}
                        dataKey='Phone'
                        level='Phone Number'
                        type='phoneNumber'
                        hideError={true}
                        marginBottom={10}
                        errorMessageSize='small'
                        rules={{
                            required: true,
                        }}
                    >
                        <div style={{ position: 'absolute', fontSize: 12 }}>
                            {errors.Phone?.type === 'required' && (
                                <span className='error-message'>
                                    {t('PHONE_NUMBER_IS_REQUIRED')}
                                </span>
                            )}
                            {errors.Phone?.type === 'pattern' && (
                                <span className='error-message'>{t('PHONE_NUMBER_INVALID')}</span>
                            )}
                        </div>
                    </InlineGenericInput>

                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <InlineGenericSelect
                                inputCol={24}
                                levelCol={24}
                                control={control}
                                errors={errors}
                                dataKey='Country'
                                level='Country'
                                placeHolder='Select Country'
                                errorMessageSize='small'
                                marginBottom={0}
                                setFormValue={setFormValue}
                                options={countryOptions}
                                allowClear
                                rules={{
                                    required: true,
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <GenericInputMaxLimit
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='City'
                                level={t('CITY_ENGLISH')}
                                marginBottom={20}
                                maxLimit={127}
                                type='english'
                                rules={{
                                    required: true,
                                    maxLength: 127,
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                    </Row>

                    <InlineGenericTextArea
                        inputCol={24}
                        levelCol={24}
                        control={control}
                        errors={errors}
                        dataKey='Address'
                        level='Address'
                        marginBottom={30}
                        minRows={2}
                        rules={{
                            required: true,
                        }}
                    />

                    <CoreButton
                        type='primary'
                        htmlType='submit'
                        isFullWidth={true}
                        loading={isLoading}
                        text={addressId ? 'Update Address' : 'Add Address'}
                    />
                </form>
            </div>
        </Modal>
    );
};

export default BillingFormModal;
