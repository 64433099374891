import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { localStoreAPI } from '@service/local-storage.service';
import { DEFAULT_LANGUAGE, LANGUAGE_KEY } from '@config/constants';

const namespace = 'languages';
export interface ILanguageState {
    selection: string;
}

const initialState: ILanguageState = {
    selection: localStoreAPI.getItemSessionStore(LANGUAGE_KEY) || DEFAULT_LANGUAGE,
};

const appLanguageSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        updateLanguage: (state, action: { type: string; payload: string }) => {
            localStoreAPI.setItemSessionStore(LANGUAGE_KEY, action.payload);
            state.selection = action.payload;
        },
    },
});
export const { updateLanguage } = appLanguageSlice.actions;
export const selectAppLanguage = (state: RootState) => state.language;
export default appLanguageSlice.reducer;
