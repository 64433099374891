import { ISubscriptionInvoices } from '@models/subscription.model';
import { fileStorageAPI } from '@service/file-storage.service';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getSubscriptionInvoices } from '@store/slices/admin/subscriptionSlice';
import { RootState } from '@store/store';
import { GET_DEFAULT_CURRENCY_CODE } from '@utils/CurrencyCodeOptions';
import { UTCToShortDate } from '@utils/date';
import { displayCurrency } from '@utils/displayCurrency';
import { message, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TFunction } from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const Invoice = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { subscriptionId } = useParams();
    const { subscriptionInvoices } = useAppSelector((state: RootState) => state.adminSubscription);

    useEffect(() => {
        if (subscriptionId) {
            dispatch(
                getSubscriptionInvoices({
                    SubscriptionId: subscriptionId,
                    payload: { PageLimit: 10 },
                }),
            );
        }
    }, [subscriptionId, dispatch]);

    const columns = (t: TFunction<'translation', undefined>): ColumnsType<any> => [
        {
            title: t('Purchase'),
            dataIndex: 'CreatedAt',
            render: (text: string) => <span>{UTCToShortDate(text)}</span>,
        },
        {
            title: t('Payment Method'),
            dataIndex: 'PaymentMethod',
            render: (text: string) => {
                return <span>{text}</span>;
            },
        },
        {
            title: t('Amount'),
            dataIndex: 'PurchaseAmount',
            render: (amount: number, data: ISubscriptionInvoices) => (
                <span>
                    {displayCurrency({
                        Code: data.Currency || GET_DEFAULT_CURRENCY_CODE,
                        Amount: amount || 0,
                    })}
                </span>
            ),
        },
        {
            title: 'Used Credit',
            dataIndex: 'CreditUsed',
            render: (amount: string, data: ISubscriptionInvoices) => {
                return (
                    <span>
                        {displayCurrency({
                            Code: data.Currency || GET_DEFAULT_CURRENCY_CODE,
                            Amount: amount || 0,
                        })}
                    </span>
                );
            },
        },
        {
            title: 'Payment (PDF)',
            dataIndex: 'InvoiceFileId',
            render: (fileId: string) => (
                <span>
                    View:{' '}
                    <span
                        className='primary-color'
                        onClick={(event) => downloadInvoicePdf(event, fileId)}
                    >
                        PDF
                    </span>
                </span>
            ),
        },
    ];

    const downloadInvoicePdf = async (event: any, fileId: string) => {
        event.stopPropagation();
        try {
            const response = await fileStorageAPI.getFileUrl(fileId);
            window.open(response, '_blank');
        } catch (error) {
            message.error('File information not found ');
            throw error;
        }
    };

    const handleRowClick = (record: any) => {
        navigate(`/admin/subscription/${record?.SubscriptionId}/invoice/${record.ItemId}`);
    };

    return (
        <div>
            <Table
                columns={columns(t)}
                dataSource={subscriptionInvoices.data}
                rowClassName='cursor-pointer'
                rowKey={'ItemId'}
                onRow={(record) => {
                    return {
                        onClick: () => handleRowClick(record),
                    };
                }}
                pagination={{}}
            />
        </div>
    );
};

export default Invoice;
