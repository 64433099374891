import { useEffect } from 'react';
import { Col, Row, Tabs, TabsProps } from 'antd';
import { CoreBackButton, CoreTitle, CoreList } from '@packages';
import './DeviceDetails.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { UtcToLocalDate } from '@utils/date';
import { useTranslation } from 'react-i18next';
import { getDevice, selectDeviceInfo } from '@store/slices/deviceSlice';
import { DEFAULT_EMPTY_VALUE } from '@config/constants';

const DeviceDetails = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { deviceId = '' } = useParams();
    const { data: device } = useAppSelector(selectDeviceInfo);

    const { organizationId = '' } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (deviceId) {
            dispatch(getDevice(deviceId));
        }
    }, [deviceId]);

    const deviceInformationTableData = [
        {
            key: `${t('ORGANIZATION_ID')}:`,
            value: device?.OrganizationId || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('ORGANIZATION_NAME_ARABIC')}:`,
            value: device?.OrganizationLegalRegistrationName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('ORGANIZATION_NAME_ENGLISH')}:`,
            value: device?.OrganizationLegalRegistrationNameEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('ENTITY_ID')}:`,
            value: device?.EntityId || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('ENTITY_NAME_ARABIC')}:`,
            value: device?.EntityLegalRegistrationName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('ENTITY_NAME_ENGLISH')}:`,
            value: device?.EntityLegalRegistrationNameEn || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('COMMON_NAME')}:`,
            value: device?.CommonName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('BUSINESS_SECTOR')}:`,
            value: device?.Category || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('EMAIL')}:`,
            value: device?.Email || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('LOCATION')}:`,
            value: device?.Location || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('CREATED_DATE')}:`,
            value: UtcToLocalDate(device?.UpdatedAt) || DEFAULT_EMPTY_VALUE,
        },
    ];

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: `${t('DEVICE_INFORMATION')}:`,
            children: <CoreList listData={deviceInformationTableData} />,
        },
    ];

    return (
        <div className='container device-details'>
            <Row gutter={16}>
                <Col>
                    <CoreBackButton
                        text={''}
                        onClick={() => {
                            navigate(`/organizations/${organizationId}`, {
                                state: {
                                    currentTab: 'device',
                                    delay: true,
                                },
                            });
                        }}
                    />
                </Col>
                <Col>
                    <CoreTitle tag='h1' text={device?.CommonName} />
                    <div>
                        {t('CREATED_DATE')}: {UtcToLocalDate(device?.CreatedAt)} |{' '}
                        {t('LAST_UPDATED_DATE')}: {UtcToLocalDate(device?.UpdatedAt)}
                    </div>
                </Col>
            </Row>

            <div className='left-tab-wrapper'>
                <div className='round-box-shadow top-tab-wrapper'>
                    <Tabs defaultActiveKey='1' items={items} />
                </div>
            </div>
        </div>
    );
};

export default DeviceDetails;
