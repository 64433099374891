import './Subscribe.scss';
import { Tabs } from 'antd';
import { CoreTitle } from '@packages';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getPaymentMethods } from '@store/slices/paymentSlice';
import { useTranslation } from 'react-i18next';
import OverviewWrapper from '../../components/overview/overview-wrapper/OverviewWrapper';
import Invoice from '../../components/invoice/Invoice';
import BillingTab from '../../components/billing/billing-tab/BillingTab';
import PaymentDetails from '../../components/payment-details/PaymentDetails';
import { setActiveTabkey } from '@store/slices/subscriptionOverviewSlice';
import { tabType } from '@models/subscription.model';
import AlertsTab from '../../components/alerts/alerts-tab/AlertsTab';

export default function Subscribe() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const activeTabKey = useAppSelector((state: any) => state.subscriptionOverview.activeTabKey);

    const changeActiveTabKeys = (key: string) => {
        dispatch(setActiveTabkey(key as tabType));
    };

    const tabItems = [
        {
            label: 'Overview',
            key: 'overview',
            children: <OverviewWrapper />,
        },
        {
            label: 'Invoice',
            key: 'invoice',
            children: <Invoice />,
        },
        {
            label: 'Payment Details',
            key: 'payment',
            children: <PaymentDetails />,
        },
        {
            label: 'Billing',
            key: 'billing',
            children: <BillingTab />,
        },
        {
            label: 'Alerts',
            key: 'alerts',
            children: <AlertsTab />,
        },
    ];

    useEffect(() => {
        dispatch(getPaymentMethods());
    }, []);

    return (
        <div className='subscribe-wrapper'>
            <CoreTitle tag='h2' text={t('YOUR_SUBSCRPTION')} />
            <p className='subscribe-sub-title'>
                Keep your account more secure with following preferences.
            </p>
            <div className='subscribe-inner-wrapper'>
                <Tabs activeKey={activeTabKey} onChange={changeActiveTabKeys} items={tabItems} />
            </div>
        </div>
    );
}
