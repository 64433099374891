import { Navigate, Route, Routes } from 'react-router-dom';
import {
    OrganizationList,
    OrganizationDetails,
    OrganizationUpdate,
    EntityDetails,
    CsidGenerate,
    EntityCreate,
    CsidDetails,
    DeviceCreate,
    DeviceDetails,
    DeviceUpdate,
} from '../client-features/organization';
import { LayoutWrapper } from '@components';
import { CustomerCreate, CustomerDetails, CustomerList } from '../client-features/customer';
import { InventoryCreate, InventoryList, InventoryStepper } from '../client-features/inventory';
import NewInvoice from '../client-features/e-invoice/pages/new-invoice/NewInvoice';
import { InvoiceList } from '../client-features/e-invoice';
import Preference from '../client-features/preference/preference-tabs/PreferenceTabs';
import OrganizationEntityUpdate from '../client-features/organization/components/organization-entity-update/OrganizationEntityUpdate';
import {
    AddDevices,
    EinvoicePhase,
    OrganizationSetupCompleted,
    AddOrganization,
    AddEntity,
    OrganizationSetUpRoot,
    OrganizationInitiate,
} from '../client-features/organization-setup';
import { CreateTicket, MyTicket, SupportHome } from '../client-features/support';
import { SettingTabs, TwoFactorAuthenticationStepper } from '../client-features/setting';
import { Profile } from '../client-features/profile';
import {
    Subscribe,
    CancelAutoRenewal,
    PricingPlans,
    SubscribeSuccess,
    ManageSubscription,
    PlanComparison,
} from '../client-features/subscribe';
import {
    UploadInvoiceDetails,
    UploadInvoiceList,
    UploadInvoiceStepper,
    ConfirmUploadInvoice,
} from '../client-features/upload-invoices';
import { ApiServicesList, CreateService } from '../client-features/api-services';
import SubscribeInvoiceDetails from '../client-features/subscribe/pages/subscribe-invoice-details/SubscribeInvoiceDetails';
import { PricingCalculator } from '../client-features/pricing-calculator';
import Dashboard from '../client-features/dashboard/Dashboard';
import { WhatsNew } from '@client-features/whats-new';
import InvoiceNewDetails from '@client-features/e-invoice/pages/invoice-new-details/InvoiceNewDetails';

export default function ClientRouter() {
    return (
        <Routes>
            <Route path='/organization-setup/:organizationId' element={<OrganizationSetUpRoot />}>
                <Route
                    path='/organization-setup/:organizationId/organization-information'
                    element={<AddOrganization />}
                ></Route>
                <Route
                    path='/organization-setup/:organizationId/entity'
                    element={<AddEntity />}
                ></Route>
                <Route
                    path='/organization-setup/:organizationId/invoice-phase'
                    element={<EinvoicePhase />}
                ></Route>
                <Route
                    path='/organization-setup/:organizationId/device'
                    element={<AddDevices />}
                ></Route>
                <Route
                    path='/organization-setup/:organizationId/complete'
                    element={<OrganizationSetupCompleted />}
                ></Route>
            </Route>
            <Route path='/organization-initiate' element={<OrganizationInitiate />}></Route>
            <Route path='organizations/create' element={<OrganizationInitiate />}></Route>
            <Route path='subscribe/pricing-plans' element={<PricingPlans />}></Route>
            <Route path='/subscribe/pricing-calculator' element={<PricingCalculator />}></Route>
            <Route path='subscribe/manage-subscription' element={<ManageSubscription />}></Route>
            <Route path='plan-comparison' element={<PlanComparison />}></Route>
            <Route path='subscribe/subscribe-success' element={<SubscribeSuccess />}></Route>
            <Route path='' element={<LayoutWrapper />}>
                <Route path='subscribe' element={<Subscribe />}></Route>
                <Route
                    path='subscribe/invoice/:invoiceId'
                    element={<SubscribeInvoiceDetails />}
                ></Route>
                <Route path='subscribe/cancel-auto-renewal' element={<CancelAutoRenewal />}></Route>
                <Route path='dashboard' element={<Dashboard />}></Route>
                <Route path='organizations' element={<OrganizationList />}></Route>

                <Route
                    path='organizations/update/:organizationId'
                    element={<OrganizationUpdate />}
                ></Route>
                <Route
                    path='organizations/:organizationId'
                    element={<OrganizationDetails />}
                ></Route>
                <Route path='profile' element={<Profile />}></Route>
                <Route path='setting' element={<SettingTabs />}></Route>
                <Route
                    path='organizations/:organizationId/entity/create'
                    element={<EntityCreate />}
                ></Route>
                <Route path='entity/create' element={<EntityCreate />}></Route>
                <Route path='organizations/csid/generate' element={<CsidGenerate />}></Route>
                <Route
                    path='organizations/:organizationId/entity/:entityId'
                    element={<EntityDetails />}
                ></Route>
                <Route
                    path='organizations/entity/update/:entityId'
                    element={<OrganizationEntityUpdate />}
                ></Route>
                <Route path='organizations/csid/:id' element={<CsidDetails />}></Route>
                <Route path='customers' element={<CustomerList />}></Route>
                <Route path='customers/create' element={<CustomerCreate />}></Route>
                <Route path='customers/update/:id' element={<CustomerCreate />}></Route>
                <Route path='customers/details/:id' element={<CustomerDetails />}></Route>
                <Route path='upload-invoices' element={<UploadInvoiceStepper />}></Route>
                <Route path='confirm-upload-invoice' element={<ConfirmUploadInvoice />}></Route>
                <Route path='upload-invoices/list' element={<UploadInvoiceList />}></Route>
                <Route
                    path='upload-invoices/details/:id'
                    element={<UploadInvoiceDetails />}
                ></Route>
                <Route path='inventory-stepper' element={<InventoryStepper />}></Route>
                <Route path='items' element={<InventoryList />}></Route>
                <Route path='items/create' element={<InventoryCreate />}></Route>
                <Route path='items/details' element={<InventoryList />}></Route>
                <Route path='items/update/:id' element={<InventoryCreate />}></Route>
                <Route
                    path='setting/two-factor-authentication'
                    element={<TwoFactorAuthenticationStepper />}
                ></Route>
                <Route path='preference' element={<Preference />}></Route>
                <Route path='e-invoices' element={<InvoiceList />}></Route>
                <Route path='e-invoices/create' element={<NewInvoice />}></Route>
                <Route path='create-credit-note' element={<NewInvoice />}></Route>
                <Route path='create-debit-note' element={<NewInvoice />}></Route>
                <Route path='create-simplified-credit-note' element={<NewInvoice />}></Route>
                <Route path='create-simplified-debit-note' element={<NewInvoice />}></Route>
                <Route path='e-invoices/details/:invoiceId' element={<InvoiceNewDetails />}></Route>
                <Route
                    path='organizations/:organizationId/device/create'
                    element={<DeviceCreate />}
                ></Route>
                <Route
                    path='organizations/:organizationId/device/update/:deviceId'
                    element={<DeviceUpdate />}
                ></Route>
                <Route
                    path='organizations/:organizationId/device/:deviceId'
                    element={<DeviceDetails />}
                ></Route>
                <Route path='/' element={<Navigate replace to='/dashboard' />}></Route>

                <Route path='/support' element={<SupportHome />}></Route>
                <Route path='/support/my-ticket' element={<MyTicket />}></Route>
                <Route path='/support/create-ticket' element={<CreateTicket />}></Route>
                <Route path='/api-services' element={<ApiServicesList />}></Route>
                <Route path='/api-services/create' element={<CreateService />}></Route>
                <Route path='/api-services/update/:id' element={<CreateService />}></Route>
                <Route path='/api-services/details/:id' element={<CreateService />}></Route>
                <Route path='/whats-new' element={<WhatsNew />}></Route>
            </Route>
        </Routes>
    );
}
