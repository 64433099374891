import { InfoCircleOutlined } from '@ant-design/icons';
import './SubscriptionCancellation.scss';
import { CoreButton, CoreTitle } from '@packages';
import { useState } from 'react';
import { SubscribeAPI } from '@service/subscription.service';
import { message } from 'antd';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getSubscription, subscribe } from '@store/slices/subscribeSlice';
import { dayCountBetweenTwoDate, UTCToShortDate } from '@utils/date';

const SubscriptionCancellation = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { subscription } = useAppSelector(subscribe);

    const onUndoCancellation = async () => {
        setIsLoading(true);
        SubscribeAPI.UndoCancellation()
            .then(() => {
                dispatch(getSubscription());
                message.success('Undo cancellation successful');
            })
            .catch((error) => {
                message.error(error?.data?.Message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className='subscription-cancellation-wrapper round-box-shadow'>
            <InfoCircleOutlined className='information-icon' />

            <div className='action-wrapper'>
                <div className='sub-title-wrapper'>
                    <CoreTitle
                        text={`Pending for cancellation in ${dayCountBetweenTwoDate(
                            subscription.Expiration,
                        )} days`}
                        tag='h5'
                    />
                    <CoreButton
                        text='Undo cancellation'
                        type='primary'
                        size='small'
                        loading={isLoading}
                        onClick={onUndoCancellation}
                    />
                </div>
                <div className='subtitle'>
                    {`We've received your request to cancel this subscription. It will be canceled at
                    the end of your billing cycle on ${UTCToShortDate(
                        subscription.DueDate,
                    )}. You can continue using your
                    product and apps until then.`}
                </div>
            </div>
        </div>
    );
};

export default SubscriptionCancellation;
