import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@store/hooks';
import { getPdfTemplates } from '@store/slices/admin/adminTemplateSlice';
import { ITemplate, ITemplateTable } from '@models/admin/template.model';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import { AdminTemplateAPI } from '@service/admin/template.service';
import { Button, Dropdown, MenuProps, message, Table } from 'antd';
import { CoreButton, CoreSearchInput } from '@packages';
import { ConfirmationModal } from '@components';
import './PdfTemplateList.scss';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';

const PdfTemplateList = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { pdfTemplates } = useSelector((state: RootState) => state.adminTemplate);
    const [templateFilterSearch, setTemplateFilterSearch] = useState<boolean>(false);

    const [templatesTableData, setTemplatesTableData] = useState<ITemplateTable[]>([]);
    const [isDeleteTemplateModalOpen, setIsDeleteTemplateModalOpen] = useState(false);
    const [templateId, setTemplateId] = useState('');
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getPdfTemplates({ PageLimit: 100 }));
    }, []);

    useEffect(() => {
        if (!pdfTemplates.isLoading && pdfTemplates) {
            formatTemplateData();
        }
    }, [pdfTemplates]);

    const formatTemplateData = () => {
        const data: ITemplateTable[] = [];
        if (pdfTemplates?.data?.length) {
            pdfTemplates?.data?.forEach((template: ITemplate) => {
                data.push({
                    TemplateId: template.TemplateId,
                    TemplateName: template.TemplateName,
                    TemplateType: template.TemplateType,
                });
            });
        }
        setTemplatesTableData(data);
    };

    const columns: ColumnsType<ITemplateTable> = [
        {
            title: 'Template Id',
            dataIndex: 'TemplateId',
            render: (TemplateId: string) => <>{TemplateId.slice(0, 5)}</>,
        },
        {
            title: 'Template Name',
            dataIndex: 'TemplateName',
        },
        {
            title: 'Template Type',
            dataIndex: 'TemplateType',
        },
        {
            title: '',
            dataIndex: 'TemplateId',
            render: (TemplateId: string) => (
                <div className='action-buttons'>
                    <div
                        className='edit-table-icon'
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/admin/template/pdf/update/${TemplateId}`);
                        }}
                    >
                        <EditOutlined />
                    </div>
                    <div
                        className='edit-table-icon'
                        onClick={(e) => handleDeleteClick(e, TemplateId)}
                    >
                        <DeleteOutlined />
                    </div>
                </div>
            ),
        },
    ];

    const handleRowClick = (e: any, template: ITemplateTable) => {
        e.stopPropagation();
        navigate(`/admin/template/pdf/${template?.TemplateId}`);
    };

    const handleDeleteClick = (e: any, TemplateId: string) => {
        e.stopPropagation();
        setIsDeleteTemplateModalOpen(true);
        setTemplateId(TemplateId);
    };

    const onTemplateDelete = async () => {
        if (!templateId) return;
        setButtonLoading(true);

        try {
            await AdminTemplateAPI.deletePdfTemplate({ TemplateId: templateId });
            setButtonLoading(false);
            message.success(`Template Deleted Successfully`);
            setTemplateId('');
            dispatch(getPdfTemplates({ PageLimit: 100 }));
            setIsDeleteTemplateModalOpen(false);
        } catch {
            message.error('Template Delete Failed');
            setButtonLoading(false);
        }
    };

    const templateTypeSearch = (type: string) => {
        //search here
        setTemplateFilterSearch(true);
        console.log(type);
    };

    const templateFilters: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div className='drop-down-item' onClick={() => templateTypeSearch('standard')}>
                    Standard
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div className='drop-down-item' onClick={() => templateTypeSearch('simplified')}>
                    Simplified
                </div>
            ),
        },
    ];

    return (
        <div className='template-list-wrapper'>
            <div className='template-list-header'>
                <CoreSearchInput placeholder={'Search'} />

                <Dropdown menu={{ items: templateFilters }}>
                    <Button
                        loading={templateFilterSearch}
                        className='drop-down-button'
                        icon={<DownOutlined />}
                    >
                        Type
                    </Button>
                </Dropdown>

                <CoreButton
                    type='primary'
                    text='Add Template'
                    onClick={() => navigate('/admin/template/pdf/create')}
                />
            </div>

            <Table
                columns={columns}
                dataSource={templatesTableData}
                rowClassName='cursor-pointer'
                rowKey={'TemplateId'}
                onRow={(record) => {
                    return {
                        onClick: (e) => handleRowClick(e, record),
                    };
                }}
            />

            <ConfirmationModal
                title='Delete Template'
                description='Would you like to delete this Template?'
                buttonText='Delete'
                isDeleteModalOpen={isDeleteTemplateModalOpen}
                handleDeleteOk={() => onTemplateDelete()}
                handleDeleteCancel={() => setIsDeleteTemplateModalOpen(false)}
                isLoading={buttonLoading}
            />
        </div>
    );
};
export default PdfTemplateList;
